import { StandaloneAuth } from '@leagueplatform/auth-standalone';

/**
 *
 * @param {string} audience
 * @returns {string}
 */

export async function getTokenForAudience(audience?: string): Promise<string> {
  return audience
    ? StandaloneAuth.client.getTokenSilently({
        audience,
      })
    : StandaloneAuth.client.getTokenSilently();
}
