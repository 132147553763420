import * as React from 'react';
import { StackLayout, HeadingText, Box } from '@leagueplatform/genesis-core';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { EntityFormArrayControlProps } from '../../types/controls';

export const ArrayPrimitiveGroup: React.FC<
  React.PropsWithChildren<Partial<EntityFormArrayControlProps>>
> = ({ hint, label, banner, children }) => {
  const showBanner = banner?.title || banner?.description;
  return (
    <StackLayout
      spacing="$half"
      data-testid="array-primitive-group"
      css={{ width: '100%' }}
    >
      {showBanner && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          description={banner.description}
          css={{ marginBottom: '$half' }}
        />
      )}
      <Box
        as="fieldset"
        css={{ width: '100%', border: '$none', padding: '$none' }}
      >
        <HeadingText
          size="sm"
          as="legend"
          level="display"
          emphasis="subtle"
          css={{ typography: '$label' }}
        >
          {label}
        </HeadingText>
        {hint && <HintText css={{ paddingTop: '$quarter' }}>{hint}</HintText>}
        <StackLayout
          as="ol"
          css={{ listStyleType: 'none', paddingInlineStart: '$none' }}
        >
          {children}
        </StackLayout>
      </Box>
    </StackLayout>
  );
};
