import * as React from 'react';
import { RichTextEditor } from '@web-config-app/core-react-ui';
import { Box } from '@leagueplatform/genesis-core';
import { ControlProps } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { useRichTextInputControlCacheKey } from './use-rich-text-input-control-cache-key';

export const RichTextInputControlContents: React.FC<
  ControlProps & { cacheKey: string | undefined }
> = ({ cacheKey, ...props }) => {
  const [minHeight, setMinHeight] = React.useState<number>(100);
  const richTextEditorElem = React.useRef<HTMLDivElement | null>(null);

  // using minHeight on a placeholder div reduces the amount of layout thrashing
  // when doing operations that re-create the entire rich text editor (ex: re-ordering
  // an array of rich text controls)

  React.useEffect(() => {
    setMinHeight(
      Math.max(minHeight, richTextEditorElem.current?.clientHeight ?? 0),
    );
  }, [minHeight]);

  return (
    <EntityFormControl
      {...props}
      renderControl={({
        data,
        id,
        handleChange,
        path,
        inputStatus,
        enabled,
      }) => {
        const handleOnChange = (value: string) => {
          handleChange(path, value);
        };

        return cacheKey && cacheKey.length > 0 ? (
          <Box ref={richTextEditorElem}>
            <RichTextEditor
              id={id}
              value={data ?? ''}
              editorCacheKey={cacheKey}
              onChange={handleOnChange}
              readOnly={!enabled}
              inputStatus={inputStatus}
            />
          </Box>
        ) : (
          <Box
            css={{
              minHeight,
              width: '100%',
              borderRadius: '$medium',
              border: '$borderWidths$thin solid $interactiveBorderDefault',
            }}
          />
        );
      }}
    />
  );
};

export const RichTextInputControl: React.FC<ControlProps> = (props) => {
  const { data: value } = props;
  const cacheKey = useRichTextInputControlCacheKey(value);

  return <RichTextInputControlContents {...props} cacheKey={cacheKey} />;
};
