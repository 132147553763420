import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_TENANT_ID_KEY } from '../../constants/storage-key';

/**
 * Manages the tenant ID using local storage.
 *
 * This hook provides functions to access and update the tenant ID,
 * which is stored in local storage using the `useLocalStorage` hook from `usehooks-ts`.
 *
 * @returns {Object} An object containing the following:
 * - `tenantId`: The current tenant ID.
 * - `setTenantId`: A function to update the tenant ID.
 */
export const useTenantId = () => {
  const initialTenantIdValue = '';
  const [tenantId, setTenantId] = useLocalStorage<string>(
    LOCAL_STORAGE_TENANT_ID_KEY,
    initialTenantIdValue,
  );

  return { tenantId, setTenantId };
};
