import type {
  AppTenantConfig,
  CreateTenantConfigArgs,
  LeagueConfigCoreAuth,
  ConfigAppCoreAuthClientOptions,
} from '@web-config-app/core';
import {
  commonCoreConfig,
  commonAppEnvironment,
  commonCoreAuthClientOptionsClientId,
  commonCoreAuthClientOptionsDomain,
  commonCoreAuthClientOptionsRedirectUri,
} from '../configs/common/core';
import { mergeTenantStrings } from './merge-tenant-strings';
import { mergeRulesTemplates } from './merge-rules-templates';
import leagueLogo from '../assets/league-logo.svg';

const getAuth0ClientOptions = (authConfig: ConfigAppCoreAuthClientOptions) => ({
  ...authConfig.clientOptions,
  domain: authConfig.clientOptions?.domain ?? commonCoreAuthClientOptionsDomain,
  client_id:
    authConfig.clientOptions?.client_id ?? commonCoreAuthClientOptionsClientId,
  redirect_uri:
    authConfig.clientOptions?.redirect_uri ??
    commonCoreAuthClientOptionsRedirectUri,
});

export const createTenantConfig = ({
  core,
  strings,
  configAppTenantConfig,
  rulesTemplates,
}: CreateTenantConfigArgs): AppTenantConfig => {
  const { en: enLocale, ...tenantStringsOtherLocales } =
    mergeTenantStrings(strings);
  const tenantRulesTemplates = mergeRulesTemplates(rulesTemplates);
  const auth0ClientOptions = getAuth0ClientOptions(core.auth);
  return {
    core: {
      ...commonCoreConfig,
      ...core,
      auth: {
        ...core.auth,
        clientOptions: auth0ClientOptions,
      } as LeagueConfigCoreAuth,
      appEnvironment: core.appEnvironment ?? commonAppEnvironment,
      i18n: {
        stringOverrides: {
          default: enLocale,
          en: enLocale,
          ...tenantStringsOtherLocales,
        },
      },
    },
    configApp: {
      ...configAppTenantConfig,
      tenantLogo: leagueLogo,
      rulesTemplates: tenantRulesTemplates,
    },
  };
};
