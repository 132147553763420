import { getCurrentLocale } from '@leagueplatform/locales';

export const getAcceptLanguageHeader = () => {
  const userLocale = getCurrentLocale();
  return {
    headers: new Headers({
      'accept-language': userLocale,
    }),
  };
};

export const combineHeaders = (...fetchOptions: RequestInit[]) => {
  const result: { [key: string]: string } = {};

  fetchOptions.forEach((options) => {
    if (!options.headers) return;

    const headers: Headers = new Headers(options.headers);
    Array.from(headers.entries()).forEach(([key, value]) => {
      result[key] = value;
    });
  });

  return new Headers(result);
};

export const isFullyQualifiedLeagueApiUrl = (path: string) =>
  /^https:\/\/api(?:\.[a-zA-Z0-9-]+)*\.league\.(?:dev|com)\//.test(path);

export const getUrlFromPath = (path: string, apiUrl: string) =>
  isFullyQualifiedLeagueApiUrl(path)
    ? path
    : `${apiUrl}${path.replace(/^\/?/, '/')}`;
