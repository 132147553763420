import { EN_STRING_OVERRIDES } from '@web-config-app/common';
import type { TenantStrings } from '@web-config-app/core';

/**
 * Returns an object of localized strings merged with the default/common strings.
 * Any localization in the passed {@link TenantStrings} that implements a translation key
 * inside of `EN_STRING_OVERRIDES` will override that string.
 *
 * Currently, the only locale supported is `en`.
 */

export const mergeTenantStrings = (
  tenantStrings?: TenantStrings,
): TenantStrings => ({
  en: {
    ...EN_STRING_OVERRIDES,
    ...tenantStrings?.en,
  },
});
