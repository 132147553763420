import React from 'react';
import { Box } from '@leagueplatform/genesis-core';
import { ConfirmationModal } from '../../confirmation-modal/confirmation-modal.component';
import { SelectActionMenuItem } from '../select-action-menu-item/select-action-menu-item.component';
import { SelectMenuItemGroup } from '../../../types/components.types';

interface CreateMenuItemsProps {
  menuGroups: SelectMenuItemGroup[];
  openItemId: string | null;
  handleModalOpenChange: (itemId: string) => void;
  onSelectItem: (itemId: string) => void;
  handleMenuItemClick: (itemId: string) => void;
}

export const createMenuItems = ({
  menuGroups,
  openItemId,
  handleModalOpenChange,
  onSelectItem,
}: CreateMenuItemsProps) =>
  menuGroups.flatMap((group, groupIndex) =>
    group.menuItems.map((item, itemIndex) => {
      const actionMenuItem = (
        <SelectActionMenuItem
          key={item.id}
          selected={item.selected}
          showDivider={
            menuGroups.length > 1 &&
            itemIndex === group.menuItems.length - 1 &&
            groupIndex !== menuGroups.length - 1
          }
          disabledTooltip={item.disabledTooltip}
        >
          {item.label}
        </SelectActionMenuItem>
      );

      const label = item.confirmationModal ? (
        <ConfirmationModal
          open={openItemId === item.id}
          onOpenChange={() => handleModalOpenChange(item.id)}
          modalTrigger={
            <Box css={{ borderRadius: 'inherit' }}>{actionMenuItem}</Box>
          }
          title={item.confirmationModal?.title}
          description={item.confirmationModal?.description}
          confirmCtaAction={() => onSelectItem(item.id)}
          confirmCtaText={item.confirmationModal?.confirmCta}
        />
      ) : (
        actionMenuItem
      );

      return { id: item.id, label };
    }),
  );
