import * as React from 'react';
import {
  Entity,
  EntityDetail,
  ListEndpointResponse,
  ListEndpointPaginationLinks,
} from '@web-config-app/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';

type EntityListUseQueryResult = UseQueryResult<ListEndpointResponse>;

export type UseEntityListResult = Omit<EntityListUseQueryResult, 'data'> & {
  /**
   * There's a clash between `useQuery` returning a `data` property and our endpoints that ALSO include a `data` property
   * in the response. So, to avoid have `data.data`, hoist the endpoint's `response.data` to the top level here.
   */
  data?: EntityDetail[];
  /**
   * Config APIs return results pagination as an object with values corresponding to
   * specific "pages" of results the entity data
   * @example
   * {
   *   self: 'v1/config-entities?page[cursor]=0&page[size]=25',
   *   first: 'v1/config-entities?page[cursor]=0&page[size]=25',
   *   next: 'v1/config-entities?page[cursor]=1&page[size]=25',
   *   last: 'v1/config-entities?page[cursor]=23&page[size]=25',
   * }
   */
  pagination?: ListEndpointPaginationLinks;
  /**
   * takes a pagination `page` string as an argument and passes it to {@link getEntityEndpoint}'s `fetch`
   * as a full path.
   */
  loadPage: (page: string) => void;
};

export const useEntityList = (
  {
    entity,
    enabled = true,
    environment,
  }: { entity: Entity; enabled?: boolean; environment?: string },
  fetchFn?: (path: string, options: any) => Promise<Response>,
): UseEntityListResult => {
  const {
    id,
    endpoints: { list },
  } = entity;

  /**
   * maintain current pageUrl in local state. We pass the `setPageUrl` function back to the
   * called of this hook as `loadPage`, which can be used to update the local state.
   *
   * As this local state changes, it will be passed to `useQuery` which results in new calls
   * to the endpoint.
   */
  const [pageUrl, setPageUrl] = React.useState<string | undefined>();

  if (!list) {
    throw new Error(`Entity ${id} has no defined list endpoint`);
  }

  /**
   * This effect needs to run when switching directly between two entity's list
   * pages to trigger properly loading the new entity's data
   */

  React.useEffect(() => {
    setPageUrl(list.path);
  }, [list.path, environment]);

  const { endpointFetch } = getEntityEndpoint<ListEndpointResponse>(
    list,
    fetchFn,
  );

  /**
   * maintain the exact path called endpoint as the cache key
   *
   * For example, if `pageUrl` is set, use that. Otherwise use
   * the endpoint root URL
   *
   * Our endpoint also use a `data` property so we rename the `useQuery`
   * `data` property here to `queryResultData` to avoid having to use
   * `data.data` below.
   */

  const { data: queryResultData, ...query } = useQuery({
    queryKey: [environment, pageUrl],
    queryFn: () => endpointFetch({ path: pageUrl }),
    enabled: enabled && !!pageUrl,
  });

  return {
    ...query,
    data: queryResultData?.data ?? [],
    pagination: queryResultData?.links,
    loadPage: setPageUrl,
  };
};
