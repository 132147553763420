import {
  captureError,
  combineErrorContexts,
} from '@leagueplatform/observability';
import { Auth } from '@leagueplatform/auth';
import type { ErrorContext } from '@leagueplatform/observability';
import { combineHeaders, getAcceptLanguageHeader } from '../utils/utils';

export interface ConfigFetchOptions extends RequestInit {
  token: string;
  clientId: string;
  appVersion?: string;
  errorContext?: ErrorContext;
}

const getFetchOptions = ({
  token,
  appVersion,
  clientId,
  ...fetchOptions
}: ConfigFetchOptions) => {
  const getCurrentLocale = getAcceptLanguageHeader();
  const authorizationHeaders = {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  };
  const clientIdOptions = {
    headers: new Headers({
      'X-League-Client-Id': clientId,
      ...(appVersion && { 'X-League-Client-Version': appVersion }),
    }),
  };
  return {
    ...fetchOptions,
    headers: combineHeaders(
      authorizationHeaders,
      fetchOptions,
      getCurrentLocale,
      clientIdOptions,
    ),
  };
};

export const configFetch = async (
  url: string,
  options: ConfigFetchOptions,
  errorContext: ErrorContext = {},
) => {
  if (!Auth.initialized) {
    throw new Error('Auth not initialized');
  }

  const promise = fetch(url, getFetchOptions(options));

  const response = await promise;

  if (!response.ok) {
    const body = await response.clone().json();
    const errorDetail = body.errors?.[0]?.detail ?? 'An error has occurred';
    const fetchErrorContext = combineErrorContexts([
      {
        errorName: 'Network Fetch Error',
        tags: { requestUrl: response.url },
        context: {
          Errors: {
            ...body.errors,
          },
          Response: {
            ok: response.ok,
            redirected: response.redirected,
            status: response.status,
            type: response.type,
            url: response.url,
            method: options.method ?? 'GET',
          },
        },
      },
      errorContext,
    ]);
    captureError(new Error(errorDetail), fetchErrorContext);
  }

  return promise;
};
