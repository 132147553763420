import { useMemo, useCallback } from 'react';
import { pick } from 'lodash-es';
import {
  getRelationshipDataSchema,
  isRelationshipArrayDataSchema,
} from '@web-config-app/schema-utils';
import type { EntityDetailSchemaWithRelationships } from '@web-config-app/core';
import type { RelationshipValue } from '../../types/controls';
import type { UseRelationshipsDataResult } from '../use-relationships-data/use-relationships-data';
import { getCreateRelationshipValueFromSchema } from '../../utils/get-create-relationship-value-from-schema/get-create-relationship-value-from-schema';

interface UseRelationships extends UseRelationshipsDataResult {
  relationship: string;
  schema: EntityDetailSchemaWithRelationships;
}

type RelationshipCurrentValue =
  | Partial<RelationshipValue>
  | Partial<RelationshipValue>[]
  | undefined;

export const useRelationship = ({
  relationship,
  setRelationships,
  getRelationship,
  schema,
}: UseRelationships) => {
  const { relationshipDataSchema, relationshipDataSchemaType } = useMemo(() => {
    const dataSchema = getRelationshipDataSchema(relationship, schema);
    const isArrayDataSchema = isRelationshipArrayDataSchema(dataSchema);
    return {
      relationshipDataSchema: isArrayDataSchema ? dataSchema.items : dataSchema,
      relationshipDataSchemaType: isArrayDataSchema ? 'array' : 'object',
    };
  }, [relationship, schema]);

  const createRelationshipValue = useMemo(
    () => getCreateRelationshipValueFromSchema(relationship, schema),
    [relationship, schema],
  );

  const value: RelationshipCurrentValue = getRelationship(relationship);

  const addRelationshipArrayItem = useCallback(
    (newRelationship: Partial<RelationshipValue>) => {
      if (relationshipDataSchemaType === 'array') {
        setRelationships(relationship, [
          ...(Array.isArray(value) ? value : []),
          createRelationshipValue(newRelationship),
        ]);
      }
    },
    [
      setRelationships,
      relationship,
      createRelationshipValue,
      value,
      relationshipDataSchemaType,
    ],
  );

  const removeRelationshipArrayItem = useCallback(
    (removedRelationship: Partial<RelationshipValue> | string) => {
      /**
       * removedRelationship may be undefined in the case where `setRelationship` was
       * called from an array of relationships and the relationship did not have a previous value
       */
      const idToRemove =
        typeof removedRelationship === 'string'
          ? removedRelationship
          : removedRelationship.id;
      if (Array.isArray(value)) {
        setRelationships(
          relationship,
          value.filter((entityToRemove) => entityToRemove.id !== idToRemove),
        );
      }
    },
    [value, setRelationships, relationship],
  );

  const moveRelationshipArrayItemUp = useCallback(
    (movedRelationship: Partial<RelationshipValue>, index: number) => {
      if (Array.isArray(value)) {
        const items = [...value];
        const itemIndex =
          items.findIndex((item) => item.id === movedRelationship.id) ?? index;
        if (itemIndex > 0) {
          // Remove the item from its current position
          const [removedItem] = items.splice(itemIndex, 1);
          // Insert the item at the position above
          items.splice(itemIndex - 1, 0, removedItem);
        }
        setRelationships(relationship, items);
      }
    },
    [value, setRelationships, relationship],
  );

  const moveRelationshipArrayItemDown = useCallback(
    (movedRelationship: Partial<RelationshipValue>, index: number) => {
      if (Array.isArray(value)) {
        const items = [...value];
        const itemIndex =
          items.findIndex((item) => item.id === movedRelationship.id) ?? index;
        if (itemIndex < items.length - 1) {
          // Remove the item from its current position
          const [removedItem] = items.splice(itemIndex, 1);
          // Insert the item at the position below
          items.splice(itemIndex + 1, 0, removedItem);
        }
        setRelationships(relationship, items);
      }
    },
    [value, setRelationships, relationship],
  );

  const setRelationship = useCallback(
    (
      newValue: RelationshipValue,
      previousValue?: RelationshipValue,
      index?: number,
    ) => {
      const relationshipValue = createRelationshipValue(newValue);
      /**
       * Using `pick` below and passing in the relationship data schema's
       * properties ensures that the end value will not include any extra data. This allows for
       * some convenience handlers to pass an entire {@link EntityDetail} object without
       * worrying exactly which props to send
       */
      const schemaShapeValue = pick(
        relationshipValue,
        Object.keys(relationshipDataSchema?.properties ?? {}),
      );

      if (relationshipDataSchemaType === 'array') {
        const items = Array.isArray(value) ? value : [];

        /**
         * This is possibly redundant. Maybe it's fine to just take the index? Either way, find
         * the relationship item that corresponds to the entity reference being changed
         */
        const existingRelationshipItem = previousValue
          ? items?.find((item) => item.id === previousValue.id)
          : index !== undefined && items[index];

        if (existingRelationshipItem) {
          /**
           * Create a new array from the previous value with the changed relationship
           */
          const updatedArray = Array.from(items).map((item, idx) => {
            /**
             * There could be multiple incomplete elements in this array that were added
             * in the form but haven't been set to an entity yet, so their id will be ''
             *
             * If the item being updated has an id, use it to match. Otherwise, rely
             * on the array index.
             */
            if (existingRelationshipItem.id) {
              return existingRelationshipItem.id === item.id
                ? schemaShapeValue
                : item;
            }
            return idx === index ? schemaShapeValue : item;
          });
          setRelationships(relationship, updatedArray);
        } else {
          setRelationships(relationship, [...items, schemaShapeValue]);
        }
      } else {
        setRelationships(relationship, schemaShapeValue);
      }
    },
    [
      relationship,
      setRelationships,
      createRelationshipValue,
      relationshipDataSchema,
      relationshipDataSchemaType,
      value,
    ],
  );

  return {
    value,
    setRelationship,
    relationshipDataSchema,
    relationshipDataSchemaType,
    addRelationshipItem: addRelationshipArrayItem,
    removeRelationshipItem: removeRelationshipArrayItem,
    moveRelationshipItemUp: moveRelationshipArrayItemUp,
    moveRelationshipItemDown: moveRelationshipArrayItemDown,
  };
};

export type UseRelationshipDataResult = ReturnType<typeof useRelationship>;
