import {
  AnnotatedJsonSchema,
  SchemaTransformerArgs,
  SchemaTransformer,
} from '@web-config-app/core';
import { evaluateIncludeIfCondition } from '../../evaluate-include-if-conditional/evaluate-include-if-conditional';
import { evaluateDisabledIfCondition } from '../../evaluate-disabled-if-conditional/evaluate-disabled-if-conditional';
import { getSchemaWithMutatedProperties } from '../../get-schema-with-mutated-properties/get-schema-with-mutated-properties';

/**
 * This transformer function handles the x-entity-conditional annotation
 * @param schema - the current schema slice
 * @param data - the form data
 * @returns an annotatedJsonSchema
 */

export const applyConditionalLogic: SchemaTransformer = ({
  schema,
  data,
  options,
}: SchemaTransformerArgs) => {
  if (
    !Object.entries(schema.properties ?? {}).find(
      ([, propertySchema]) => !!propertySchema['x-entity-conditional'],
    )
  )
    return schema;

  const result = getSchemaWithMutatedProperties({
    schema,
    filterProperties: (propertySchema, propertyName) =>
      evaluateIncludeIfCondition({
        schema: propertySchema,
        data,
        options: {
          ...options,
          schemaPropertyName: propertyName,
        },
      }),
    mutateProperties: (propertySchema: AnnotatedJsonSchema) =>
      evaluateDisabledIfCondition({ schema: propertySchema, data, options }),
  });

  return result;
};
