import { useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { TranslationKey } from '@web-config-app/core';
import { linkValueFormatter } from './link-value-formatter';

/**
 * Hook that returns a convenience function that is useful for translating
 * keys from our custom annotations with support for dynamic values, including <a> tags - links.
 * It uses `react-intl`'s `formatMessage` function and extends it to handle link values via the linkValueFormatter.
 *
 * To use this hook, wrap the text you want to be a link in a `<a>` tag in the translation file, this tag with return an internal or eternal link.
 * The value of the `href` attribute on the link tag will be the key in the `values` object passed to the `translateWithValues` function.
 *
 * @returns A function that takes a translation key and an object of values (optional) and returns the translated string.
 *
 * @example
 * const translateWithValues = useTranslate();
 *
 * // Basic usage with key only
 * const hint = { key: "HELPFUL_HINT" };
 * const translatedString = translate(hint.key);
 *
 * // With link value (assuming the translation for "HELPFUL_HINT" is "Click <a>{myLink}|here</a>")
 * const hint = {
 *  key: "HELPFUL_HINT",
 *  values: {
 *    myLink: "https://formatjs.github.io/"
 *  }
 * };
 * const translatedStringWithLink = translate(hint.key, hint.values);
 *
 */

export const useTranslate = () => {
  const { formatMessage } = useIntl();
  const translate = useCallback(
    (id?: TranslationKey, values?: Record<string, string>) => {
      const result =
        id &&
        formatMessage(
          { id },
          values ? { ...values, a: linkValueFormatter } : {},
        );
      /**
       * Ensure the return value is always a string or undefined, this is because formatMessage with our formatter passed is typed to string or an array of strings - but in our case it should only ever return a string
       */
      return !Array.isArray(result) ? result : undefined;
    },
    [formatMessage],
  );

  return translate;
};
