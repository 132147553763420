import type {
  AnnotatedJsonSchema,
  EntityConditionalType,
} from '@web-config-app/core';

export const getConditionOfType = (
  schema: AnnotatedJsonSchema,
  type: EntityConditionalType,
) =>
  schema?.['x-entity-conditional']?.conditions?.find(
    (condition) => condition?.type === type,
  );
