import React from 'react';
import {
  Checkbox,
  InputStatusMessage,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { useEntityFormControl } from '../../../hooks/use-entity-form-control/use-entity-form-control';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { ControlComponent, ControlProps } from '../../../types/controls';

/**
 * BooleanInputWrapper returns the children that is passed to it. It is used to bypass the default wrapper that EntityFormControl
 * integrates because Genesis core Checkbox component has its own built in hint and label.
 */

const BooleanInputWrapper: React.FC<React.PropsWithChildren<ControlProps>> = ({
  children,
  ...props
}) => {
  const entityFormControlProps = useEntityFormControl(props);
  const { banner } = entityFormControlProps;
  const showBanner = banner?.title || banner?.description;
  return (
    <StackLayout
      spacing="$half"
      data-testid="boolean-wrapper"
      css={{ marginTop: '$oneAndHalf' }}
    >
      {showBanner && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          description={banner.description}
        />
      )}
      {children}
    </StackLayout>
  );
};

export const BooleanInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    wrapperComponent={BooleanInputWrapper}
    renderControl={({
      data,
      handleChange,
      path,
      inputStatus,
      statusMessage,
      hint,
      enabled,
      required,
      id,
      label,
    }) => (
      <>
        <Checkbox
          name={id}
          id={id}
          label={label}
          required={required}
          hint={hint && <HintText>{hint}</HintText>}
          checked={data ?? false}
          inputStatus={inputStatus}
          aria-readonly={!enabled}
          onChange={enabled ? () => handleChange(path, !data) : () => false}
          css={{
            ...(!enabled && {
              '.GDS-checkbox-label': {
                'pointer-events': 'none',
              },
            }),
          }}
        />
        {/* May or may not be used as much */}
        <InputStatusMessage
          inputStatus={inputStatus}
          statusIconLabel={inputStatus}
        >
          {statusMessage}
        </InputStatusMessage>
      </>
    )}
  />
);
