/**
 * Retrieves the first valid label from a prioritized list.
 *
 * Iterates through the provided arguments in order, returning the first argument that is a non-empty string.
 * Useful for cases where we want to use a value for some schema label and there is a series of fallbacks available incase the value is not
 *
 * @param args - A prioritized list of potential labels for the property.
 * @returns The first valid label found, or undefined if none are found.
 */

export const getFirstUseableLabel = (
  ...args: Array<string | undefined | null>
) =>
  args.find(
    (possibleLabel) =>
      typeof possibleLabel === 'string' && possibleLabel.length > 0,
  );
