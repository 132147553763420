import { useMemo } from 'react';
import type {
  AuthoringEnvironmentId,
  AuthoringEnvironmentClientInfo,
  BaseAuthoringEnvironmentConfig,
  PreviewAuthoringEnvironment,
  Nullable,
  AuthoringEnvironmentNameKeyMap,
} from '@web-config-app/core';
import { environmentKeys } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

type EnvironmentMap = Map<
  AuthoringEnvironmentId,
  AuthoringEnvironmentClientInfo
>;

export const getEnvironmentNameKeyForEnvironmentId = (
  envId: AuthoringEnvironmentId,
  nameKeys?: Partial<AuthoringEnvironmentNameKeyMap>,
) => {
  const configNameKey = nameKeys?.[envId];
  return configNameKey ?? environmentKeys[envId];
};

/**
 * Ensures that re always get a boolean value for properties that are optional booleans in the
 * configuration but required booleans in {@link AuthoringEnvironmentClientInfo}
 */

export const getBooleanValueOrDefault = (
  value: boolean | undefined,
  defaultValue: boolean,
): boolean => {
  if (typeof value !== 'undefined') return value;

  return defaultValue;
};

/**
 * Since {@link BaseAuthoringEnvironmentConfig} allows for some properties to be optional AND also
 * omits the `type` (which we always want to be equal to `base`), we want to pass the config through this
 * function and add values for the omitted and optional properties
 */

export const getBaseEnvironmentWithDefaults = ({
  publishDangerously,
  defaultAuthoringEnvironment,
  nameKeys,
  ...baseEnvironment
}: BaseAuthoringEnvironmentConfig): AuthoringEnvironmentClientInfo => ({
  ...baseEnvironment,
  nameKey: getEnvironmentNameKeyForEnvironmentId(baseEnvironment.id, nameKeys),
  type: 'base',
  defaultAuthoringEnvironment: getBooleanValueOrDefault(
    defaultAuthoringEnvironment,
    false,
  ),
  publishDangerously: getBooleanValueOrDefault(publishDangerously, true),
});

export const getPreviewEnvironmentWithDefaults = ({
  nameKeys,
  defaultAuthoringEnvironment,
  publishDangerously,
  ...authoringEnv
}: PreviewAuthoringEnvironment): AuthoringEnvironmentClientInfo => ({
  ...authoringEnv,
  nameKey: getEnvironmentNameKeyForEnvironmentId(authoringEnv.id, nameKeys),
  publishDangerously: getBooleanValueOrDefault(publishDangerously, false),
  defaultAuthoringEnvironment:
    defaultAuthoringEnvironment ?? authoringEnv.type === 'preview',
});

export const getCombinedAuthoringEnvironmentsMap = (
  baseEnvironment: AuthoringEnvironmentClientInfo,
  previewEnvironments: PreviewAuthoringEnvironment[] = [],
) => {
  const previewEnvironmentClientInfos = previewEnvironments.map((env) =>
    getPreviewEnvironmentWithDefaults(env),
  );
  return new Map<AuthoringEnvironmentId, AuthoringEnvironmentClientInfo>(
    [baseEnvironment, ...previewEnvironmentClientInfos]?.map((env) => [
      env.id,
      env,
    ]),
  );
};
export interface UseAuthoringEnvironmentsResult {
  /**
   * All app configs have a base environment
   */
  baseAuthoringEnvironment: AuthoringEnvironmentClientInfo;
  /**
   * The default environment is configurable but defaults to:
   *
   * 1. in a multi-environment setup, it defaults to the `preview` type
   * 2. in a single environment, it defaults to `base`
   */
  defaultAuthoringEnvironment: AuthoringEnvironmentClientInfo;
  /**
   * The ID of the current authoring environment
   */
  currentAuthoringEnvironment: AuthoringEnvironmentId;
  multiEnvironmentAuthoringEnabled: boolean;
  environmentMap: Nullable<EnvironmentMap>;
}

export const useAuthoringEnvironments = ({
  environmentId,
}: {
  environmentId?: AuthoringEnvironmentId;
} = {}): UseAuthoringEnvironmentsResult => {
  const {
    authoring: {
      baseEnvironment: baseEnvironmentConfig,
      previewEnvironments = [],
      disableMultiEnvironmentOperations,
    },
  } = useConfigApp();
  const baseAuthoringEnvironment = useMemo(
    () => getBaseEnvironmentWithDefaults(baseEnvironmentConfig),
    [baseEnvironmentConfig],
  );

  const multiEnvironmentAuthoringEnabled =
    disableMultiEnvironmentOperations !== true &&
    previewEnvironments.length > 0;

  const environmentMap = useMemo<Nullable<EnvironmentMap>>(
    () =>
      multiEnvironmentAuthoringEnabled
        ? getCombinedAuthoringEnvironmentsMap(
            baseAuthoringEnvironment,
            previewEnvironments,
          )
        : null,
    [
      baseAuthoringEnvironment,
      previewEnvironments,
      multiEnvironmentAuthoringEnabled,
    ],
  );

  const defaultAuthoringEnvironment =
    useMemo<AuthoringEnvironmentClientInfo>(() => {
      if (
        baseAuthoringEnvironment.defaultAuthoringEnvironment ||
        !multiEnvironmentAuthoringEnabled ||
        environmentMap === null
      ) {
        return baseAuthoringEnvironment;
      }

      const defaultEnvironmentType = previewEnvironments.find(
        (env) => env.defaultAuthoringEnvironment,
      )?.id;
      const defaultEnvironment =
        defaultEnvironmentType && environmentMap.get(defaultEnvironmentType);
      return defaultEnvironment ?? baseAuthoringEnvironment;
    }, [
      baseAuthoringEnvironment,
      previewEnvironments,
      multiEnvironmentAuthoringEnabled,
      environmentMap,
    ]);

  return {
    baseAuthoringEnvironment,
    defaultAuthoringEnvironment,
    currentAuthoringEnvironment: environmentId ?? baseAuthoringEnvironment.id,
    multiEnvironmentAuthoringEnabled,
    environmentMap,
  };
};
