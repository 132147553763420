import { useState, useCallback } from 'react';
import type { EntityDetail } from '@web-config-app/core';

/**
 * Stores any relationship entities that are returned via the API response in
 * the top-level `included` property. These are set via `setIncludedEntities` after
 * a successful GET call of a specific entity
 *
 * {@link https://jsonapi.org/format/#document-compound-documents}
 *
 * Additionally, when adding *new* relationships during editing, we manually append
 * those entities via `addIncludedEntity` so that we can reference its full data in the
 * period before the entity has been saved with the new relationship values.
 *
 */

export const useIncludedEntities = () => {
  const [entities, setEntities] = useState<EntityDetail[]>([]);

  /**
   * Resets the includedEntities array to the passed array. This typically
   * is set when loading a new entity from storage via a GET endpoint call.
   */
  const setIncludedEntities = useCallback(
    (includedEntities: EntityDetail[] | undefined) => {
      if (Array.isArray(includedEntities)) {
        setEntities(includedEntities);
      }
    },
    [],
  );

  /**
   * When editing an entity and adding new relationship, it needs
   * to be manually added to whatever data is returned from the server so
   * that we can still reference the referenced entity's data
   */
  const addIncludedEntity = useCallback(
    (entity: EntityDetail) => {
      if (!entities.find((e) => e.id === entity.id))
        setEntities([...entities, entity]);
    },
    [entities],
  );

  return {
    includedEntities: entities,
    setIncludedEntities,
    addIncludedEntity,
  };
};

export type UseIncludedEntitiesResult = ReturnType<typeof useIncludedEntities>;
