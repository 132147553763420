import { SchemaTransformerArgs } from '@web-config-app/core';
import type { RulesLogic } from 'json-logic-js';
import jsonLogic from 'json-logic-js';
import { getConditionOfType } from '../get-condition-of-type/get-condition-of-type';

const evaluateRules = (
  rules: RulesLogic,
  data: any,
  {
    arrayItem,
    onSchemaPropertyRemoved,
    path,
    schemaPropertyName,
  }: SchemaTransformerArgs['options'] = {},
) => {
  const dataToEvaluate =
    arrayItem?.isArrayItemContext !== true ? data : arrayItem.arrayItemData;

  const result = jsonLogic.apply(rules, dataToEvaluate);

  if (
    result === false &&
    onSchemaPropertyRemoved &&
    typeof path === 'string' &&
    schemaPropertyName
  ) {
    onSchemaPropertyRemoved(path, schemaPropertyName);
  }

  return result;
};

/**
 * The evaluateIncludeIfCondition function is a part of the x-entity-conditional annotation used to evaluate the includeIf condition.
 *
 * @param schema - the schema that implements or extends the {@link AnnotatedJsonSchema} interface
 * @param data - data that corresponds to the schema
 * @param options - schema
 * @returns true or false based on the json logic rule applied
 */
export const evaluateIncludeIfCondition = ({
  schema,
  data,
  options,
}: SchemaTransformerArgs) => {
  if (!schema['x-entity-conditional']) return true;

  const includeIfCondition = getConditionOfType(schema, 'includeIf');

  if (!includeIfCondition) return true;

  return evaluateRules(includeIfCondition.rules, data, options);
};
