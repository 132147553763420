// Both functions in this file were created with the support of gemini

function calculateStringSimilarity(str1: string, str2: string): number {
  const words1 = str1.split('.');
  const words2 = str2.split('.');
  const uniqueCommonWords = new Set(
    words1.filter((word) => words2.includes(word)),
  );
  return uniqueCommonWords.size;
}
export function findClosestNodeIdFromInstancePath(
  nodeIdList: string[],
  instancePath: string,
): string {
  /* the instancePath provided by json forms errors use `/` characters as separators, while our nodeIds use `.` characters as separators. We format the instancePath here by replacing all the `/` characters with `.` characters such that we can more easily split the strings and compare in our calculateStringSimilarity function */
  const formattedInstancePath = instancePath.replaceAll('/', '.').slice(1);
  return nodeIdList.reduce((closestMatch, currentStr) => {
    const currentSimilarity = calculateStringSimilarity(
      currentStr,
      formattedInstancePath,
    );
    const closestSimilarity = calculateStringSimilarity(
      closestMatch,
      formattedInstancePath,
    );
    return currentSimilarity > closestSimilarity ? currentStr : closestMatch;
  }, '');
}
