import * as React from 'react';
import { createStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const dataSourceStore = createStore(
  subscribeWithSelector(() => ({
    dataSources: new Map<string, Array<unknown>>([]),
  })),
);

export type DataSourceStore = typeof dataSourceStore;

const DataSourceStoreContext = React.createContext<DataSourceStore | null>(
  null,
);

export const DataSourceStoreProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const store = React.useRef(dataSourceStore);

  return (
    <DataSourceStoreContext.Provider value={store.current}>
      {children}
    </DataSourceStoreContext.Provider>
  );
};

export const useDataSourceStore = () => {
  const store = React.useContext(DataSourceStoreContext);
  if (!store) {
    throw new Error(
      'No provider found. useDataSourceStore must be called within a child of a DataSourceStoreProvider',
    );
  }

  return store;
};
