import * as React from 'react';
import {
  GDSStatus,
  GDSStyleObject,
  StatusBanner,
  ParagraphText,
} from '@leagueplatform/genesis-core';

export interface EntityFormBannerProps {
  title?: React.ReactNode;
  status?: GDSStatus;
  css?: GDSStyleObject;
  description?: React.ReactNode;
}

/**
 * Status banner for entity form that allows for the description to be a string of rich-text.
 * The status is set to 'info' by default if no status is provided
 */

export const EntityFormBanner: React.FC<EntityFormBannerProps> = ({
  css,
  title,
  status,
  description,
}) => (
  <StatusBanner
    status={status || 'info'}
    title={title}
    css={{ width: '100%', ...css }}
  >
    {description && <ParagraphText as="div">{description}</ParagraphText>}
  </StatusBanner>
);
