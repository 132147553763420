import * as React from 'react';
import { JsonFormsDispatch } from '@jsonforms/react';
import { Select, Divider, StackLayout } from '@leagueplatform/genesis-core';
import {
  ControlComponent,
  EntityFormControlProps,
} from '../../../types/controls';
import { CompoundPrimitiveGroupFieldset } from '../../compound-primitive-group-fieldset/compound-primitive-group-fieldset.component';
import { PrimitiveControlFormField } from '../../primitive-control-form-field/primitive-control-form-field.component';
import { useEntityFormControl } from '../../../hooks/use-entity-form-control/use-entity-form-control';
import { useCombinatorSchemas } from '../../../hooks/use-combinator-schemas/use-combinator-schemas';

export const SelectableDiscriminatorCombinatorControlContents = (
  controlProps: EntityFormControlProps,
) => {
  const {
    path,
    rootSchema,
    data,
    handleChange,
    visible,
    enabled,
    renderers,
    cells,
    inputStatus,
    combinatorProperties,
  } = controlProps;
  // TODO: Review this in future for performance optimizations

  const {
    combinatorInputWrapperOptions,
    combinatorDiscriminator = '',
    combinatorSelectOptions,
    combinatorPlaceholder,
  } = combinatorProperties ?? {};

  const { selectedSubSchema, detailUiSchema, createSelectedSubSchemaValue } =
    useCombinatorSchemas({
      combinatorProperties,
      rootSchema,
      data,
    });

  return (
    <StackLayout spacing="$half" horizontalAlignment="stretch">
      <PrimitiveControlFormField
        {...controlProps}
        {...combinatorInputWrapperOptions}
        id={`${combinatorDiscriminator}-select-input`}
      >
        <Select
          value={combinatorDiscriminator && data?.[combinatorDiscriminator]}
          onChange={(evt) => {
            const selectedDiscriminator = evt.currentTarget.value;
            handleChange(
              path,
              createSelectedSubSchemaValue?.(selectedDiscriminator),
            );
          }}
          readOnly={!enabled}
          inputStatus={inputStatus}
          options={combinatorSelectOptions}
          placeholder={combinatorPlaceholder}
        />
      </PrimitiveControlFormField>
      {selectedSubSchema && (
        <>
          <Divider />
          <JsonFormsDispatch
            visible={visible}
            enabled={enabled}
            schema={selectedSubSchema}
            uischema={detailUiSchema}
            path={path}
            renderers={renderers}
            cells={cells}
          />
        </>
      )}
    </StackLayout>
  );
};

export const SelectableDiscriminatorCombinatorControl: ControlComponent = (
  props,
) => {
  const entityFormControlProps = useEntityFormControl(props);
  const { hint, label, banner } = entityFormControlProps;
  return (
    <CompoundPrimitiveGroupFieldset hint={hint} label={label} banner={banner}>
      <SelectableDiscriminatorCombinatorControlContents
        {...entityFormControlProps}
      />
    </CompoundPrimitiveGroupFieldset>
  );
};
