import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  InputStatusMessage,
} from '@leagueplatform/genesis-core';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { EntityHeadingProps } from '../../types/components';

/**    
 * 
This heading is shared between the Object Compound Layout and the Array of Objects Layout. It appears at the top of every new "page" within the property editor.
 * 
*/

export const CompoundComplexHeading = ({
  label,
  hint,
  banner,
  error,
}: EntityHeadingProps) => {
  const { formatMessage } = useIntl();
  const showBanner = banner?.title || banner?.description;

  return (
    <>
      <Box
        css={{
          borderBottom: '$borderWidths$thin solid $onSurfaceBorderSubdued',
          margin: '$oneAndQuarter 0',
        }}
      >
        {showBanner && (
          <EntityFormBanner
            status={banner.status}
            title={banner.title}
            description={banner.description}
            css={{ margin: '$twoAndHalf 0' }}
          />
        )}
        <HeadingText size="xl" level="2" css={{ paddingBottom: '$half' }}>
          {label}
        </HeadingText>
        {hint && (
          <HintText size="sm" css={{ marginBottom: '$oneAndHalf' }}>
            {hint}
          </HintText>
        )}
      </Box>
      {error && (
        <InputStatusMessage
          data-testid="error-message"
          inputStatus="error"
          statusIconLabel={formatMessage({ id: 'ERROR' })}
          css={{ marginTop: '$half', marginBottom: '$one' }}
        >
          {error}
        </InputStatusMessage>
      )}
    </>
  );
};
