import * as React from 'react';
import { FormField, StackLayout, Box } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { EntityFormControlProps } from '../../types/controls';

/**
 * Genesis Core FormField that can be used to wrap primitive property inputs (single input element) via the EntityFormControl
 * It provides a built-in label, hint, banner, and error status message that are populated from the transformed schema via useEntityFormControl
 * To correctly apply the id and name, the property's input element must be the direct child of this component.
 *
 * If primitive control is an array item, we hide the label and not render the banner or hint text
 */

export const PrimitiveControlFormFieldBanner = ({
  children,
  banner,
  isArrayItem = false,
}: React.PropsWithChildren<{
  banner?: EntityFormControlProps['banner'];
  isArrayItem?: boolean;
}>) => {
  const showBanner = (banner?.title || banner?.description) && !isArrayItem;
  return (
    <StackLayout
      spacing="$half"
      data-testid="primitive-control-form-field"
      css={{ width: '100%', margin: `${isArrayItem ? 0 : '$oneAndQuarter 0'}` }}
    >
      {showBanner && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          description={banner.description}
        />
      )}

      {children}
    </StackLayout>
  );
};

export const PrimitiveControlFormField: React.FC<
  React.PropsWithChildren<EntityFormControlProps>
> = ({
  hint,
  label,
  id,
  inputStatus,
  statusMessage,
  banner,
  children,
  required,
}) => {
  const { formatMessage } = useIntl();
  const [isArrayItem, setIsArrayItem] = React.useState(false);

  // TODO:: rework logic with annotation https://everlong.atlassian.net/browse/CACT-849
  const formFieldRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const closestDataArrayItem = formFieldRef.current?.closest(
      '[data-array-primitive-item]',
    );
    setIsArrayItem(Boolean(closestDataArrayItem));
  }, []);

  return (
    <PrimitiveControlFormFieldBanner banner={banner} isArrayItem={isArrayItem}>
      <Box ref={formFieldRef} css={{ width: '100%' }}>
        <FormField
          hint={
            !isArrayItem && (
              <HintText css={{ paddingBottom: '$quarter' }}>{hint}</HintText>
            )
          }
          id={id}
          label={label}
          inputStatus={inputStatus}
          statusIconLabel={formatMessage({ id: 'ERROR' })}
          statusMessage={statusMessage}
          required={!!required}
          hideLabel={isArrayItem}
        >
          {children}
        </FormField>
      </Box>
    </PrimitiveControlFormFieldBanner>
  );
};
