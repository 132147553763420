import {
  EntityDetailSchema,
  EntityRootData,
  EntityDetail,
  EntityDetailSchemaWithRelationships,
  LocalDataSource,
  RelationshipDataSource,
} from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import { cloneDeep } from 'lodash-es';
import jp from 'jsonpath';

/**
 * This will limit the data source to include ONLY entities of the type
 * defined in the linked relationship
 */

const getIncludedDataSourceForRelationship = (
  includedEntities: EntityDetail[] | undefined,
  relationship: string | undefined,
  schema: EntityDetailSchemaWithRelationships,
) => {
  if (!relationship) return includedEntities;
  const { type } = getRelationshipSchemaItemType(relationship, schema);
  return includedEntities?.filter((entity) => entity.type === type);
};

const isRelationshipDataSource = (
  dataSource: LocalDataSource | RelationshipDataSource,
): dataSource is RelationshipDataSource => 'relationship' in dataSource;

/**
 * Get any data sources that have been declared in the entity schema's root using
 * the {@link EntityDataSourcesAnnotation} annotation
 */

export const getSchemaDataSources = (
  {
    'x-entity-data-source-registry': dataSourceRegistry,
    ...schema
  }: EntityDetailSchema,
  data: EntityRootData | undefined,
  includedEntities?: EntityDetail[],
) => {
  const sources = dataSourceRegistry?.sources ?? [];
  return sources.map((source) =>
    isRelationshipDataSource(source)
      ? {
          name: source.name,
          value: getIncludedDataSourceForRelationship(
            includedEntities,
            source.relationship,
            schema,
          ),
        }
      : {
          name: source.name,
          value: cloneDeep(jp.value(data ?? {}, source.path)),
        },
  );
};
