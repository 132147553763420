import * as React from 'react';
import {
  GDSParagraphTextSize,
  GDSStyleObject,
  ParagraphText,
} from '@leagueplatform/genesis-core';

export interface HintTextProps {
  id?: string;
  size?: GDSParagraphTextSize;
  css?: GDSStyleObject;
}

/**
 * Reuseable hint that matches InputHint from Genesis core but is able to handle rendering rich-text as children. Defaults to being size 'xs' to match genesis InputHint but size can be overridden with size prop.
 */

export const HintText: React.FC<React.PropsWithChildren<HintTextProps>> = ({
  children,
  id,
  size,
  css,
}) => (
  <ParagraphText
    id={id}
    as="div"
    emphasis="subtle"
    size={size || 'xs'}
    css={{
      a: {
        fontSize: 'inherit',
        fontWeight: '$buttonOne',
      },
      '& ul, & ol': {
        fontSize: 'inherit',
        color: 'inherit',
        marginBlockEnd: '$none',
        lineHeight: 'inherit',
      },
      ...css,
    }}
  >
    {children}
  </ParagraphText>
);
