import type { EnumOptionsDataSourceMapping } from '@web-config-app/core';
import { getDataPointValue } from '../../utils/get-data-point-value/get-data-point-value';

/**
 * Returns enumOptions derived from a data source
 *
 * The data source must be an array of objects such that each object contains
 * a property that will correspond to the value you want to set. Optionally, you can
 * include a property to use as a readable label for the option. The label will default
 * to the value if label is not included (or not retrieved from the data)
 */

export const getMappedDataSourceEnumOptions = (
  data: any,
  mapping: EnumOptionsDataSourceMapping | undefined,
) => {
  if (!mapping) return undefined;

  const { optionValuePath, optionLabelPath } = mapping;

  const mappedData =
    optionValuePath && Array.isArray(data)
      ? data
          .map((item) => {
            const itemValue = getDataPointValue(item, optionValuePath);
            const itemLabel =
              optionLabelPath && getDataPointValue(item, optionLabelPath);
            return { value: itemValue, label: itemLabel ?? itemValue };
          })
          .filter((item) => !!item.value)
      : undefined;

  return { enumOptions: mappedData };
};
