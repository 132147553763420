import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { Entity, EntityRootData, OperationType } from '@web-config-app/core';
import { injectOperationEntityMetadataRequiredFields } from '../../utilities/inject-operation-entity-metadata-required-fields/inject-operation-entity-metadata-required-fields';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';
import { OnMutateWithOperation } from '../../types/operation.types';

export type UseEntityUpdateResult = ReturnType<typeof useEntityUpdate>;

export type UseEntityUpdateOptions = Pick<
  UseMutationOptions,
  'onSuccess' | 'onError' | 'onSettled'
> & {
  onMutate?: OnMutateWithOperation;
  fetchFn?: (path: string, options: any) => Promise<Response>;
};

interface UseEntityUpdateProps {
  entity: Entity;
  instanceId: string;
  operation: OperationType;
  data?: EntityRootData;
  headers?: HeadersInit;
  options?: UseEntityUpdateOptions;
}

/**
 * useEntityUpdate - The following hook is responsible for calling the BE API associated to the all Update (PATCH) services of an entity.
 * @param props
 * @param props.entity - the whole {@link Entity} that exposes the needed values including the id, endpoints, requiredFieldValues etc.
 * @param props.instanceId - the entity instance ID needed to associate the form data with to saving an existing entity. Not needed for saving a new entity.
 * @param props.data - the root data of the form used when saving the entity data
 * @param props.headers - optional headers to pass into the fetch function
 * @param props.options - optional config object
 * @param props.options.fetchFn - You can pass in your own custom fetch function to be used, instead of the default leagueFetch
 * @returns the result of useMutation, which is then used by our EntityDetailsProvider, who is responsible for relaying the result where needed.
 * @returns enableAction - if the operation should exist or not
 */
export const useEntityUpdate = ({
  entity,
  instanceId,
  operation,
  data,
  headers = {
    'Content-Type': 'application/vnd.api+json',
  },
  options,
}: UseEntityUpdateProps) => {
  const { endpoints } = entity;
  const patchOperation = endpoints[operation];

  let mutationFn;

  // we only want to set the mutationFn if the patchOperation exists. Not every entity will have every patch operation.
  if (patchOperation) {
    // the path parameters name value is used in the params prop of endpointFetch
    const entityInstanceIdParam = patchOperation?.pathParameters?.find(
      (param) => patchOperation.path.indexOf(`${param.name}`) > -1,
    );
    const entityInstanceIdParamName = entityInstanceIdParam?.name;

    const params = [{ name: entityInstanceIdParamName!, value: instanceId }];

    const { endpointFetch } = getEntityEndpoint(
      patchOperation,
      options?.fetchFn,
    );

    const body = JSON.stringify({
      data: injectOperationEntityMetadataRequiredFields(data, patchOperation),
    });

    mutationFn = () =>
      endpointFetch({
        params,
        body,
        headers,
      });
  }

  const mutation = useMutation({
    mutationFn,
    onMutate: () => options?.onMutate?.(operation),
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    onSettled: options?.onSettled,
  });

  return {
    ...mutation,
    enableAction: !!patchOperation,
  };
};
