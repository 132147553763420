import { useRef, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { useDataSourceStore } from './data-source-store-provider';

/**
 * Allows a component to subscribe to changes to a specified data source.
 * @param dataSource - the name of the data source
 */

export const useDataSource = (dataSource: string | undefined) => {
  const store = useDataSourceStore();

  /**
   * This ref + useEffect/subscribe approach is recommended by the [zustand docs](https://github.com/pmndrs/zustand?tab=readme-ov-file#transient-updates-for-often-occurring-state-changes)
   * and allows a consumer component to only receive new values when the data of its reference data source
   * updates (avoiding calling it every time the entity root data updates)
   */
  const dataSourceValue = useRef(
    dataSource ? store.getState().dataSources.get(dataSource) : undefined,
  );

  useEffect(() =>
    dataSource
      ? store.subscribe(
          /**
           * First argument is a selector. The returned value is used to track when the slice has updated.
           */
          (state) => state.dataSources.get(dataSource),
          /**
           * This is a callback that fires when the result of the selector does not match the previous value
           */
          (value) => {
            dataSourceValue.current = value;
          },
          {
            equalityFn: isEqual,
          },
        )
      : undefined,
  );

  return dataSourceValue.current;
};
