import * as React from 'react';
import {
  Button,
  HeadingText,
  Modal,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { ConfirmationModalProps } from '../../types/components.types';

// modal width according to designs
const MODAL_CONTENT_WIDTH = 668;

/**
 * ConfirmationModal component displays a modal with a title, description, a cancel button, and a confirm button.
 * The confirm button action can be a link (string) for navigation or a function for custom actions.
 *
 * @param {React.ReactNode} props.modalTrigger - UI Trigger for opening the modal (e.g., a button)
 * @param {string} props.confirmCtaText - Text for the confirm cta button
 * @param {string | (() => void)} props.confirmCtaAction - Action to perform on confirm cta button click (string for navigation, function for custom action)
 * @param {string} props.title - Title of the modal
 * @param {string} props.description - Description within the modal
 * @param {boolean} props.open - Controls the open state of the modal (optional, will use internal state if not provided)
 * @param {() => void} props.onOpenChange - Callback function when the modal's open state changes (optional)
 */

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalTrigger,
  confirmCtaText,
  confirmCtaAction,
  title,
  description,
  open,
  onOpenChange,
}) => {
  const { formatMessage } = useIntl();

  // Internal state to manage modal open/close if 'open' prop is not provided
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpenChange = React.useCallback(() => {
    if (onOpenChange) {
      onOpenChange();
    } else {
      setModalOpen((prevOpen) => !prevOpen);
    }
  }, [onOpenChange]);

  const handlePrimaryButtonClick = () => {
    if (typeof confirmCtaAction === 'function') {
      confirmCtaAction();
    }
    handleOpenChange();
  };

  return (
    <Modal.Root open={open ?? modalOpen} onOpenChange={handleOpenChange}>
      <Modal.Trigger>{modalTrigger}</Modal.Trigger>
      <Modal.Content width={MODAL_CONTENT_WIDTH}>
        <StackLayout spacing="$one" horizontalAlignment="stretch">
          <Modal.Title>
            <HeadingText size="xl" level="2">
              {title}
            </HeadingText>
          </Modal.Title>
          <Modal.Description>
            <ParagraphText>{description}</ParagraphText>
          </Modal.Description>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="end"
            spacing="$one"
          >
            <Modal.Close>
              <Button priority="tertiary" quiet>
                {formatMessage({ id: 'CANCEL' })}
              </Button>
            </Modal.Close>
            {typeof confirmCtaAction === 'string' ? (
              <Button destructive as={Link} to={confirmCtaAction}>
                {confirmCtaText}
              </Button>
            ) : (
              <Button destructive onClick={handlePrimaryButtonClick}>
                {confirmCtaText}
              </Button>
            )}
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
