import { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { EntityFormControlProps } from '../../../types/controls';
import { useGetImageDataById } from './selected-image/use-get-image-data-by-id.hook';

enum Protocol {
  HTTP = 'http:',
  HTTPS = 'https:',
}

const isValidURL = (urlString: string) => {
  let url;

  try {
    url = new URL(urlString);
  } catch {
    return false;
  }

  return url.protocol === Protocol.HTTP || url.protocol === Protocol.HTTPS;
};

type AddModalType = 'id' | 'URL';

const SUPPORTED_FORMATS = ['uri', 'uri-reference'];

export const prependHttps = (url: string) =>
  !isValidURL(url) && url.indexOf('//') === 0 ? `${Protocol.HTTPS}${url}` : url;

export const useImageInputControl = ({
  handleChange,
  path,
  schema,
}: Pick<EntityFormControlProps, 'handleChange' | 'path' | 'schema'>) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [urlError, setUrlError] = useState('');

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalType, setAddModalType] = useState<AddModalType>('id');
  const [isUploadingFromComputer, setIsUploadingFromComputer] = useState(false);

  const {
    isError: isGetImageByIdError,
    isLoading: isLoadingImageById,
    findEntityById,
  } = useGetImageDataById(inputValue, {
    enabled: false,
    retry: 1,
  });

  /**
   * Assets can be tracked via either a contenful id or as a URL. This is determined via the schema.format--if the format is listed as 'uri' or `uri-reference`, the asset should be tracked as a URL.
   *
   * If isImageURL is set to true, then the value stored for the asset should be the URL for the image. Additionally, the UI will include a button to add by URL, and the text in the modal to add by URL will reflect the action accordingly
   */
  const isImageURL = SUPPORTED_FORMATS.includes(schema.format ?? '');

  /**
   * This boolean tracks whether the user has selected the "Add By ID" or "Add by URL" option to open the add item modal. Depending on this value, we will update the text in the modal and the submit function to reflect the style of upload
   */
  const isAddingByURL = addModalType === 'URL';

  const errorMessage =
    !isAddingByURL && isGetImageByIdError
      ? formatMessage({ id: 'UNABLE_TO_FIND_IMAGE' })
      : urlError;

  const addItemTriggerList = [
    {
      buttonLabel: formatMessage({
        id: 'ADD_IMAGE_BY_CONTENTFUL_ID',
      }),
      openFn: () => {
        setAddModalType('id');
        setIsAddModalOpen(true);
      },
    },
    {
      buttonLabel: formatMessage({
        id: 'CHOOSE_IMAGE_FROM_COMPUTER',
      }),
      openFn: () => setIsUploadingFromComputer(true),
    },
  ];
  if (isImageURL) {
    addItemTriggerList.push({
      buttonLabel: formatMessage({ id: 'USE_IMAGE_URL' }),
      openFn: () => {
        setAddModalType('URL');
        setIsAddModalOpen(true);
      },
    });
  }

  // close + submit functions for ID/URL modal
  const closeAddModal = () => {
    setInputValue('');
    setUrlError('');
    setIsAddModalOpen(false);
  };
  const handleSubmitById = () =>
    findEntityById({
      onSuccess: ({ data: response }) => {
        const url = prependHttps(response.data?.attributes?.url);
        handleChange(path, isImageURL ? url : inputValue);
        closeAddModal();
      },
    });
  const handleSubmitByUrl = () => {
    if (isValidURL(inputValue)) {
      handleChange(path, inputValue);
      closeAddModal();
    } else {
      setUrlError(formatMessage({ id: 'INVALID_URL' }));
    }
  };

  // close + submit functions for the upload modal
  const closeUploadImage = () => setIsUploadingFromComputer(false);
  const handleSubmitUploadedImage = ({
    id,
    url,
  }: {
    id: string;
    url: string;
  }) => handleChange(path, isImageURL ? prependHttps(url) : id);

  const addItemModalProps = {
    headingLabel: formatMessage({ id: 'ADD_IMAGE' }),
    fieldLabel: formatMessage({
      id: isAddingByURL ? 'IMAGE_URL' : 'CONTENTFUL_ID',
    }),
    open: isAddModalOpen,
    onCancel: closeAddModal,
    idValue: inputValue,
    setIdValue: setInputValue,
    onSubmit: isAddingByURL ? handleSubmitByUrl : handleSubmitById,
    errorMessage,
    isLoadingSubmission: isLoadingImageById,
    ...(isAddingByURL && {
      inputHint: formatMessage({ id: 'IMAGE_URL_HINT' }),
    }),
  };

  const uploadImageModalProps = {
    open: isUploadingFromComputer,
    onCancel: closeUploadImage,
    onSubmitSuccess: handleSubmitUploadedImage,
  };

  return {
    isImageURL,
    addItemTriggerList,
    addItemModalProps,
    uploadImageModalProps,
  };
};
