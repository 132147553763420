import { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { JsonFormsUISchemaRegistryEntry, findUISchema } from '@jsonforms/core';
import { EntityFormArrayControlProps } from '../../../types/controls';
import { createDefaultValueForSchema } from '../../../utils/create-default-value-for-schema/create-default-value-for-schema';

export interface UseArrayActionsProps
  extends Pick<
    EntityFormArrayControlProps,
    | 'uischema'
    | 'uischemas'
    | 'schema'
    | 'path'
    | 'rootSchema'
    | 'moveUp'
    | 'moveDown'
    | 'removeItems'
  > {
  data: Array<string | undefined> | undefined;
  formPath?: string;
}

export const useArrayPrimitiveActions = ({
  uischema,
  uischemas,
  schema,
  path,
  rootSchema,
  moveDown,
  moveUp,
  removeItems,
  data,
  formPath,
}: UseArrayActionsProps) => {
  /**
   * formPathCache, cacheKeys and dataCache are used only in the context when the control is rendering an array of
   * RichTextInputControl items. RichTextInputControl has some quirks around re-rendering with new content and
   * require passing a key to indicate that it's time to recreate its editor.
   */
  const formPathCache = useRef<string | undefined>(formPath);
  const [cacheKeys, setCacheKeys] = useState<
    (string | undefined)[] | undefined
  >();
  const [dataCache, setDataCache] = useState<(string | undefined)[]>();

  const childUiSchema = useMemo(
    () =>
      findUISchema(
        uischemas as JsonFormsUISchemaRegistryEntry[],
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema,
      ),
    [uischemas, schema, path, uischema, rootSchema],
  );

  const handleCreateDefaultValue = useCallback(
    () => createDefaultValueForSchema(schema),
    [schema],
  );

  const handleRemoveItem = useCallback(
    (itemPath: string, index: number) => () => {
      removeItems(itemPath, [index])();
      setCacheKeys([]);
    },
    [removeItems],
  );

  const handleMoveUp = useCallback(
    (itemPath: string, index: number) => () => {
      moveUp(itemPath, index)();
      setCacheKeys([]);
    },
    [moveUp],
  );

  const handleMoveDown = useCallback(
    (itemPath: string, index: number) => () => {
      moveDown(itemPath, index)();
      setCacheKeys([]);
    },
    [moveDown],
  );

  /**
   * `getArrayItemCacheKey`, like `cacheKeys` and `dataCache` are used only in the context of an array of
   * RichTextInputControl items
   */
  const getArrayItemCacheKey = useCallback(
    (index: number) => (Array.isArray(cacheKeys) ? cacheKeys[index] : null),
    [cacheKeys],
  );

  useEffect(() => {
    /**
     * Our last bit of special handling for RichTextInputControl arrays, this useEffect just handles
     * recalculating the keys passed to the individual controls when required.
     */
    if (schema.format === 'rich-text' && Array.isArray(data)) {
      let refreshKeys = false;
      if (!Array.isArray(dataCache)) {
        /**
         * Initialize the data cache
         */
        setDataCache(data);
        refreshKeys = true;
      } else if (data.length === dataCache.length) {
        /**
         * If the data changed but we have the same number of items, loop through them and
         * check if the order of two items has flipped. If so, the user re-ordered the items.
         *
         * Otherwise, we can assume they simply edited the content in one of the fields.
         */

        data.forEach((item, index) => {
          if (
            typeof item === 'string' &&
            item !== dataCache[index] &&
            (item === dataCache[index - 1] || item === dataCache[index + 1])
          ) {
            refreshKeys = true;
          }
        });
      } else {
        /**
         * An item was added or removed
         */
        refreshKeys = true;
      }

      if (refreshKeys || formPathCache.current !== formPath) {
        setCacheKeys(data.map(() => crypto.randomUUID()));
        formPathCache.current = formPath;
      }

      setDataCache(data);
    }
  }, [data, dataCache, formPath, schema.format]);

  return {
    childUiSchema,
    handleCreateDefaultValue,
    handleMoveUp,
    handleMoveDown,
    handleRemoveItem,
    getArrayItemCacheKey,
  };
};
