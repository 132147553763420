import React from 'react';
import {
  Box,
  Divider,
  Icon,
  StackItem,
  StackLayout,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { SelectMenuItemContentsProps } from '../../../types/components.types';

/**
 * SelectActionMenuItem Contents component renders a single menu item within the SelectActionMenuItem
 *
 * It can be used to display a simple label or a more complex item with a secondary label.
 *
 * @param {React.ReactNode} [children] The contents of the menu item
 *
 * @param {boolean} [selected=false] - Whether the menu item is currently selected.
 * @param {string} [color] - GDSColor color to override default of '$onSurfaceTextPrimary'
 * @param {string} [disabled] - Changes to disabled colors
 * @param {React.ForwardedRef<HTMLDivElement>} ref - A ref to the underlying HTML element so this can be the child of tooltips, modals etc
 */

export const SelectActionMenuItemContents = React.forwardRef(
  (
    {
      children,
      disabled,
      showDivider,
      selected,
      color = '$onSurfaceTextPrimary',
      ...tooltipProps
    }: React.PropsWithChildren<SelectMenuItemContentsProps>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { formatMessage } = useIntl();
    const selectedLabel = formatMessage({ id: 'SELECTED' });
    const extraPaddingRight = typeof children === 'string';

    return (
      <Box
        ref={ref}
        css={{
          borderRadius: 'inherit',
          '&:hover': {
            backgroundColor: disabled
              ? '$interactiveBackgroundPressed'
              : '$inputBackgroundHovered',
            cursor: disabled ? 'default' : 'pointer',
          },
          color: disabled ? '$onSurfaceTextSubdued' : color,
          backgroundColor: disabled
            ? '$interactiveBackgroundPressed'
            : 'inherit',
          'aria-disabled': disabled,
        }}
        // prop spreading needed for tooltip to work properly since the tooltip will pass extra props needed to render the tooltip
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...tooltipProps}
      >
        <StackLayout
          orientation="horizontal"
          verticalAlignment="center"
          css={{
            paddingY: '$threeQuarters',
            paddingLeft: `${selected ? '$half' : '$two'}`,
            paddingRight: `${extraPaddingRight ? '$two' : '$half'}`,
            typography: '$bodyTwo',
            borderBottom: `${
              showDivider
                ? '$borderWidths$thick solid $onSurfaceBorderSubdued'
                : '$none'
            }`,
          }}
        >
          <StackItem
            itemOrder={2}
            css={{ flexDirection: 'row', width: '100%' }}
            verticalAlignment="center"
          >
            {children}
          </StackItem>
          {/* TODO:: https://everlong.atlassian.net/browse/CACT-1506 refactor ActionMenu to rake in menuRole type and checked prop, will allow for removal of passing label to icon */}
          {selected && (
            <Icon
              icon="interfaceCheckmark"
              label={selectedLabel}
              css={{
                marginRight: '$half',
                order: 1,
                color: '$onSurfaceIconPrimary',
              }}
            />
          )}
        </StackLayout>
        {showDivider && (
          <VisuallyHidden>
            <Divider aria-hidden={false} />
          </VisuallyHidden>
        )}
      </Box>
    );
  },
);
