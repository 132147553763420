import { Format } from 'ajv';
import { FormatType } from '../../types';

const jsonLogicFormat: Format = {
  type: 'string',
  // TODO - Make this function actually do stuff in https://everlong.atlassian.net/browse/CACT-621
  validate: (value: unknown) => typeof value === 'string',
};

export const jsonLogicFormatEntry = {
  name: FormatType.JsonLogic,
  format: jsonLogicFormat,
};
