import { AppTenantConfig } from '@web-config-app/core';
import { highmarkActivityRulesTemplates } from './rules-templates';
import { highmarkDomains } from './domains';
import { createTenantConfig } from '../../../utils/create-tenant-config';
import { HIGHMARK_STRINGS_EN } from './strings';

const {
  VITE_HIGHMARK_CONTENT_URL,
  VITE_HIGHMARK_IEL_AUTH0_ORGANIZATION_ID,
  VITE_HIGHMARK_PRIMARY_AUDIENCE,
  VITE_HIGHMARK_WS_API_URL,
  VITE_HIGHMARK_API_URL,
  VITE_HIGHMARK_LEGACY_REST_API_URL,
  VITE_BASE_ENVIRONMENT_ID,
} = import.meta.env;

export const highmarkIELConfig: AppTenantConfig = createTenantConfig({
  core: {
    contentUrl: VITE_HIGHMARK_CONTENT_URL,
    auth: {
      clientOptions: {
        audience: VITE_HIGHMARK_PRIMARY_AUDIENCE,
        organization: VITE_HIGHMARK_IEL_AUTH0_ORGANIZATION_ID,
      },
      idleSessionLifetimeHours: 0.25,
    },
    // TODO: Update analytics once we have a working version for our team with all tenants
    analytics: {
      segmentKey: 'HGr7PK6lxQ8wWZNFBw8Ya4fM8dbLl8jp',
    },
    api: {
      wsUrl: VITE_HIGHMARK_WS_API_URL,
      url: VITE_HIGHMARK_API_URL,
      legacyRestApi: VITE_HIGHMARK_LEGACY_REST_API_URL,
    },
  },
  configAppTenantConfig: {
    tenantName: 'Highmark IEL',
    domains: highmarkDomains,
    authoring: {
      baseEnvironment: {
        apiUrl: VITE_HIGHMARK_API_URL,
        id: VITE_BASE_ENVIRONMENT_ID,
        audience: VITE_HIGHMARK_PRIMARY_AUDIENCE,
      },
    },
  },
  strings: {
    en: HIGHMARK_STRINGS_EN,
  },
  rulesTemplates: highmarkActivityRulesTemplates,
});
