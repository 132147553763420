import React, { FC } from 'react';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { SaveStatus } from '../save-status/save-status.component';
import { ValueWithCopyButton } from '../value-with-copy-button/value-with-copy-button.component';

interface EntityNameAndSaveStatusProps {
  entityInstanceName?: string;
  instanceId?: string;
  saveStatusMessage?: string;
  isEntitySaved: boolean;
  isArchived: boolean;
}

export const EntityNameAndSaveStatus: FC<EntityNameAndSaveStatusProps> = ({
  entityInstanceName,
  instanceId,
  isArchived,
  saveStatusMessage,
  isEntitySaved,
}) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout spacing="$none">
      <HeadingText
        level="1"
        size="lg"
        css={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '30vw',
          '@tablet': {
            maxWidth: '40vw',
          },
          '@laptop': {
            maxWidth: '45vw',
          },
          '@desktop': {
            maxWidth: '60vw',
          },
        }}
      >
        {entityInstanceName}
      </HeadingText>
      <StackLayout
        orientation="horizontal"
        spacing="$half"
        verticalAlignment="center"
        css={{ minHeight: 32 }}
      >
        {instanceId && (
          <ValueWithCopyButton
            value={instanceId}
            buttonLabel={formatMessage({ id: 'COPY_ENTITY_ID' })}
          />
        )}
        {!isArchived && saveStatusMessage && (
          <SaveStatus
            isEntitySaved={isEntitySaved}
            saveStatusMessage={saveStatusMessage}
          />
        )}
      </StackLayout>
    </StackLayout>
  );
};
