import { useIntl } from '@leagueplatform/locales';
import { Entity, EntityRootData, Operation } from '@web-config-app/core';
import {
  UseEntityCreateOptions,
  useEntityCreate,
} from '../use-entity-create/use-entity-create';
import { alterDataForDuplicateEntity } from '../../utilities/alter-data-for-duplicate-entity/alter-data-for-duplicate-entity';

export type UseEntityDuplicateResult = ReturnType<typeof useEntityDuplicate>;
export interface UseEntityDuplicateProps {
  entity: Entity;
  data?: EntityRootData;
  headers?: HeadersInit;
  options?: UseEntityCreateOptions;
}

/**
 * Provides functionality for duplicating an entity as a draft.
 *
 * This hook utilizes the `useEntityCreate` hook to create a new draft entity based on the provided data.
 * It modifies the data by removing the `id` property and prefixing the entity name with a defined prefix.
 *
 * @param {Entity} params.entity - The entity configuration.
 * @param {EntityRootData} params.data - The entity data to be duplicated.
 * @param {UseMutationOptions} [params.options] - Options for the `useEntityCreate` mutation.
 *
 * @returns {Object} An object containing the `duplicateEntity` object with an additional `enableAction` property.
 * The `enableAction` property indicates whether the duplicate action is enabled based on the entity configuration.
 */
export const useEntityDuplicate = ({
  entity,
  data,
  options,
}: UseEntityDuplicateProps) => {
  const { formatMessage } = useIntl();
  const { endpoints } = entity;
  const entityNamePath = entity.schema?.['x-entity-metadata']?.entityNamePath;
  const prefix = formatMessage({ id: 'DUPLICATE_OF' });

  const duplicateEntity = useEntityCreate({
    entity,
    data: alterDataForDuplicateEntity({
      data,
      entityNamePath,
      prefix,
    }),
    options,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
    operation: Operation.DuplicateAsDraft,
  });

  const duplicateAsDraft = {
    ...duplicateEntity,
    enableAction: !!endpoints[Operation.DuplicateAsDraft],
  };
  return duplicateAsDraft;
};
