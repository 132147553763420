import jp from 'jsonpath';
import { EntityRootData } from '@web-config-app/core';
import { get, unset } from 'lodash-es';

/**
 * Alters data for duplicating an entity.
 *
 * This function removes the `id` property and prefixes the entity name with the given prefix.
 * It uses `jsonpath` to access and modify the entity name at the specified path.
 *
 * @param {EntityRootData} [params.data] - The entity data to be modified.
 * @param {string} [params.entityNamePath] - The JSON path to the entity name.
 * @param {string} params.prefix - The prefix to be added to the entity name.
 *
 * @returns {EntityRootData|undefined} - The modified entity data, or undefined if the input data is undefined.
 */
export const alterDataForDuplicateEntity = ({
  data,
  entityNamePath,
  prefix,
}: {
  data?: EntityRootData;
  entityNamePath?: string; // JSON path
  prefix: string;
}) => {
  if (typeof data === 'undefined') {
    return undefined;
  }

  const entityIdPath = 'id';

  // Need to clone the data object because jp.value and unset functions will alter the data passed in.
  const isolatedData = structuredClone(data);

  // confirm the value exists
  const idValue = get(isolatedData, entityIdPath);

  if (idValue) {
    // remove the ID property from data because when duplicating an entity, we do not want to see the original entities ID
    unset(isolatedData, entityIdPath);
  }

  if (typeof entityNamePath === 'undefined') {
    return isolatedData;
  }

  // use `value` function from `jsonpath` to get the instance Name at the path specified
  const instanceName = jp.value(isolatedData, entityNamePath);

  // Build a new name from the prefix
  const newName = `${prefix} ${instanceName}`;

  // set the new name at the name path
  jp.value(isolatedData, entityNamePath, newName);

  return isolatedData;
};
