import { AppTenantConfig } from '@web-config-app/core';
import { createTenantConfig } from '../../../utils/create-tenant-config';
import { pcHealthDomains } from './domains';

const {
  VITE_CAN_CONTENT_URL,
  VITE_PCHEALTH_AUTH0_ORGANIZATION_ID,
  VITE_CA_PRIMARY_AUDIENCE,
  VITE_CAN_WS_API_URL,
  VITE_CAN_API_URL,
  VITE_CAN_LEGACY_REST_API_URL,
  VITE_BASE_ENVIRONMENT_ID,
} = import.meta.env;

export const pcHealthConfig: AppTenantConfig = createTenantConfig({
  core: {
    contentUrl: VITE_CAN_CONTENT_URL,
    auth: {
      clientOptions: {
        audience: VITE_CA_PRIMARY_AUDIENCE,
        organization: VITE_PCHEALTH_AUTH0_ORGANIZATION_ID,
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 1,
    },
    // TODO: Update analytics once we have a working version for our team with all tenants
    analytics: {
      GAPropertyId: 'UA-xxxxxxxx-x',
      segmentKey: 'gqIuNkEL7p5hTUQMfKA1R57GSzgL1xFU',
    },
    api: {
      wsUrl: VITE_CAN_WS_API_URL,
      url: VITE_CAN_API_URL,
      legacyRestApi: VITE_CAN_LEGACY_REST_API_URL,
    },
  },
  configAppTenantConfig: {
    tenantName: 'PC Health',
    domains: pcHealthDomains,
    authoring: {
      baseEnvironment: {
        apiUrl: VITE_CAN_API_URL,
        id: VITE_BASE_ENVIRONMENT_ID,
        audience: VITE_CA_PRIMARY_AUDIENCE,
      },
    },
  },
});
