import type {
  KeywordDefinition,
  KeywordCxt,
  KeywordErrorDefinition,
} from 'ajv';
import { _, str } from 'ajv';

const error: KeywordErrorDefinition = {
  message: str`is a required property`,
};

/**
 * Custom keyword definition applied to string type schemas to extend built-in
 * required validation beyond defined/undefined and ensure that the string is not empty
 */

export const allowEmpty: KeywordDefinition = {
  keyword: 'allowEmpty',
  schemaType: 'boolean',
  type: 'string',
  error,
  code(cxt: KeywordCxt) {
    const { data, schema: keywordValue, gen } = cxt;
    /**
     * We only want to care about this if the schema has the `allowEmpty` keyword and it is set to `false`
     */
    if (keywordValue !== false) return;

    gen.if(_`typeof ${data} === 'string' && ${data}.trim() === ''`, () =>
      cxt.error(),
    );
  },
};
