import type { AppTenantConfig } from '@web-config-app/core';

export const validateTenantConfig = (tenantConfig: AppTenantConfig) => {
  const {
    configApp: {
      authoring: { baseEnvironment, previewEnvironments },
    },
  } = tenantConfig;
  if (Array.isArray(previewEnvironments)) {
    const environmentIds: string[] = [baseEnvironment.id];
    previewEnvironments.forEach(({ type, id }) => {
      if (type === 'base') {
        throw new Error(
          `Authoring Config: Invalid type 'base' found for preview environment.`,
        );
      }
      // @ts-expect-error since the configs are env specific it may be possible to have an unexpected value here
      if (id === 'production') {
        throw new Error(
          `Authoring Config: Invalid id 'production' found for preview environment.`,
        );
      }
      if (environmentIds.includes(id)) {
        throw new Error(
          `Authoring Config: Found duplicate authoring environments with using the '${id}' id. Each environment needs a unique id`,
        );
      }
      environmentIds.push(id);
    });
  }
  return tenantConfig;
};
