import * as React from 'react';
import { Tooltip } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  WorkflowActionModal,
  MenuItemWorkflowAction,
} from '@web-config-app/core';
import {
  ConfirmationModal,
  SelectActionMenuItemContents,
} from '@web-config-app/core-react-ui';

interface EntityMenuItemProps {
  item: MenuItemWorkflowAction;
  entityConfigName?: string;
  entityListPath: string;
}

export const EntityMenuItem: React.FC<EntityMenuItemProps> = ({
  item,
  entityConfigName,
  entityListPath,
}) => {
  const { formatMessage } = useIntl();
  const { disabled, label, tooltip, modalType } = item;

  if (disabled && tooltip) {
    return (
      <Tooltip
        align="center"
        avoidCollisions
        content={tooltip}
        side="left"
        sideOffset={30}
      >
        <SelectActionMenuItemContents disabled={disabled}>
          {label}
        </SelectActionMenuItemContents>
      </Tooltip>
    );
  }

  if (modalType === WorkflowActionModal.confirmation) {
    return (
      <ConfirmationModal
        modalTrigger={
          <SelectActionMenuItemContents
            disabled={disabled}
            color="$onSurfaceTextCritical"
          >
            {label}
          </SelectActionMenuItemContents>
        }
        title={formatMessage(
          { id: 'EXIT_ENTITY_DETAIL_TITLE' },
          { name: entityConfigName },
        )}
        description={formatMessage({
          id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
        })}
        confirmCtaText={formatMessage({
          id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
        })}
        confirmCtaAction={entityListPath}
      />
    );
  }

  return <SelectActionMenuItemContents>{label}</SelectActionMenuItemContents>;
};
