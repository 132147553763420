import React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { ErrorState } from '@leagueplatform/web-common-components';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export function ErrorBoundaryWrapper({ children }: ErrorBoundaryProps) {
  return (
    <ObservabilityErrorBoundary
      fallback={
        <StackLayout
          data-testid="error-boundary-fallback"
          horizontalAlignment="center"
          verticalAlignment="center"
          css={{ height: '100vh' }}
        >
          <ErrorState />
        </StackLayout>
      }
    >
      {children}
    </ObservabilityErrorBoundary>
  );
}
