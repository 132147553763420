import { useMemo } from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import type { EntityStatus } from '@web-config-app/core';
import { AuthoringEnvironmentId } from '@web-config-app/core';
import type {
  GetEntityDetailsPathForEnvAndId,
  GetEntityCreatePathForEnv,
} from '../use-config-app-path/use-config-app-path';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';

export const useEntityDetailsEnvironmentSelect = ({
  status,
  environmentId,
  entityInstanceId,
  getEntityDetailsPathForEnvAndId,
  getEntityCreatePathForEnv,
}: {
  status: EntityStatus;
  environmentId: AuthoringEnvironmentId;
  entityInstanceId?: string;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  getEntityCreatePathForEnv: GetEntityCreatePathForEnv;
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const {
    currentAuthoringEnvironment,
    multiEnvironmentAuthoringEnabled,
    environmentMap,
  } = useAuthoringEnvironments({ environmentId });

  // TODO: not all environments from `environmentMap` should be shown to the user. If an entity does not exist on a given env (calling the API with given ID returns blah),
  // that env should not be an option for `availableEnvironments` array, but instead, should be shown as the `copy to <env>` option instead
  // (part of additionalActions) where POST is called to create it.
  const availableEnvironments = useMemo(
    () =>
      multiEnvironmentAuthoringEnabled && environmentMap instanceof Map
        ? Array.from(environmentMap.values()).map?.((clientInfo) => ({
            id: clientInfo.id,
            label: formatMessage({ id: clientInfo.nameKey }),
            // TODO: The status section of availableEnvironments is to be updated correctly to handle setting the status of all environments correctly
            // https://everlong.atlassian.net/browse/CACT-1538
            status:
              currentAuthoringEnvironment === clientInfo.id
                ? status
                : undefined,
            action: () => {
              history.push(
                entityInstanceId
                  ? getEntityDetailsPathForEnvAndId(
                      entityInstanceId,
                      clientInfo.id,
                    )
                  : getEntityCreatePathForEnv(clientInfo.id),
              );
            },
          }))
        : null,
    [
      multiEnvironmentAuthoringEnabled,
      environmentMap,
      currentAuthoringEnvironment,
      status,
      history,
      entityInstanceId,
      getEntityDetailsPathForEnvAndId,
      getEntityCreatePathForEnv,
      formatMessage,
    ],
  );
  return {
    currentEnvironmentId: currentAuthoringEnvironment,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
  };
};
