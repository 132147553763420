import { RulesTemplate } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

export interface UseRulesTemplatesProps {
  entityType?: string;
  rulesType?: RulesTemplate['type'];
}

export const useRulesTemplates = (
  props: UseRulesTemplatesProps | undefined,
) => {
  const { entityType, rulesType } = props ?? {};
  const { rulesTemplates } = useConfigApp();

  if (!rulesType) return undefined;

  const allRulesTemplates = Object.values(rulesTemplates ?? {});

  return entityType || rulesType
    ? allRulesTemplates.filter((rulesTemplate) => {
        if (entityType && rulesType) {
          return (
            rulesTemplate.entityType === entityType &&
            rulesTemplate.type === rulesType
          );
        }

        if (entityType) {
          return rulesTemplate.entityType === entityType;
        }

        if (rulesType) {
          return rulesTemplate.type === rulesType;
        }

        return false;
      })
    : allRulesTemplates;
};
