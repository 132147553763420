import {
  CoreActions,
  JsonFormsCore,
  UPDATE_CORE,
  UPDATE_DATA,
} from '@jsonforms/core';
import { filterOutErrorsByKeywords } from '@web-config-app/validation';

/**
 * Creates a [middleware function  for JsonForms](https://jsonforms.io/docs/middleware/#:~:text=JSON%20Forms%20offers%20the%20option,data%20updates%20and%20validation%20methods.) to filter out specific error types based on their error keywords from ajv.
 *
 * This middleware intercepts the `UPDATE_CORE` and `UPDATE_DATA` actions and filters out any errors
 * whose `keyword` property matches one of the keywords in the `filteredErrorKeywords` array.
 *
 * @param filteredErrorKeywords- An array of error keywords to be filtered out.
 * @returns A middleware function that can be used with JsonForms.
 */
export const createErrorFilteringMiddleware =
  (filteredErrorKeywords: string[]) =>
  (
    state: JsonFormsCore,
    action: CoreActions,
    defaultReducer: (
      state: JsonFormsCore,
      action: CoreActions,
    ) => JsonFormsCore,
  ) => {
    const newState = defaultReducer(state, action);
    switch (action.type) {
      case UPDATE_CORE:
      case UPDATE_DATA: {
        if (newState.errors && newState.errors?.length > 0) {
          newState.errors = filterOutErrorsByKeywords({
            errors: newState.errors,
            filteredErrorKeywords,
          });
        }
        return newState;
      }
      default:
        return newState;
    }
  };
