import * as React from 'react';
import {
  UtilityText,
  StatusBadge,
  RadioIndicator,
  focusOutlineOuter,
  Icon,
} from '@leagueplatform/genesis-core';
import { EntityDetail, getEntityStatusBadgeProps } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { FormattedRelativeTime } from 'react-intl';
import {
  Table,
  TableRow,
  TableHeading,
  TableCell,
} from '../table-pieces.component';
import { EntityListTableSkeleton } from '../entity-list-table-skeleton/entity-list-table-skeleton.component';
import { getTimeSinceLastUpdated } from '../get-time-since-last-updated';

interface SelectableEntityListTableProps {
  entityData?: EntityDetail[];
  onEntitySelected: (entity: EntityDetail) => void;
  getItemName: (data: EntityDetail) => string;
  isLoading?: boolean;
}

export const SelectableEntityListTable = ({
  entityData,
  onEntitySelected,
  getItemName,
  isLoading = false,
}: SelectableEntityListTableProps) => {
  const { formatMessage } = useIntl();
  return (
    <Table>
      <thead>
        <tr>
          <TableHeading css={{ width: '$twoAndHalf', textAlign: 'center' }}>
            <Icon
              icon="interfaceCheckmark"
              size="$one"
              label={formatMessage({
                id: 'SELECTABLE_TABLE_ROW_STATUS_HEADING',
              })}
            />
          </TableHeading>
          <TableHeading>{formatMessage({ id: 'NAME' })}</TableHeading>
          <TableHeading css={{ width: '20%' }}>
            {formatMessage({ id: 'ID' })}
          </TableHeading>
          <TableHeading css={{ width: '15%' }}>
            {formatMessage({ id: 'LAST_UPDATED' })}
          </TableHeading>
          <TableHeading css={{ width: '15%' }}>
            {formatMessage({ id: 'STATUS' })}
          </TableHeading>
        </tr>
      </thead>

      {isLoading ? (
        <EntityListTableSkeleton selectable />
      ) : (
        <tbody>
          {entityData?.map((item) => {
            const statusBadgeProps = getEntityStatusBadgeProps(
              item.attributes.entityMetadata.status,
            );
            const timeSinceLastUpdated = getTimeSinceLastUpdated(
              item.attributes.entityMetadata.lastUpdatedDate,
            );
            return (
              <TableRow
                key={item.id}
                css={{
                  '&:focus-within': focusOutlineOuter,
                  borderRadius: '$small',
                }}
              >
                <TableCell css={{ paddingY: 17 }}>
                  <RadioIndicator
                    id={`entity-${item.id}`}
                    name="entity-select"
                    onChange={() => {
                      onEntitySelected(item);
                    }}
                  />
                </TableCell>
                {/* NAME */}
                <TableCell
                  as="th"
                  scope="row"
                  css={{
                    position: 'relative',
                    paddingY: 17,
                  }}
                >
                  <UtilityText
                    as="label"
                    htmlFor={`entity-${item.id}`}
                    css={{
                      '&:after': {
                        content: '',
                        display: 'block',
                        position: 'absolute',
                        width: '87vw',
                        inset: 0,
                        cursor: 'pointer',
                      },
                    }}
                  >
                    {getItemName(item)}
                  </UtilityText>
                </TableCell>

                {/* ID */}
                <TableCell css={{ paddingY: 17 }}>
                  <UtilityText
                    emphasis="subtle"
                    size="sm"
                    css={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.id}
                  </UtilityText>
                </TableCell>

                {/* DATE */}
                <TableCell css={{ paddingY: 17 }}>
                  <UtilityText>
                    <FormattedRelativeTime
                      value={timeSinceLastUpdated}
                      numeric="auto"
                      updateIntervalInSeconds={60}
                    />
                  </UtilityText>
                </TableCell>

                {/* STATUS */}
                <TableCell css={{ paddingY: 17 }}>
                  <StatusBadge
                    status={statusBadgeProps.genesisStatus}
                    label={formatMessage({
                      id: statusBadgeProps.labelKey,
                    })}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      )}
    </Table>
  );
};
