import type {
  AnnotatedJsonSchema,
  SchemaTransformerArgs,
} from '@web-config-app/core';
import { getSchemaWithMutatedProperties } from '../../get-schema-with-mutated-properties/get-schema-with-mutated-properties';
import { isArrayOrObjectSchema } from '../../is-array-or-object-schema/is-array-or-object-schema';

/**
 * Enforces special object card notations that apply to the root node only
 */

export const computeRootNodeSchema = ({ schema }: SchemaTransformerArgs) => {
  const mutatedRootNodeSchema = getSchemaWithMutatedProperties({
    schema,
    mutateProperties: (propertySchema: AnnotatedJsonSchema) =>
      isArrayOrObjectSchema(propertySchema)
        ? {
            ...propertySchema,
            'x-entity-control': { type: 'objectCard' },
          }
        : propertySchema,
  });

  return {
    ...mutatedRootNodeSchema,
    ...(schema.required && {
      required: schema.required.filter((property: string) => {
        const propertySchema = mutatedRootNodeSchema.properties?.[property];
        return (
          propertySchema &&
          propertySchema['x-entity-control']?.type !== 'objectCard'
        );
      }),
    }),
  };
};
