import * as React from 'react';
import { useDebounce } from '@leagueplatform/web-common';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { isEqual, isEmpty } from 'lodash-es';

export type UseEntityFormStateProps = {
  onFormDataChange: (updatedData: any) => void;
  formDataChangeDebounceLength?: number;
  formSchema?: AnnotatedJsonSchema;
  formPath?: string;
  formData?: any;
};

export const useEntityFormState = ({
  onFormDataChange,
  formDataChangeDebounceLength = 300,
  formSchema,
  formData,
  formPath,
}: UseEntityFormStateProps) => {
  const [localData, setLocalData] = React.useState(formData);
  const [localSchema, setLocalSchema] = React.useState<
    AnnotatedJsonSchema | undefined
  >(formSchema);
  const debouncedData = useDebounce(localData, formDataChangeDebounceLength);

  const formDataChangeHandler = React.useCallback(
    (updatedData: any) => {
      if (updatedData && !isEqual(updatedData, localData)) {
        setLocalData(updatedData);
      }
    },
    [localData],
  );

  /**
   * Only run this effect that passed the data to the onFormDataChange when the debounced data updates.
   * This prevents triggering an update of the root entity data too often, for example when a user is
   * typing we can only update the root data when the value of `debouncedData` has changed instead of
   * on every keystroke.
   */
  React.useEffect(() => {
    if (
      debouncedData &&
      (!isEmpty(debouncedData) || Array.isArray(debouncedData)) &&
      !isEqual(debouncedData, formData)
    ) {
      onFormDataChange(debouncedData);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [debouncedData]);

  React.useEffect(() => {
    setLocalData(formData);
    setLocalSchema(formSchema);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [formPath]);

  // TODO: Temporary fix, we should revisit our entityRootData / formData / localData implementation
  // on page refresh, localData gets set as an empty object and we do not update
  // it with the result of our formData call. This hook will run when formData changes
  // and check if our formData is populated but our localData is not. In that case,
  // we set the local data with our new formData
  React.useEffect(() => {
    if (localData === undefined && formData !== undefined) {
      setLocalData(formData);
    }
  }, [formData, localData]);

  React.useEffect(() => {
    /**
     * On possible formSchema changes from x-entity-conditional annotation, we need to update the localSchema to match the formSchema and ensure correct schema is rendered and that the entity form updates if needed
     */
    if (!isEqual(formSchema, localSchema)) {
      setLocalSchema(formSchema);
    }
  }, [formSchema]);

  return {
    localData,
    localSchema,
    formDataChangeHandler,
  };
};
