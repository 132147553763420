import { SchemaTransformerArgs } from '@web-config-app/core';
import jsonLogic from 'json-logic-js';
import { getConditionOfType } from '../get-condition-of-type/get-condition-of-type';

/**
 * This function handles the `disabledIf` type of `x-entity-conditional` annotation
 * @param schema - the current schema slice
 * @param data - the form data
 * @returns an annotatedJsonSchema with the readOnly flag set to true (which will disable a given control), if the json logic rule evaluates to true,
 * otherwise returns the schema as is
 */
export const evaluateDisabledIfCondition = ({
  schema,
  data,
}: SchemaTransformerArgs) => {
  const disabledIfCondition = getConditionOfType(schema, 'disabledIf');

  if (!disabledIfCondition) return schema;

  // TODO: Future enhancement (or not): this probably also need to traverse an object and recursively disable all child properties
  const computedSchema = jsonLogic.apply(disabledIfCondition.rules, data)
    ? {
        ...schema,
        readOnly: true,
      }
    : schema;
  return computedSchema;
};
