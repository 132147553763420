import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  InputStatusMessage,
} from '@leagueplatform/genesis-core';
import { EntityFormBanner, HintText } from '@web-config-app/core-react-ui';
import { CompoundPrimitiveGroupFieldsetProps } from '../../types/components';

/**
 * CompoundPrimitiveGroupFieldset is used for 
 * type ObjectPrimitive = {
    children: Primitive | ArrayPrimitive
  }
  It is the same as CompoundPrimitiveGroup except renders a fieldset
  css prop is applied to the direct parent el of the rendered children
 */

export const CompoundPrimitiveGroupFieldset: React.FC<
  React.PropsWithChildren<CompoundPrimitiveGroupFieldsetProps>
> = ({ hint, label, banner, children, childWrapperCss, error }) => {
  const { formatMessage } = useIntl();
  const showBanner = banner?.title || banner?.description;

  return (
    <Box css={{ marginTop: '$oneAndQuarter', marginBottom: '$two' }}>
      {showBanner && (
        <EntityFormBanner
          status={banner.status}
          title={banner.title}
          description={banner.description}
          css={{ marginBottom: '$half' }}
        />
      )}
      <Box
        as="fieldset"
        css={{ width: '100%', borderWidth: '0px', padding: 0, margin: 0 }}
      >
        <HeadingText
          size="xs"
          as="legend"
          level="display"
          emphasis="subtle"
          css={{ padding: 0 }}
        >
          {label}
        </HeadingText>
        {hint && (
          <HintText size="sm" css={{ paddingTop: '$quarter' }}>
            {hint}
          </HintText>
        )}
        {error && (
          <InputStatusMessage
            data-testid="error-message"
            inputStatus="error"
            statusIconLabel={formatMessage({ id: 'ERROR' })}
            css={{ marginTop: '$half' }}
          >
            {error}
          </InputStatusMessage>
        )}
        <Box
          css={{
            marginTop: '$threeQuarters',
            ...childWrapperCss,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
