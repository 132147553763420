import type { SchemaTransformerArgs } from '@web-config-app/core';

/**
 * Recalculates the schema required properties
 */

export const recalculateRequiredProperties = ({
  schema,
}: SchemaTransformerArgs) => {
  if (schema.type !== 'object' || !schema.required) {
    return schema;
  }

  return {
    ...schema,
    required: schema.required.filter((property: string) => {
      const propertySchema = schema.properties?.[property];
      return (
        propertySchema &&
        propertySchema['x-entity-control']?.type !== 'objectCard'
      );
    }),
  };
};
