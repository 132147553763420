import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingIndicator } from '../../components/loading-indicator.component';
import { useTenantId } from '../../hooks/use-tenant-id/use-tenant-id';

export const AuthViewLogout = () => {
  const { setTenantId } = useTenantId();
  useEffect(() => {
    setTenantId('');
    StandaloneAuth.client.logout({ returnTo: window.location.origin });
  }, [setTenantId]);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <LoadingIndicator />;
};
