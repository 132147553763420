import * as React from 'react';
import { composePaths } from '@jsonforms/core';
import { StackLayout, StackItem, Box } from '@leagueplatform/genesis-core';
import { JsonFormsDispatch } from '@jsonforms/react';
import { EntityFormArrayControl } from '../../entity-form-array-control/entity-form-array-control.component';
import { ArrayPrimitiveGroup } from '../../array-primitive-group/array-primitive-group.components';
import { ArrayAddButton } from '../../array-add-button/array-add-button.component';
import { ArrayActionButtons } from '../../array-action-buttons/array-action-buttons.component';
import { NumberIcon } from '../../number-icon/number-icon.component';
import {
  ArrayControl,
  EntityFormArrayControlProps,
} from '../../../types/controls';
import { EmptyArrayState } from '../../empty-array-state/empty-array-state.component';
import { RichTextControlArrayItemProvider } from '../../controls-primitive/rich-text-input-control/rich-text-control-array-item-provider';
import { useArrayPrimitiveActions } from './use-array-primitive-actions';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';

export const ArrayPrimitiveContents = ({
  id,
  hint,
  label,
  banner,
  data,
  errors,
  addItem,
  removeItems,
  moveDown,
  moveUp,
  arrayAddLabel,
  path,
  schema,
  rootSchema,
  uischemas = [],
  uischema,
  renderers,
}: EntityFormArrayControlProps) => {
  const entityDetailsProps = useEntityDetailsProps();
  const formPath = entityDetailsProps?.formPath;

  const {
    childUiSchema,
    handleCreateDefaultValue,
    handleMoveDown,
    handleMoveUp,
    handleRemoveItem,
    getArrayItemCacheKey,
  } = useArrayPrimitiveActions({
    removeItems,
    moveDown,
    moveUp,
    rootSchema,
    uischemas,
    uischema,
    path,
    schema,
    data,
    formPath,
  });

  const arrayItemsEmpty = !data || data?.length === 0;

  return (
    <Box css={{ marginTop: '$oneAndQuarter', marginBottom: '$two' }}>
      <ArrayPrimitiveGroup
        hint={hint}
        label={label}
        banner={banner}
        errors={errors}
      >
        {arrayItemsEmpty ? (
          <EmptyArrayState item={label} />
        ) : (
          data?.map((_: any, index: number) => {
            const childPath = composePaths(path, `${index}`);
            const listItemNumber = index + 1;
            const uniqueId = `${id}-${index}`;

            return (
              <StackLayout
                key={uniqueId}
                as="li"
                orientation="horizontal"
                verticalAlignment="center"
                spacing="$half"
                css={{
                  width: '100%',
                  marginTop: '$half',
                  flexGrow: 0,
                }}
              >
                <StackItem>
                  <NumberIcon number={listItemNumber} />
                </StackItem>
                {/* data-array-primitive-item used on rendered children to hide annotation ui and label 
                TODO:: rework with annotation https://everlong.atlassian.net/browse/CACT-849
              */}
                <StackItem css={{ width: '100%' }} data-array-primitive-item>
                  <RichTextControlArrayItemProvider
                    cacheKey={getArrayItemCacheKey(index) ?? ''}
                  >
                    <JsonFormsDispatch
                      schema={schema}
                      uischema={childUiSchema || uischema}
                      path={childPath}
                      key={childPath}
                      renderers={renderers}
                    />
                  </RichTextControlArrayItemProvider>
                </StackItem>
                <StackItem>
                  <ArrayActionButtons
                    arrayLength={data.length}
                    itemIndex={index}
                    itemLabel={label}
                    onDelete={handleRemoveItem(path, index)}
                    onMoveDown={handleMoveDown(path, index)}
                    onMoveUp={handleMoveUp(path, index)}
                  />
                </StackItem>
              </StackLayout>
            );
          })
        )}
      </ArrayPrimitiveGroup>
      <ArrayAddButton
        addButtonLabel={arrayAddLabel}
        addItem={addItem}
        path={path}
        createDefaultValue={handleCreateDefaultValue}
      />
    </Box>
  );
};

const renderControl = (formContentsProps: EntityFormArrayControlProps) => (
  <ArrayPrimitiveContents {...formContentsProps} />
);

export const ArrayPrimitiveControl: ArrayControl = (props) => (
  <EntityFormArrayControl {...props} renderControl={renderControl} />
);
