import * as React from 'react';

interface RichTextControlArrayItemContextValueProps {
  cacheKey?: string;
}

interface RichTextControlArrayItemContextValue
  extends RichTextControlArrayItemContextValueProps {
  isArrayItem: boolean;
}

export const RichTextControlArrayItemContext =
  React.createContext<RichTextControlArrayItemContextValue>({
    /**
     * By initializing the context with a default value of `isArrayItem: false`, we allow
     * a RichTextInputControl that is NOT nested within a RichTextControlArrayItemProvider
     * to still implement `useRichTextControlArrayItemCacheKey` and it will receive a
     * return value of simply `{ isArrayItem: false }`
     */
    isArrayItem: false,
  });

export const RichTextControlArrayItemProvider = ({
  children,
  cacheKey,
}: React.PropsWithChildren<RichTextControlArrayItemContextValueProps>) => {
  const value = React.useMemo(
    () => ({
      cacheKey,
      isArrayItem: true,
    }),
    [cacheKey],
  );
  return (
    <RichTextControlArrayItemContext.Provider value={value}>
      {children}
    </RichTextControlArrayItemContext.Provider>
  );
};

export const useRichTextControlArrayItemCacheKey = () => {
  const ctx = React.useContext(RichTextControlArrayItemContext);

  return ctx;
};
