import * as React from 'react';
import { useAuth } from '@leagueplatform/auth-utils';
import { useGetPermissions } from '@leagueplatform/permissions';
import { useFilterEntitiesBasedOnPermissions } from '@web-config-app/core-react';
import { AuthViewLogout } from '../../pages/auth/Auth.pages';
import { LoadingIndicator } from '../loading-indicator.component';
import { useLoginValidation } from '../../hooks/use-login-validation/use-login-validation';
import { useTenantId } from '../../hooks/use-tenant-id/use-tenant-id';

export const AuthorizedRoutes = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isAuthenticated, error: authError } = useAuth({
    redirectToLogInWhenSignedOut: true,
  });

  const { tenantId } = useTenantId();
  const { setLoginValidation } = useLoginValidation();

  const { status: permissionsRequestStatus } = useGetPermissions();

  useFilterEntitiesBasedOnPermissions({
    isAuthenticated,
    permissionsRequestStatus,
  });

  React.useEffect(() => {
    if (authError && authError?.type === 'access_denied') {
      setLoginValidation({
        inputStatus: 'error',
        statusMessage: `Sorry, you do not have access to ${tenantId} tenant ID. Try again or contact League Admin.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError?.type]);

  if (permissionsRequestStatus === 'error') {
    throw new Error('Unable to access user permissions');
  }

  if (authError) {
    return <AuthViewLogout />;
  }

  if (!isAuthenticated || permissionsRequestStatus === 'loading')
    return <LoadingIndicator />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
