import { AppTenantConfig } from '@web-config-app/core';
import { createTenantConfig } from '../../../utils/create-tenant-config';
import { scanDomains } from './domains';

const {
  VITE_US_CONTENT_URL,
  VITE_SCAN_AUTH0_ORGANIZATION_ID,
  VITE_US_PRIMARY_AUDIENCE,
  VITE_US_WS_API_URL,
  VITE_US_API_URL,
  VITE_US_LEGACY_REST_API_URL,
  VITE_AUTH0_DOMAIN_WIP,
  VITE_AUTH0_CLIENT_ID_WIP,
  VITE_ENV_WIP,
} = import.meta.env;

export const scanConfig: AppTenantConfig = createTenantConfig({
  core: {
    appEnvironment: VITE_ENV_WIP,
    contentUrl: VITE_US_CONTENT_URL,
    auth: {
      clientOptions: {
        audience: VITE_US_PRIMARY_AUDIENCE,
        organization: VITE_SCAN_AUTH0_ORGANIZATION_ID,
        domain: VITE_AUTH0_DOMAIN_WIP,
        client_id: VITE_AUTH0_CLIENT_ID_WIP,
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 1,
    },
    // TODO: Update analytics once we have a working version for our team with all tenants
    analytics: {
      GAPropertyId: 'UA-xxxxxxxx-x',
      segmentKey: 'gqIuNkEL7p5hTUQMfKA1R57GSzgL1xFU',
    },
    api: {
      wsUrl: VITE_US_WS_API_URL,
      url: VITE_US_API_URL,
      legacyRestApi: VITE_US_LEGACY_REST_API_URL,
    },
  },
  configAppTenantConfig: {
    tenantName: 'SCAN',
    domains: scanDomains,
    authoring: {
      baseEnvironment: {
        apiUrl: VITE_US_API_URL,
        id: VITE_ENV_WIP,
        audience: VITE_US_PRIMARY_AUDIENCE,
      },
    },
  },
});
