import React, { FC } from 'react';
import {
  HeadingBar,
  Divider,
  StackLayout,
  Button,
  SkeletonText,
  SkeletonRectangle,
  focusOutlineInner,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import type {
  EntityDetailAction,
  WorkflowAction,
  StatusBadge,
  AuthoringEnvironmentId,
  Nullable,
} from '@web-config-app/core';
import { EntityDetailsRightHeaderActions } from '../entity-details-right-header-actions/entity-details-right-header-actions.component';
import { EntityNameAndSaveStatus } from '../entity-name-and-save-status/entity-name-and-save-status.component';
import { ConfirmationModal } from '../confirmation-modal/confirmation-modal.component';
import { AvailableEnvironment } from '../../types/components.types';

export interface EntityDetailsHeaderProps {
  menuItemGroup: {
    menuItems: {
      id: EntityDetailAction;
      label: React.ReactNode;
    }[];
    onMenuItemClicked: (itemId: EntityDetailAction) => void;
  };
  isLoading: boolean;
  secondaryAction?: WorkflowAction;
  primaryAction?: WorkflowAction;
  statusBadge?: StatusBadge;
  entityInstanceName?: string;
  entityConfigName: string;
  exitModalPrimaryButtonPath: string;
  saveStatusMessage?: string;
  isEntitySaved: boolean;
  isArchived: boolean;
  instanceId?: string;
  currentEnvironmentId: AuthoringEnvironmentId;
  availableEnvironments: Nullable<AvailableEnvironment[]>;
  multiEnvironmentAuthoringEnabled: boolean;
}

const commonButtonCssStyles: GDSStyleObject = {
  padding: '$oneAndHalf',
  border: 0,
  '&[class*="GDS"]:focus': {
    outlineOffset: 0,
    outline: 0,
  },
  '&:focus': {
    ...focusOutlineInner,
  },
};

const EntityDetailsHeaderTriggerLink = ({
  entityListPath,
}: {
  entityListPath: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      icon="tinyClose"
      hideLabel
      priority="tertiary"
      quiet
      size="toolbar"
      as={Link}
      to={entityListPath}
      css={{ ...commonButtonCssStyles }}
    >
      {formatMessage({ id: 'EXIT' })}
    </Button>
  );
};

export const EntityDetailsHeaderSkeleton = () => (
  <HeadingBar
    css={{
      width: '100%',
    }}
    leftAction={
      <StackLayout spacing="$half" css={{ minWidth: 300 }}>
        <SkeletonText header />
        <SkeletonText />
      </StackLayout>
    }
    rightAction={
      <StackLayout
        orientation="horizontal"
        spacing="$one"
        verticalAlignment="center"
      >
        <SkeletonText width={100} />
        <SkeletonRectangle height={38} width={120} />
        <SkeletonRectangle height={38} />
      </StackLayout>
    }
  />
);

export const EntityDetailsHeader: FC<EntityDetailsHeaderProps> = ({
  menuItemGroup,
  secondaryAction,
  primaryAction,
  statusBadge,
  exitModalPrimaryButtonPath,
  isLoading = false,
  entityInstanceName,
  entityConfigName,
  saveStatusMessage,
  isArchived,
  isEntitySaved,
  instanceId,
  currentEnvironmentId,
  availableEnvironments,
  multiEnvironmentAuthoringEnabled,
}) => {
  const { formatMessage } = useIntl();
  const exitModalTitle = formatMessage(
    { id: 'EXIT_ENTITY_DETAIL_TITLE' },
    { name: entityConfigName },
  );
  const exitModalDescription = formatMessage({
    id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
  });

  const confirmCtaText = formatMessage({
    id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
  });
  return (
    <StackLayout
      orientation="horizontal"
      css={{ alignItems: 'center', height: '100%' }}
    >
      {!isEntitySaved ? (
        <ConfirmationModal
          modalTrigger={
            <Button
              icon="tinyClose"
              hideLabel
              priority="tertiary"
              quiet
              size="toolbar"
              css={{ ...commonButtonCssStyles }}
            >
              {formatMessage({ id: 'EXIT' })}
            </Button>
          }
          title={exitModalTitle}
          description={exitModalDescription}
          confirmCtaText={confirmCtaText}
          confirmCtaAction={exitModalPrimaryButtonPath}
        />
      ) : (
        <EntityDetailsHeaderTriggerLink
          entityListPath={exitModalPrimaryButtonPath}
        />
      )}
      <Divider orientation="vertical" css={{ margin: 0 }} />
      {isLoading ? (
        <EntityDetailsHeaderSkeleton />
      ) : (
        <HeadingBar
          css={{
            width: '100%',
            '.GDS-heading-bar-base': {
              paddingX: '$oneAndQuarter',
              gap: '$one',
            },
          }}
          leftAction={
            <EntityNameAndSaveStatus
              entityInstanceName={entityInstanceName}
              instanceId={instanceId}
              isArchived={isArchived}
              isEntitySaved={isEntitySaved}
              saveStatusMessage={saveStatusMessage}
            />
          }
          rightAction={
            <EntityDetailsRightHeaderActions
              entityConfigName={entityConfigName}
              currentEnvironmentId={currentEnvironmentId}
              availableEnvironments={availableEnvironments}
              multiEnvironmentAuthoringEnabled={
                multiEnvironmentAuthoringEnabled
              }
              statusBadge={statusBadge}
              menuItemGroup={menuItemGroup}
              primaryAction={
                primaryAction?.display && (
                  <Button
                    priority="primary"
                    size="medium"
                    disabled={primaryAction?.disabled}
                    onClick={() => {
                      primaryAction?.action?.();
                    }}
                  >
                    {primaryAction?.label}
                  </Button>
                )
              }
              secondaryAction={
                secondaryAction?.display && (
                  <Button
                    priority="secondary"
                    size="medium"
                    onClick={() => {
                      secondaryAction?.action?.();
                    }}
                    disabled={secondaryAction?.disabled}
                  >
                    {secondaryAction?.label}
                  </Button>
                )
              }
            />
          }
        />
      )}
    </StackLayout>
  );
};
