import AJV, { Options, KeywordDefinition } from 'ajv';
import ajvErrors from 'ajv-errors';
import addFormats from 'ajv-formats';
import { FormatDefinition } from '../types';

const defaultOptions = {
  allErrors: true,
  verbose: true,
  strict: false,
  addUsedSchema: false,
  $data: true,
  discriminator: true,
  multipleOfPrecision: 12, // this option allows for more accuracy when validating multipleOf with floating point numbers
};

export interface CreateAjvParameters {
  keywords?: KeywordDefinition[];
  formats?: FormatDefinition[];
  options?: Options;
}

const defaultArgs = {
  options: defaultOptions,
};

/**
 * @function createAjv
 *
 * Returns an AJV instance with passed custom keywords and formats added.
 *
 * @param createAjvOptions
 * @param createAjvOptions.keywords - an array of {@link KeywordDefinition} to add via the ajv `addKeyword` API
 * @param createAjvOptions.formats - an array of {@link FormatDefinition} to add via the ajv `addFormat` API
 * @param createAjvOptions.options - options passed to the AJV constructor {@link Options}}
 *
 *
 * @returns {Object} an instance of {@link AJV}.
 */

export function createAjv({
  formats = [],
  keywords = [],
  options = defaultOptions,
}: CreateAjvParameters = defaultArgs) {
  const ajv = new AJV(options);

  keywords.forEach((keyword: KeywordDefinition) => {
    ajv.addKeyword(keyword);
  });

  formats.forEach(({ name, format }: FormatDefinition) => {
    ajv.addFormat(name, format);
  });

  /**
   * wrap the ajv instance with the `ajv-errors` package, which adds the
   * `errorMessage` keyword and allows us to pass custom error messages
   *
   * For more info see: https://ajv.js.org/packages/ajv-errors.html
   */

  ajvErrors(ajv);

  /**
   * wrap the ajv instance with the `ajv-formats` package, which adds several
   * new formats to the core set of JSON Schema supported formats, ex: `date`
   *
   * For the full list and more see: https://ajv.js.org/packages/ajv-formats.html
   */

  addFormats(ajv);

  return ajv;
}
