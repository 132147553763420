import jp from 'jsonpath';

/**
 * We assume that any string that begins with `$.` is a JSON Path
 */

const isJsonPath = (path: string) => path.indexOf('$.') === 0;

export const getDataPointValue = (
  data: { [k: string]: any },
  path?: string,
) => {
  if (typeof data === 'object' && typeof path === 'string') {
    return isJsonPath(path) ? jp.value(data, path) : data[path];
  }

  if (path) {
    // eslint-disable-next-line no-console
    console.warn(
      `DataSource mapping provided a path ${path} but the data is not an object or array.`,
    );
  }

  return data;
};
