import { GDSInputStatus } from '@leagueplatform/genesis-core';
import { useSessionStorage } from 'usehooks-ts';
import { LOCAL_SESSION_LOGIN_VALIDATION_ERROR } from '../../constants/storage-key';

/**
 * Manages login validation state using session storage.
 *
 * This hook provides functions to set, reset, and access login validation information
 * stored in session storage. It uses the `useSessionStorage` hook from `usehooks-ts`
 * to persist the validation state between sessions.
 *
 * The validation state includes:
 * - `inputStatus`: The status of the login input (e.g. 'error').
 * - `statusMessage`: A message associated with the login status (e.g. 'Invalid credentials').
 *
 * @returns {Object} An object containing the following functions and state:
 * - `resetLogicValidation`: A function to reset the login validation state to its default values.
 * - `setLoginValidation`: A function to update the login validation state.
 * - `loginValidation`: The current login validation state.
 */
export const useLoginValidation = () => {
  const initialLoginValue = undefined;
  const [loginValidation, setLoginValidation] = useSessionStorage<
    | {
        inputStatus: GDSInputStatus;
        statusMessage: string;
      }
    | undefined
  >(LOCAL_SESSION_LOGIN_VALIDATION_ERROR, initialLoginValue);

  const resetLogicValidation = () => setLoginValidation(initialLoginValue);

  return { resetLogicValidation, setLoginValidation, loginValidation };
};
