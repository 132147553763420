import { ErrorObject } from 'ajv';

/**
 * Filters an array of ErrorObjects to ensure uniqueness based on their 'instancePath' property.
 * @param arr The array of ErrorObjects to filter.
 * @returns A new array containing only the unique ErrorObjects based on their 'instancePath'.
 */
export const removeDuplicateInstancePathErrors = (
  arr: ErrorObject<string, Record<string, any>, unknown>[],
) => {
  const seenInstancePaths = new Set();
  return arr.filter((obj) => {
    if (seenInstancePaths.has(obj.instancePath)) {
      return false;
    }
    seenInstancePaths.add(obj.instancePath);
    return true;
  });
};
