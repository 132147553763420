import { useMemo, useEffect, useCallback } from 'react';
import { ArrayControlProps, resolveSchema, Actions } from '@jsonforms/core';
import { useJsonForms } from '@jsonforms/react';
import { useAnnotatedSchemaProps } from '../use-annotated-schema-props/use-annotated-schema-props';
import { EntityFormArrayControlProps } from '../../types/controls';
import { useTranslate } from '../use-translate/use-translate';

/**
 * This hook takes the default props passed to array controls via @jsonforms/react
 * withJsonFormsArrayControlProps and enhances them with props from our own custom annotations implementation, which are present in `props.schema`
 *
 * JsonForms ArrayControl prop passes us the item.schema and not the array schema, so we do some transformations below
 *
 * @param props - ArrayControlProps passed to all controls by JsonSchemaDispatch
 * @returns {@link EntityFormArrayControlProps}
 */

export const useEntityFormArrayControl = (
  props: ArrayControlProps,
): EntityFormArrayControlProps => {
  const translate = useTranslate();
  const { schema, rootSchema, path, label: jsonFormsLabel, data } = props;

  const ctx = useJsonForms();
  const { dispatch } = ctx;
  /**
   * When the data property at an array path is missing from the backend GET request of an entity, the JsonForms library (or some other factor) is incorrectly setting it as an empty object {}, instead of the expected empty array [].
   *
   * This useEffect hook ensures that the property at this path is always an array.
   *  If it's missing or not an array, it's forcefully updated to an empty array [].
   *
   * this prevents the error of JSON Forms trying to push an item to an object
   */
  useEffect(() => {
    if (!Array.isArray(data) && dispatch) {
      dispatch(Actions.update(path, () => []));
    }
  }, [data, dispatch, path]);

  /**
   * The schema from ArrayControlProps for array type is returning the item's schema, vs the array level schema and yes this is a hack but it enables us to pass in the correct path to receive the array schema
   *
   * TODO:: Ensure this is working with nested arrays https://everlong.atlassian.net/browse/CACT-694
   */
  const splitPath = path.split('.');

  /**
   * The path can be empty string here in the case where we are rendering an array as a page. In which case,
   * we shouldn't do all the fancy properties joinery.
   */
  const constructedPath =
    path === '' ? '#' : `#/properties/${splitPath.join('/properties/')}`;

  /**
   * resolvedSchema is the array level schema
   */
  const resolvedSchema = resolveSchema(rootSchema, constructedPath, rootSchema);

  const handleUpdateArrayItem = useCallback(
    (itemPath: string, value: any) => {
      const action = Actions.update(itemPath, () => value);
      dispatch?.(action);
    },
    [dispatch],
  );

  /**
   * transform custom annotations into props get translated strings, we passed the resolvedSchema (array schema) to this
   */

  const annotationProps = useAnnotatedSchemaProps(resolvedSchema);
  const { label, arrayAddLabel } = annotationProps;

  /**
   * Array item annotations
   */
  const { label: itemLabel, labelPropertyRef: itemLabelPropertyRef } =
    useAnnotatedSchemaProps(schema);

  const addItemButtonLabel =
    arrayAddLabel || `${translate('ADD')} ${itemLabel}`;

  /**
   * Todo:: A11y audit to see if we need this: https://everlong.atlassian.net/browse/CACT-807
   */
  const visuallyHiddenItemLabel = `${itemLabel} ${translate('LIST_ITEM')}`;

  return useMemo(
    () => ({
      ...props,
      ...annotationProps,
      // Should we potentially handle what it means when these functions are actually undefined?
      moveDown: props?.moveDown!,
      moveUp: props?.moveUp!,
      removeItems: props?.removeItems!,
      // TODO :: label should be required and not backup of jsonFormsLabel https://everlong.atlassian.net/browse/CACT-819
      label: label || jsonFormsLabel,
      itemLabel,
      itemLabelPropertyRef,
      arrayAddLabel: addItemButtonLabel,
      visuallyHiddenItemLabel,
      handleUpdateArrayItem,
    }),
    [
      props,
      annotationProps,
      label,
      jsonFormsLabel,
      itemLabel,
      itemLabelPropertyRef,
      addItemButtonLabel,
      visuallyHiddenItemLabel,
      handleUpdateArrayItem,
    ],
  );
};
