import React, { FC } from 'react';
import { ActionMenu } from '@leagueplatform/web-common-components';
import {
  Button,
  Icon,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { SelectActionMenuProps } from '../../types/components.types';
import { createMenuItems } from './create-menu-items/create-menu-items';

/**
 * SelectActionMenu is built on top of the `ActionMenu` from `@leagueplatform/web-common-components`. It follows renders a dropdown menu with grouped action items, following the [Menu Button](https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/) and [Menu](https://www.w3.org/WAI/ARIA/apg/patterns/menubar/) semantics and keyboard controls.
 * **Note** that the `menu` and `menuitem` roles used for this component are specifically meant to handle a **"grouping of common actions  or functions that the user can invoke"** ([via MDN](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menu_role)).
 * * Think of `ActionMenu` as an options popup menu on a section or element within the interface, this is not a replacement for a native <select> form element.
 *
 * This component provides a user-friendly and consistent way to present a list of selectable options with optional groupings that render a semantic separator nad visual break between grouping.
 *
 * @param {React.ReactNode} props.triggerLabel - The label or element that triggers the menu dropdown.
 * @param {string} props.menuId - A unique identifier for the menu.
 * @param {SelectMenuItemGroup[]} props.menuGroups - An array of menu item groups, each containing an array of `MenuItem` objects.
 *   Each `MenuItem` can have an optional `confirmationModal` property. If this property is set, a confirmation modal will be shown
 *   before the `onSelectItem` callback is triggered. The `confirmationModal` property should be an object with the following properties:
 *     - `title`: The title of the confirmation modal.
 *     - `description`: The description to be displayed in the modal.
 *     - `confirmCta`: The text for the confirm button in the modal.
 * @param {(itemId: string) => void} props.onSelectItem - A callback function that is triggered when a menu item is selected.
 * @param {string} [props.menuLabel] - An optional label for the menu.
 */

export const SelectActionMenu: FC<SelectActionMenuProps> = ({
  triggerLabel,
  menuId,
  menuGroups,
  onSelectItem,
  menuLabel,
}) => {
  const [openItemId, setOpenItemId] = React.useState<string | null>(null);

  const handleModalOpenChange = (itemId: string) => {
    setOpenItemId((prevItemId) => {
      if (prevItemId !== null) {
        return null;
      }
      return itemId;
    });
  };

  const handleMenuItemClick = (itemId: string) => {
    const item = menuGroups
      .flatMap((group) => group.menuItems)
      .find((menuItem) => menuItem.id === itemId);

    if (!item?.confirmationModal) {
      onSelectItem(itemId);
    }
  };

  const menuItems = createMenuItems({
    menuGroups,
    openItemId,
    handleModalOpenChange,
    onSelectItem,
    handleMenuItemClick,
  });

  return (
    <StackLayout
      orientation="horizontal"
      verticalAlignment="center"
      css={{ flexBasis: 'auto' }}
    >
      <UtilityText css={{ typography: '$label' }}>{menuLabel}</UtilityText>
      {menuGroups.length > 0 && (
        <ActionMenu
          menuId={menuId}
          css={{
            marginLeft: '$half',
            '.GDS-action-menu-popup': {
              border: '$borderWidths$thin solid $interactiveBorderDefault',
            },
            '.GDS-action-menu-list-item': {
              padding: '$none',
            },
            ul: {
              margin: '$none',
            },
            'li:first-child': {
              'border-top-left-radius': '$huge',
              'border-top-right-radius': '$huge',
            },
            'li:last-child': {
              'border-bottom-left-radius': '$huge',
              'border-bottom-right-radius': '$huge',
            },
          }}
          menuButton={
            <Button
              priority="tertiary"
              size="medium"
              css={{
                borderRadius: '$inputField',
                '&:hover': {
                  borderColor: '$inputBorderHovered',
                  backgroundColor: '$inputBackgroundHovered',
                  color: '$tertiaryTextDefault',
                },
                '&:active': {
                  borderColor: '$inputBorderPressed',
                  backgroundColor: '$inputBackgroundPressed',
                  color: '$tertiaryTextDefault',
                },
              }}
            >
              <StackItem
                css={{
                  flexDirection: 'row',
                  typography: '$bodyTwo',
                  color: '$onSurfaceTextPrimary',
                }}
              >
                {triggerLabel}
                <Icon
                  icon="interfaceChevronDown"
                  css={{ marginLeft: '$half' }}
                  size="$oneAndQuarter"
                  tint="$interactiveActionSubdued"
                />
              </StackItem>
            </Button>
          }
          menuItems={menuItems}
          onSelectItem={handleMenuItemClick}
        />
      )}
    </StackLayout>
  );
};
