import type {
  AnnotatedJsonSchema,
  EntityDataSourceType,
  EnumOptionsDataSourceMapping,
  GroupedEnumOptionsDataSourceMapping,
} from '@web-config-app/core';
import type { ControlProps } from '@jsonforms/core';
import type { GDSSelectProps } from '@leagueplatform/genesis-core';
import { useDataSource } from '../../state/data-source/use-data-source';
import { getMappedDataSourceEnumOptions } from './get-mapped-data-source-enum-options';
import { getMappedDataSourceNestedEnumOptions } from './get-mapped-data-source-grouped-enum-options';

const mappings: Record<
  EntityDataSourceType,
  (
    data: Array<unknown> | undefined,
    mapping:
      | EnumOptionsDataSourceMapping
      | GroupedEnumOptionsDataSourceMapping
      | undefined,
  ) => { enumOptions: GDSSelectProps['options'] } | undefined
> = {
  enumOptions: getMappedDataSourceEnumOptions,
  groupedEnumOptions: getMappedDataSourceNestedEnumOptions,
};

/**
 * Consumes a schema and returns a value from the data source store if the schema
 * contains an {@link EntityDataSourceAnnotation} (`x-entity-data-source`) annotation .
 *
 * The annotation should contain a `dataSource` string property that
 * corresponds to a key in the data source store.
 *
 * The annotation can also contain an optional `mapping` object that allows transforming
 * the data before it's returned in the form of an object
 */

export const useEntityFormDataSource = ({ schema }: ControlProps) => {
  const { 'x-entity-data-source': xEntityDataSource } =
    schema as AnnotatedJsonSchema;

  const dataSource = useDataSource(xEntityDataSource?.dataSource);

  if (dataSource === undefined) {
    return dataSource;
  }

  const mapper = xEntityDataSource?.type && mappings[xEntityDataSource.type];

  if (!mapper && !!xEntityDataSource?.type) {
    /**
     * Throwing a warning here because the annotation specified a type but it doesn't match any known
     * mapping function so it was probably a typo.
     */
    // eslint-disable-next-line no-console
    console.log(
      `Using DataSource '${xEntityDataSource?.dataSource}' but could not find a mapper corresponding to '${xEntityDataSource?.type}'`,
    );
  }

  return mapper ? mapper(dataSource, xEntityDataSource?.mapping) : undefined;
};
