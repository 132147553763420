import React from 'react';
import {
  Icon,
  StackLayout,
  UtilityText,
  StatusBadge,
} from '@leagueplatform/genesis-core';

export const SaveStatus = ({
  saveStatusMessage,
  isEntitySaved,
}: {
  saveStatusMessage: string;
  isEntitySaved: boolean;
}) =>
  isEntitySaved ? (
    <UtilityText>
      <StackLayout horizontalAlignment="center" orientation="horizontal">
        <Icon
          icon="tinyValidationCheck"
          css={{ paddingRight: '$quarter', color: '$successIcon' }}
        />

        {saveStatusMessage}
      </StackLayout>
    </UtilityText>
  ) : (
    <StatusBadge status="warning" label={saveStatusMessage} />
  );
