import type { AnnotatedJsonSchema } from '@web-config-app/core';

export const reduceSchemaProperties = (
  acc: { [k: string]: AnnotatedJsonSchema },
  [property, propertySchema]: [string, AnnotatedJsonSchema],
) => ({
  ...acc,
  [property]: propertySchema,
});

export const getSchemaFromEntries = (
  schemaEntries: [string, AnnotatedJsonSchema][],
) =>
  schemaEntries.reduce(
    reduceSchemaProperties,
    {},
  ) as AnnotatedJsonSchema['properties'];
