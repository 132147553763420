import React, { useCallback, useState } from 'react';
import {
  HeadingText,
  TextInput,
  Box,
  Button,
  ParagraphText,
  FormField,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';
import { isValidTenantId } from 'utils/is-valid-tenant-id';
import { useLoginValidation } from '../../hooks/use-login-validation/use-login-validation';

type TenantIdFormProps = {
  onValidSubmit: (tenantId: string) => void;
};

/**
 * A form for entering and submitting a workspace tenant ID.
 *
 * This component displays a form with a single input field for the tenant ID.
 * It validates the input and calls the `onValidSubmit` function with the valid tenant ID.
 *
 * The form includes validation for empty input and invalid tenant ID format.
 * It also uses the `useLoginValidation` hook to manage the validation state and display error messages.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onValidSubmit - A function to be called with the valid tenant ID when the form is submitted.
 */
export const TenantIdForm = ({ onValidSubmit }: TenantIdFormProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { loginValidation, setLoginValidation, resetLogicValidation } =
    useLoginValidation();

  const submitTenant = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (inputValue === '') {
        setLoginValidation({
          inputStatus: 'error',
          statusMessage: 'Please enter your workspace league tenant ID.',
        });
      } else if (!isValidTenantId(inputValue)) {
        setLoginValidation({
          inputStatus: 'error',
          statusMessage:
            "We couldn't find your workspace. If you cannot remember your workspace League tenant ID, please reach out to the League team.",
        });
      } else {
        onValidSubmit(inputValue);
      }
    },
    [inputValue, onValidSubmit, setLoginValidation],
  );

  return (
    <StackLayout
      orientation="horizontal"
      verticalAlignment="stretch"
      css={{
        height: '100vh',
      }}
    >
      <Box
        css={{ backgroundColor: '$surfaceBackgroundSecondary', width: '60%' }}
      />
      <StackItem
        verticalAlignment="center"
        horizontalAlignment="center"
        css={{
          width: '100%',
        }}
      >
        <HeadingText
          size="xxl"
          level="1"
          css={{ marginBottom: '$one', textAlign: 'center' }}
        >
          Sign in to your configuration workspace.
        </HeadingText>
        <ParagraphText css={{ paddingBottom: '$two' }}>
          Enter your workspace League Tenant ID!
        </ParagraphText>
        <Box as="form" css={{ width: '400px' }} onSubmit={submitTenant}>
          <FormField
            css={{ width: 'auto' }}
            id="tenant-id-input"
            label="Enter tenant id"
            name="Enter tenant id"
            inputStatus={loginValidation?.inputStatus}
            statusMessage={loginValidation?.statusMessage}
            hideLabel
            required
          >
            <TextInput
              id="tenantIdInput"
              type="text"
              name="tenantId"
              placeholder="your-workspace-id"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                resetLogicValidation();
              }}
              onClear={() => {
                setInputValue('');
                resetLogicValidation();
              }}
              clearable
              clearLabel="clear the current workspace value"
            />
          </FormField>
          <Button
            type="submit"
            width="fillContainer"
            css={{
              marginTop: '$oneAndHalf',
              borderRadius: '$radii$medium $radii$medium',
            }}
          >
            Sign in
          </Button>
        </Box>
      </StackItem>
    </StackLayout>
  );
};
