import { ErrorObject, Format } from 'ajv';

export enum FormatType {
  RichText = 'rich-text',
  JsonLogic = 'json-logic',
}

export interface FormatDefinition {
  name: string;
  format: Format;
}

export interface FormError extends ErrorObject {
  propertyName?: string;
}
