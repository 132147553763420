import { ErrorObject } from 'ajv';
import { removeDuplicateInstancePathErrors } from '../remove-duplicate-instance-path-errors/remove-duplicate-instance-path-errors.util';

/**
 * we are removing these keywords because JSON Forms also omits them from their validation work - this way the root data / root forms is validated the same as the data slice / form slice https://github.com/eclipsesource/jsonforms/blob/master/packages/core/src/util/errors.ts
 */
const defaultFilteredErrorKeywords = [
  'additionalProperties',
  'allOf',
  'anyOf',
  'oneOf',
];

/**
 * Filters an array of AJV error objects based on specified keywords.
 *
 * This function takes an array of `ErrorObject`s and an optional array of keywords.
 *
 * It returns a new array, filtering out errors by the `defaultFilteredErrorKeywords` and the `filteredErrorKeywords` (if provided)
 * it also removes errors that are attached to properties that have the `hidden` annotation and removed duplicate `instancePath` errors so only one error per property remains
 *
 * @param {Object} params - An object containing the parameters:
 * @param {ErrorObject[]} params.errors - The array of AJV error objects to filter.
 * @param {string[]} [params.filteredErrorKeywords] - An optional array of keywords to filter out.
 * @returns {ErrorObject[]} A new array containing the filtered error objects.
 */
export const filterOutErrorsByKeywords = ({
  errors,
  filteredErrorKeywords,
}: {
  errors: ErrorObject<string, Record<string, any>, unknown>[];
  filteredErrorKeywords?: string[];
}) => {
  const keywordsList = new Set([
    ...defaultFilteredErrorKeywords,
    ...(filteredErrorKeywords || []),
  ]);

  const errorsFilteredByKeywords = errors.filter(
    ({ keyword }) => !keywordsList.has(keyword),
  );

  /**
   * we want to remove any odd errors that are attached to fields with the hidden annotation because they are un-editable by the user
   */
  const errorsFilteredByVisibility = errorsFilteredByKeywords.filter(
    (error) => {
      const { parentSchema, params } = error;
      const property = params.missingProperty;

      const isParentHidden =
        parentSchema?.['x-entity-presentation']?.hidden === true;
      const isPropertyHidden =
        parentSchema?.properties?.[property]?.['x-entity-presentation']
          ?.hidden === true;

      return !(isParentHidden || isPropertyHidden);
    },
  );

  /**
   * we want to remove any duplicated error.instancePath errors so we only show one error per property
   */
  const uniqueInstanceErrors = removeDuplicateInstancePathErrors(
    errorsFilteredByVisibility,
  );

  return uniqueInstanceErrors;
};
