import { get, set, unset } from 'lodash-es';

/**
 * Unsets a property from the passed data object at the given path using _.unset. The returned object
 * will have the property completely removed (vs setting it to `undefined`)
 *
 * @example
 *
 * const input = {
 *   nested: {
 *     someProperty: {
 *       toStay: 'hi'
 *       toRemove: 'bye'
 *     },
 *     otherProperty: 'i am here too'
 *   }
 * }
 *
 * const result = unsetObjectProperty(input, 'nested.someProperty', 'toRemove')
 *
 * returns:
 *
 * {
 *   nested: {
 *     someProperty: {
 *       toStay: 'hi'
 *     }
 *   }
 *   otherProperty: 'i am here too'
 * }
 *
 * @param {Object} data - the object to operate on
 * @param {string} dataPath - optional, path to a deeply nested property
 * @param propertyName - the property to remove from the data object
 * @returns {@Link UnsetObjectPropertyResult}
 *
 */

export interface UnsetObjectPropertyResult {
  /**
   * The resulting data object
   */
  data: any;
  /**
   * A flag to help consumers determine if any change was made to the passed data object
   */
  updated: boolean;
}

export const unsetObjectProperty = ({
  data,
  dataPath,
  propertyName,
}: {
  data: any;
  dataPath?: string;
  propertyName: string;
}) => {
  const newData = structuredClone(data);
  const isNestedPath = !!dataPath && dataPath !== '';
  const dataSlice = isNestedPath ? get(newData, dataPath) : newData;

  if (dataSlice?.[propertyName]) {
    unset(dataSlice, propertyName);
    if (!isNestedPath) {
      return {
        data: dataSlice,
        updated: true,
      };
    }

    set(newData, dataPath, dataSlice);
    return {
      data: newData,
      updated: true,
    };
  }

  return { data, updated: false };
};
