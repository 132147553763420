import type { AuthoringEnvironmentId } from '@web-config-app/core';
import { useAuthoringEnvironments } from '../use-authoring-environments/use-authoring-environments';

export const useAuthoringEnvironmentInfo = (
  AuthoringEnvironmentId?: AuthoringEnvironmentId,
) => {
  const {
    environmentMap,
    baseAuthoringEnvironment,
    multiEnvironmentAuthoringEnabled,
  } = useAuthoringEnvironments();

  if (!AuthoringEnvironmentId || !multiEnvironmentAuthoringEnabled) {
    return baseAuthoringEnvironment;
  }

  const environment = environmentMap?.get(AuthoringEnvironmentId);

  if (!environment) {
    throw new Error(`Unknown environment: ${AuthoringEnvironmentId}`);
  }

  return environment;
};
