import { AnnotatedJsonSchema } from '@web-config-app/core';

export const mockSimpleSchemaAttributes = {
  title: 'Simple Schema Attributes',
  type: 'object',
  'x-entity-label': {
    key: 'SIMPLE_SCHEMA_ENTITY_NAME',
  },
  'x-entity-inject-reference': {
    relationship: 'pygmyOwl',
    'x-entity-label': { key: 'PYGMY_OWL_LABEL' },
    'x-entity-presentation': {
      hint: {
        key: 'PYGMY_OWL_HINT',
      },
    },
  },
  properties: {
    entityMetadata: {
      'x-entity-presentation': {
        hidden: true,
      },
      type: 'object',
      properties: {
        status: {
          type: 'string',
          enum: ['draft', 'published', 'archived'],
        },
        lastUpdatedDate: {
          type: 'string',
          format: 'date-time',
        },
        lastPublishedDate: {
          type: 'string',
          format: 'date-time',
        },
      },
    },
    tags: {
      title: 'Example primitive array on root node',
      type: 'array',
      items: {
        type: 'string',
        title: 'Tag',
      },
    },
    metaData: {
      'x-entity-label': {
        key: 'METADATA',
      },
      type: 'object',
      properties: {
        internalName: {
          title: 'Name',
          'x-entity-label': {
            key: 'NAME',
          },
          type: 'string',
        },
      },
      required: ['internalName'],
    },
    primitiveText: {
      type: 'string',
      title: 'Text',
      'x-entity-label': {
        key: 'SIMPLE_SCHEMA_PRIMITIVE_TEXT_LABEL',
      },
    },
    complex: {
      type: 'object',
      title: 'Complex Object',
      'x-entity-label': {
        key: 'SIMPLE_SCHEMA_COMPLEX_OBJECT_LABEL',
      },
      properties: {
        primitiveEnum: {
          type: 'string',
          title: 'Enum',
          'x-entity-label': {
            key: 'SIMPLE_SCHEMA_PRIMITIVE_ENUM_LABEL',
          },
          enum: ['hi', 'bye'],
        },
        primitiveObject: {
          type: 'object',
          title: 'Nested primitive object',
          'x-entity-label': {
            key: 'SIMPLE_SCHEMA_COMPLEX_NESTED_OBJECT_LABEL',
          },
          properties: {
            name: {
              type: 'string',
              title: 'Name',
              'x-entity-label': {
                key: 'SIMPLE_SCHEMA_COMPLEX_NESTED_OBJECT_NAME_LABEL',
              },
            },
            age: {
              type: 'integer',
              title: 'Age',
              'x-entity-label': {
                key: 'SIMPLE_SCHEMA_COMPLEX_NESTED_OBJECT_AGE_LABEL',
              },
            },
          },
        },
      },
    },
  },
  required: ['primitiveText', 'complex', 'status', 'metaData'],
};

export const mockRelationshipDataItemSubSchema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    type: {
      type: 'string',
      enum: ['config-pygmy-owl'],
    },
  },
  required: ['id', 'type'],
};

export const mockRelationshipDataItemWithConstSubSchema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    type: {
      type: 'string',
      const: 'config-pygmy-owl',
    },
  },
  required: ['id', 'type'],
};

export const mockOneToOneRelationshipsSubSchema = {
  type: 'object',
  properties: {
    pygmyOwl: {
      type: 'object',
      properties: {
        data: mockRelationshipDataItemSubSchema,
      },
    },
  },
};

export const mockOneToOneRelationshipsSubSchemaWithConst = {
  type: 'object',
  properties: {
    pygmyOwl: {
      type: 'object',
      properties: {
        data: mockRelationshipDataItemWithConstSubSchema,
      },
    },
  },
};

export const mockOneToOneRelationshipTransformedSchema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    type: {
      type: 'string',
      enum: ['config-pygmy-owl'],
    },
  },
  required: ['id', 'type'],
  'x-entity-label': {
    key: 'PYGMY_OWL_LABEL',
  },
  'x-entity-presentation': {
    hint: {
      key: 'PYGMY_OWL_HINT',
    },
  },
  'x-entity-reference': {
    relationship: 'pygmyOwl',
    relationshipDataIdKey: 'id',
    includesDisplayNameKey: 'internalName',
  },
};

export const mockOneToManyRelationshipTransformedSchema = {
  ...mockOneToOneRelationshipTransformedSchema,
  'x-entity-reference': {
    relationship: 'campaigns',
  },
};

export const mockOneToManyRelationshipsSubSchema = {
  type: 'object',
  properties: {
    pygmyOwls: {
      type: 'object',
      properties: {
        data: {
          type: 'array',
          items: mockRelationshipDataItemSubSchema,
        },
      },
    },
  },
};

export const mockOneToManyRelationshipsSubSchemaWithConst = {
  type: 'object',
  properties: {
    pygmyOwls: {
      type: 'object',
      properties: {
        data: {
          type: 'array',
          items: mockRelationshipDataItemWithConstSubSchema,
        },
      },
    },
  },
};

export const mockSimpleSchemaAttributesWithCustomRelationshipProperties = {
  ...mockSimpleSchemaAttributes,
  'x-entity-inject-reference': {
    relationship:
      mockSimpleSchemaAttributes['x-entity-inject-reference'].relationship,
    relationshipDataIdKey: 'custom-data-key',
    includesDisplayNameKey: 'custom-name-key',
    'x-entity-label':
      mockSimpleSchemaAttributes['x-entity-inject-reference']['x-entity-label'],
    'x-entity-presentation': {
      ...mockSimpleSchemaAttributes['x-entity-inject-reference'][
        'x-entity-presentation'
      ],
      banner: {
        title: {
          key: 'MOCK_ENTITY_REFERENCE_BANNER_TITLE',
        },
        status: 'info',
      },
    },
  },
} as const;

export const mockSimpleSchema = {
  type: 'object',
  'x-entity-metadata': {
    entityNamePath: '$.attributes.metaData.internalName',
  },
  properties: {
    id: {
      'x-entity-presentation': {
        hidden: true,
      },
      type: 'string',
    },
    type: {
      'x-entity-presentation': {
        hidden: true,
      },
      type: 'string',
      enum: ['config-cactus'],
    },
    attributes: mockSimpleSchemaAttributes,
    relationships: mockOneToOneRelationshipsSubSchema,
  },
  required: ['type', 'id'],
};

export const mockSimpleSchemaWithIncludeIfConditionalField = {
  type: 'object',
  'x-entity-metadata': {
    entityNamePath: '$.attributes.metaData.internalName',
  },
  properties: {
    id: {
      'x-entity-presentation': {
        hidden: true,
      },
      type: 'string',
    },
    type: {
      'x-entity-presentation': {
        hidden: true,
      },
      type: 'string',
      enum: ['config-cactus'],
    },
    attributes: {
      type: 'object',
      properties: {
        entityMetadata: mockSimpleSchemaAttributes.properties.metaData,
        metaData: mockSimpleSchemaAttributes.properties.metaData,
        conditionalData: {
          type: 'object',
          'x-entity-conditional': {
            conditions: [
              {
                type: 'includeIf',
                rules: {
                  '==': [{ var: 'entityMetadata.status' }, 'draft'],
                },
              },
            ],
          },
          properties: {
            conditionalFieldText: {
              type: 'string',
            },
          },
        },
      },
    },
  },
  required: ['type', 'id'],
} as AnnotatedJsonSchema;

export const mockSimpleSchemaWithOneToManyRelationship = {
  type: 'object',
  properties: {
    ...mockSimpleSchema.properties,
    relationships: mockOneToManyRelationshipsSubSchema,
  },
};

export const primitiveArraySubSchema = {
  title: 'Things',
  type: 'array',
  'x-entity-label': {
    key: 'SIMPLE_SCHEMA_ARRAY_LABEL',
    propertyRef: 'examplePropertyForItemName',
    arrayItemNameKey: 'SIMPLE_SCHEMA_ARRAY_ITEM_LABEL',
  },
  items: {
    title: 'Stuff Array Item',
    type: 'object',
    properties: {
      examplePropertyForItemName: {
        type: 'string',
        title: 'Name',
      },
    },
  },
};

export const mockSimpleSchemaWithPrimitiveArrayAttributes = {
  ...mockSimpleSchemaAttributes,
  properties: {
    ...mockSimpleSchemaAttributes.properties,
    complexWithArray: {
      title: 'Object with array property',
      type: 'object',
      'x-entity-label': {},
      properties: {
        thing: {
          'x-entity-label': {
            key: 'SIMPLE_SCHEMA_THING_LABEL',
          },
          type: 'object',
          properties: {
            startDate: {
              'x-entity-label': {
                key: 'SIMPLE_SCHEMA_START_DATE',
              },
              type: 'string',
              format: 'date',
            },
            endDate: {
              'x-entity-label': {
                key: 'SIMPLE_SCHEMA_END_DATE',
              },
              type: 'string',
              format: 'date',
            },
          },
        },
        stuff: primitiveArraySubSchema,
      },
    },
  },
};

export const mockSimpleSchemaWithPrimitiveArray = {
  ...mockSimpleSchema,
  properties: {
    ...mockSimpleSchema.properties,
    attributes: mockSimpleSchemaWithPrimitiveArrayAttributes,
  },
};

export const complexArraySubSchema = {
  title: 'Things',
  type: 'array',
  'x-entity-label': {
    key: 'SIMPLE_SCHEMA_ARRAY_LABEL',
  },
  items: {
    'x-entity-label': {
      propertyRef: 'examplePropertyForItemName',
      key: 'SIMPLE_SCHEMA_ARRAY_ITEM_LABEL',
    },
    title: 'Stuff Array Item',
    type: 'object',
    properties: {
      examplePropertyForItemName: {
        type: 'string',
      },
      exampleObjectProperty: {
        type: 'object',
        properties: {
          one: {
            type: 'string',
          },
          two: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const mockSimpleSchemaWithComplexArrayAttributes = {
  ...mockSimpleSchemaAttributes,
  properties: {
    ...mockSimpleSchemaAttributes.properties,
    complexWithArray: {
      title: 'Object with array property',
      type: 'object',
      'x-entity-label': {},
      properties: {
        thing: {
          'x-entity-label': {
            key: 'SIMPLE_SCHEMA_THING_LABEL',
          },
          type: 'object',
          properties: {
            startDate: {
              'x-entity-label': {
                key: 'SIMPLE_SCHEMA_START_DATE',
              },
              type: 'string',
              format: 'date',
            },
            endDate: {
              'x-entity-label': {
                key: 'SIMPLE_SCHEMA_END_DATE',
              },
              type: 'string',
              format: 'date',
            },
          },
        },
        stuff: complexArraySubSchema,
      },
    },
  },
};

export const mockSimpleSchemaWithComplexArray = {
  ...mockSimpleSchema,
  properties: {
    ...mockSimpleSchema.properties,
    attributes: mockSimpleSchemaWithComplexArrayAttributes,
  },
};

export const mockSchemaWithOneOfArrayItemPage = {
  ...mockSimpleSchema,
  properties: {
    ...mockSimpleSchema.properties,
    attributes: {
      type: 'object',
      title: 'Schema with Any Of Array',
      'x-entity-label': {
        key: 'THING',
      },
      properties: {
        entityMetadata:
          mockSimpleSchema.properties.attributes.properties.entityMetadata,
        metaData: mockSimpleSchema.properties.attributes.properties.metaData,
        contentData: {
          'x-entity-label': {
            key: 'CONTENT_DATA',
          },
          type: 'object',
          title: 'Content Data Tree Node',
          properties: {
            things: {
              type: 'array',
              'x-entity-label': {
                key: 'THINGS',
              },
              items: {
                discriminator: {
                  propertyName: 'thingType',
                },
                oneOf: [
                  {
                    'x-entity-label': {
                      key: 'THING_TYPE_ONE',
                    },
                    type: 'object',
                    title: 'Thing type one',
                    properties: {
                      thingType: {
                        'x-entity-label': {
                          key: 'THING_TYPE_ONE',
                        },
                        type: 'string',
                        enum: ['thingTypeOne'],
                      },
                      thingContent: {
                        type: 'object',
                        'x-entity-label': {
                          key: 'THING_CONTENT',
                        },
                        properties: {
                          thingName: {
                            'x-entity-label': {
                              key: 'THING_NAME',
                            },
                            type: 'string',
                          },
                          thingSize: {
                            'x-entity-label': {
                              key: 'THING_SIZE',
                            },
                            type: 'integer',
                          },
                        },
                      },
                    },
                    required: ['thingType'],
                  },
                ],
              },
            },
          },
        },
      },
    },
  },
};

export const mockOneOfObjectSchema = {
  type: 'object',
  'x-entity-label': {
    key: 'THINGS',
  },
  discriminator: {
    propertyName: 'thingType',
  },
  oneOf: [
    {
      'x-entity-label': {
        key: 'THING_TYPE_ONE',
      },
      type: 'object',
      title: 'Thing type one',
      properties: {
        thingType: {
          'x-entity-label': {
            key: 'THING_TYPE_ONE',
          },
          type: 'string',
          enum: ['thingTypeOne'],
        },
        thingContent: {
          type: 'object',
          'x-entity-label': {
            key: 'THING_CONTENT',
          },
          properties: {
            thingName: {
              'x-entity-label': {
                key: 'THING_NAME',
              },
              type: 'string',
            },
            thingSize: {
              'x-entity-label': {
                key: 'THING_SIZE',
              },
              type: 'integer',
            },
          },
        },
      },
    },
  ],
};

export const mockSchemaWithOneOfObject = {
  ...mockSimpleSchema,
  properties: {
    ...mockSimpleSchema.properties,
    attributes: {
      type: 'object',
      title: 'Schema with Any Of Array',
      'x-entity-label': {
        key: 'THING',
      },
      properties: {
        entityMetadata:
          mockSimpleSchema.properties.attributes.properties.entityMetadata,
        metaData: mockSimpleSchema.properties.attributes.properties.metaData,
        contentData: {
          'x-entity-label': {
            key: 'CONTENT_DATA',
          },
          type: 'object',
          title: 'Content Data Tree Node',
          properties: {
            things: mockOneOfObjectSchema,
          },
        },
      },
    },
  },
};

export const mockActivitiesCreateSchema = {
  title: 'ConfigActivityRequest',
  'x-stoplight': {
    id: 'kewelu9eimd48',
  },
  type: 'object',
  description: "Model for creating an activity and it's related information.",
  properties: {
    type: {
      type: 'string',
      'x-stoplight': {
        id: 'xfovhvu9ttdxr',
      },
      enum: ['config-activity'],
    },
    attributes: {
      title: 'ConfigActivityAttributes',
      'x-stoplight': {
        id: 'qvb2v4663zrrv',
      },
      type: 'object',
      'x-entity-label': {
        key: 'ACTIVITY_JOURNEY_TOOLBOX',
      },
      description: 'Model with toolbox activity attribute information.',
      'x-tags': ['Config-Activity'],
      properties: {
        entityMetadata: {
          type: 'object',
          'x-entity-presentation': {
            hidden: true,
          },
          'x-stoplight': {
            id: 'rpzjhmjaofyyu',
          },
          required: ['status'],
          properties: {
            status: {
              type: 'string',
              'x-stoplight': {
                id: 'hq5783yg20ail',
              },
              description: 'status of toolbox activity in config tools.',
              enum: ['draft', 'published', 'archived'],
            },
            lastPublishedDate: {
              type: 'string',
              'x-stoplight': {
                id: 'kfhkepfj3lv0i',
              },
              description: 'Date and time activity was last published.',
              format: 'date-time',
            },
            lastUpdatedDate: {
              type: 'string',
              'x-stoplight': {
                id: '16b4y43cxpr6a',
              },
              format: 'date-time',
              description: 'date and time activity was last update.',
            },
          },
        },
        metaData: {
          type: 'object',
          'x-entity-label': {
            key: 'ACTIVITY_META_DATA',
          },
          'x-stoplight': {
            id: 'f95v8yed5b89c',
          },
          required: ['internalName', 'type'],
          properties: {
            internalName: {
              type: 'string',
              'x-entity-label': {
                key: 'INTERNAL_NAME',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_INTERNAL_NAME_HINT',
                },
              },
              'x-stoplight': {
                id: 'i1kk90n7x24bo',
              },
              description: 'Searchable within content auth, internal name.',
            },
            type: {
              type: 'string',
              'x-entity-label': {
                key: 'TYPE',
              },
              'x-entity-presentation': {
                enumLabels: {
                  enumControlPlaceholder: 'ACTIVITY_TYPE_SELECT_PLACEHOLDER',
                  'click-through': 'ACTIVITY_CLICK_THROUGH',
                  'no-touch': 'ACTIVITY_NO_TOUCH',
                },
              },
              'x-stoplight': {
                id: 'sbkn031ef86x1',
              },
              enum: ['click-through', 'no-touch'],
              description: 'Type of activity.',
            },
          },
        },
        cardData: {
          type: 'object',
          'x-entity-label': {
            key: 'ACTIVITY_CARD_DATA',
          },
          'x-stoplight': {
            id: 'tnncfdst31mcy',
          },
          required: ['priority', 'detailLevel', 'activityImageId'],
          properties: {
            priority: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_PRIORITY_WEIGHTING',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_PRIORITY_WEIGHTING_HINT',
                },
                enumLabels: {
                  enumControlPlaceholder:
                    'ACTIVITY_PRIORITY_WEIGHTING_SELECT_PLACEHOLDER',
                  low: 'LOW',
                  medium: 'MEDIUM',
                  high: 'HIGH',
                },
              },
              'x-stoplight': {
                id: 'p0uzfjd8rlk6y',
              },
              enum: ['low', 'medium', 'high'],
              description: 'Activity priority.',
            },
            detailLevel: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_DISPLAY_WEIGHTING',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_DISPLAY_WEIGHTING_HINT',
                },
                enumLabels: {
                  enumControlPlaceholder:
                    'ACTIVITY_DISPLAY_WEIGHTING_SELECT_PLACEHOLDER',
                  simple: 'SIMPLE',
                  standard: 'STANDARD',
                  detailed: 'DETAILED',
                },
              },
              'x-stoplight': {
                id: 'gdmb77s8uu7yc',
              },
              enum: ['simple', 'standard', 'detailed'],
              description:
                'Details of an activity, where simple has headline only, standard has headline and image and detailed has headline, image and description.',
            },
            activityImageId: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_IMAGE_ID',
              },
              'x-stoplight': {
                id: 'zy3gpk4hd3emg',
              },
              'x-entity-asset': {
                type: 'image',
              },
            },
          },
        },
        contentData: {
          type: 'object',
          'x-entity-label': {
            key: 'ACTIVITY_CONTENT_DATA',
          },
          'x-stoplight': {
            id: 'rfedujjz6bi28',
          },
          required: ['title'],
          properties: {
            title: {
              type: 'string',
              'x-entity-label': {
                key: 'TITLE',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_TITLE_HINT',
                },
              },
              'x-stoplight': {
                id: '5jkzhk0ddm2qv',
              },
              description: 'This is the title that the user sees.',
            },
            shortDescription: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_SHORT_DESCRIPTION',
              },
              'x-stoplight': {
                id: '7tqeadv1r5kqm',
              },
              description:
                'A short summary of campaign. Ensure characters do not exceed 150 character limit.',
            },
            page: {
              type: 'array',
              'x-entity-label': {
                key: 'ACTIVITY_PAGES',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_PAGES_HINT',
                },
              },
              'x-stoplight': {
                id: 'rik7zv63pmc2x',
              },
              items: {
                title: 'ConfigActivityPage',
                'x-stoplight': {
                  id: 'xour62uilem0c',
                },
                type: 'object',
                'x-entity-label': {
                  key: 'ACTIVITY_PAGE',
                  propertyRef: '$.internalPageName',
                },
                'x-entity-presentation': {
                  hint: {
                    key: 'ACTIVITY_PAGE_HINT',
                  },
                },
                description: 'ActivityPage component.',
                'x-tags': ['Config-Activity'],
                properties: {
                  internalPageName: {
                    type: 'string',
                    'x-entity-label': {
                      key: 'ACTIVITY_INTERNAL_PAGE_NAME',
                    },
                    'x-entity-presentation': {
                      hint: {
                        key: 'ACTIVITY_INTERNAL_PAGE_NAME_HINT',
                      },
                    },
                    'x-stoplight': {
                      id: 'tpe0os2s76x8v',
                    },
                    description: 'Page name.',
                  },
                  action: {
                    type: 'object',
                    'x-entity-label': {
                      key: 'ACTIVITY_CALL_TO_ACTION',
                    },
                    'x-entity-presentation': {
                      hint: {
                        key: 'ACTIVITY_CALL_TO_ACTION_HINT',
                      },
                    },
                    'x-stoplight': {
                      id: 'rk5ko9p7orj88',
                    },
                    properties: {
                      url: {
                        type: 'string',
                        'x-entity-label': {
                          key: 'ACTIVITY_URL',
                        },
                        'x-stoplight': {
                          id: 'cp09fb5kw5qtc',
                        },
                        format: 'uri-reference',
                      },
                      title: {
                        type: 'string',
                        'x-entity-label': {
                          key: 'ACTIVITY_TEXT',
                        },
                        'x-stoplight': {
                          id: 'dqc2rzkhduiq8',
                        },
                      },
                    },
                  },
                  components: {
                    type: 'array',
                    'x-entity-label': {
                      key: 'ACTIVITY_COMPONENTS',
                    },
                    'x-entity-presentation': {
                      hint: {
                        key: 'ACTIVITY_COMPONENTS_HINT',
                      },
                    },

                    'x-stoplight': {
                      id: 'yst9kl10niqmh',
                    },
                    items: {
                      'x-entity-label': {
                        key: 'ACTIVITY_COMPONENTS',
                      },
                      'x-stoplight': {
                        id: 'c980o31q917c7',
                      },
                      discriminator: {
                        propertyName: 'componentType',
                      },
                      oneOf: [
                        {
                          title: 'RichTextComponentAttribute',
                          'x-stoplight': {
                            id: 'z0pwja1s50qb5',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_RICH_TEXT_COMPONENT',
                          },
                          description:
                            "Contains the Rich Text HTML for the component and it's type.",
                          'x-examples': {
                            Example: {
                              richText:
                                '<p>Our bodies need sleep to rest and repair. When we sleep, <b>our body is under less demand.</b> This allows our heart rate to lower, <i>our breathing to slow, and our muscles to relax.</i> Rest is not just for our bodies, though - it’s also for our minds.</p>',
                              componentType: 'rich-text',
                            },
                          },
                          properties: {
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_RICH_TEXT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'rich-text': 'ACTIVITY_RICH_TEXT',
                                },
                              },
                              enum: ['rich-text'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_RICH_TEXT_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                richText: {
                                  type: 'string',
                                  format: 'rich-text',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_RICH_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_RICH_TEXT_HINT',
                                    },
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_LINK_COMPONENT',
                          },
                          'x-entity-presentation': {
                            hint: {
                              key: 'ACTIVITY_LINK_COMPONENT_HINT',
                            },
                          },
                          'x-examples': {
                            LinkComponentAttributeExample: {
                              componentType: 'link',
                              componentAttributes: {
                                iconUrl:
                                  'https://images.ctfassets.net/ii2mrg7wcwxq/7544A2boC1xxHxcYKa4ylr/0abd3b059fd9352be138dab50fbaee69/league_questionnaire.png?w=192',
                                url: 'https://app.staging.league.dev/app/member/health-journey/12345',
                                title: 'A guide to hit those marks',
                              },
                            },
                          },
                          properties: {
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_LINK',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  link: 'ACTIVITY_LINK',
                                },
                              },
                              enum: ['link'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_LINK_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                iconUrl: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_ICON_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_LINK_ICON_URL_HINT',
                                    },
                                  },
                                },
                                url: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_LINK_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_LINK_URL_HINT',
                                    },
                                  },
                                },
                                title: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_LINK_TITLE',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_LINK_TITLE_HINT',
                                    },
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'HeaderComponentAttributes',
                          'x-stoplight': {
                            id: '7qrltfee8wb81',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_HEADER_COMPONENT',
                          },
                          'x-examples': {
                            HeaderComponentAttributesExample: {
                              componentType: 'header',
                              componentAttributes: {
                                imageUrl: 'http://link.com/image.jpg',
                                progressPercentage: 20,
                                campaignName: 'Fueling for Performance',
                                alt: 'alt',
                              },
                            },
                          },
                          description:
                            "The HeaderComponent is meant to be used as the first component of the first page of a Toolbox Activity, and it'll display an image on the top of the first page.",
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_HEADER',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  header: 'ACTIVITY_HEADER',
                                },
                              },
                              enum: ['header'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_HEADER_COMPONENT_ATTRIBUTES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_HEADER_COMPONENT_ATTRIBUTES_HINT',
                                },
                              },
                              properties: {
                                imageUrl: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_HEADER_IMAGE_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_HEADER_IMAGE_URL_HINT',
                                    },
                                  },
                                },
                                progressPercentage: {
                                  type: 'integer',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_HEADER_PROGRESS_PERCENTAGE',
                                  },
                                  'x-entity-presentation': {
                                    hidden: true,
                                    banner: {
                                      title: {
                                        key: 'ACTIVITY_HEADER_PROGRESS_PERCENTAGE_BANNER_TITLE',
                                      },
                                      description: {
                                        key: 'ACTIVITY_HEADER_PROGRESS_PERCENTAGE_BANNER_DESCRIPTION',
                                      },
                                      status: 'info',
                                    },
                                  },
                                  maximum: 100,
                                  minimum: 0,
                                },
                                campaignName: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_HEADER_CAMPAIGN_NAME',
                                  },
                                },
                                alt: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'BlockQuoteComponentAttributes',
                          'x-stoplight': {
                            id: '8q82q2wksik9b',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_BLOCK_QUOTE_COMPONENT',
                          },
                          'x-examples': {
                            BlockQuoteComponentAttributesExample: {
                              componentType: 'block-quote',
                              componentAttributes: {
                                text: 'A simple quote',
                              },
                            },
                          },
                          description:
                            'The BlockQuote component is meant to represent the BlockQuote HTML tag, which used to be part of the RichText component. Instead of using the BlockQuote tag inside the RichText component, a BlockQuoteComponent should be added right after the RichTextComponent.',
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_BLOCK_QUOTE',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'block-quote': 'ACTIVITY_BLOCK_QUOTE',
                                },
                              },
                              enum: ['block-quote'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_BLOCK_QUOTE_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                text: {
                                  type: 'string',
                                  format: 'rich-text',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_BLOCK_QUOTE_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BLOCK_QUOTE_TEXT_HINT',
                                    },
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'ImageComponentAttribute',
                          'x-stoplight': {
                            id: 'ogqx9lertugld',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_IMAGE_COMPONENT',
                          },
                          description:
                            "Contains the URL pointing to the image asset for the component and it's type.",
                          'x-examples': {
                            Example: {
                              componentAttributes: {
                                imageUrl:
                                  'https://images.ctfassets.net/ii2mrg7wcwxq/3s2nV35BTxv1ErP7B4ISVE/2f5e8abb0770fef2b58a06a1455fddfa/healthy-weight-couple-cooking-at-home.jpg',
                                alt: 'alt',
                                caption: 'image 1',
                              },
                              componentType: 'image',
                            },
                          },
                          properties: {
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_IMAGE',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  image: 'ACTIVITY_IMAGE',
                                },
                              },
                              enum: ['image'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_IMAGE_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                imageUrl: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_IMAGE_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_IMAGE_URL_HINT',
                                    },
                                  },
                                },
                                alt: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_IMAGE_ALT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_IMAGE_ALT_HINT',
                                    },
                                  },
                                  description: 'Alternate text',
                                },
                                caption: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_IMAGE_CAPTION',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_IMAGE_CAPTION_HINT',
                                    },
                                  },
                                  description: 'Describes the image',
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'BannerComponentAttributes',
                          'x-stoplight': {
                            id: 'u1ayg8in418bb',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_BANNER_COMPONENT',
                          },
                          'x-examples': {},
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_BANNER',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  banner: 'ACTIVITY_BANNER',
                                },
                              },
                              enum: ['banner'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_BANNER_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                title: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_BANNER_TITLE',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BANNER_TITLE_HINT',
                                    },
                                  },
                                },
                                iconUrl: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_ICON_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BANNER_ICON_URL_HINT',
                                    },
                                  },
                                },
                                type: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_BANNER_TYPE',
                                  },
                                  'x-entity-presentation': {
                                    banner: {
                                      title: {
                                        key: 'ACTIVITY_BANNER_TYPE_PRESENTATION_TITLE',
                                      },
                                      description: {
                                        key: 'ACTIVITY_BANNER_TYPE_PRESENTATION_DESCRIPTION',
                                      },
                                      status: 'info',
                                    },
                                    enumLabels: {
                                      enumControlPlaceholder:
                                        'ACTIVITY_BANNER_TYPE_PLACEHOLDER',
                                      primary: 'PRIMARY',
                                      secondary: 'SECONDARY',
                                      subtle: 'SUBTLE',
                                    },
                                  },
                                  enum: ['primary', 'secondary', 'subtle'],
                                },
                                richText: {
                                  type: 'string',
                                  format: 'rich-text',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_BANNER_RICH_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BANNER_RICH_TEXT_HINT',
                                    },
                                  },
                                },
                              },
                              required: ['type'],
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'FullPageHighlightComponentAttributes',
                          'x-stoplight': {
                            id: 'wu6zo70ghbdiv',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_COMPONENT',
                          },
                          'x-examples': {
                            FullPageHighlightComponentAttributesExample: {
                              componentType: 'full-page-highlight',
                              componentAttributes: {
                                title: 'Get healthy!',
                                subtitle: 'What can you achieve next?',
                                highlightImage:
                                  'https://images.ctfassets.net/ii2mrg7wcwxq/3s2nV35BTxv1ErP7B4ISVE/2f5e8abb0770fef2b58a06a1455fddfa/healthy-weight-couple-cooking-at-home.jpg',
                                highlightText: 'A guide to hit those marks',
                                backgroundImage:
                                  'https://images.ctfassets.net/ii2mrg7wcwxq/3s2nV35BTxv1ErP7B4ISVE/2f5e8abb0770fef2b58a06a1455fddfa/healthy-weight-couple-cooking-at-home.jpg',
                                backgroundColor: '#333000',
                              },
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'full-page-highlight':
                                    'ACTIVITY_FULL_PAGE_HIGHLIGHT',
                                },
                              },
                              enum: ['full-page-highlight'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                title: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_TITLE',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_TITLE_HINT',
                                    },
                                  },
                                },
                                subtitle: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_SUBTITLE',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_SUBTITLE_HINT',
                                    },
                                  },
                                },
                                highlightImage: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_IMAGE',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_IMAGE_HINT',
                                    },
                                  },
                                },
                                highlightText: {
                                  type: 'string',
                                  format: 'rich-text',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_HIGHLIGHT_TEXT_HINT',
                                    },
                                  },
                                },
                                backgroundImage: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_BACKGROUND_IMAGE',
                                  },
                                },
                                backgroundColor: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_BACKGROUND_COLOR',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_FULL_PAGE_HIGHLIGHT_BACKGROUND_COLOR_HINT',
                                    },
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'ListComponentAttributes',
                          'x-stoplight': {
                            id: 'gum8o3k8frod1',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_LIST_COMPONENT',
                          },
                          'x-examples': {
                            ' ListComponentAttributesExample': {
                              componentType: 'list',
                              componentAttributes: {
                                list: [
                                  'List item 1',
                                  'List item 2',
                                  'List item 3',
                                ],
                              },
                            },
                          },
                          description:
                            'The List component is meant to represent the UL/LI HTML tags, which used to be part of the RichText component. Instead of using the UL/LI tags inside the richtext component, a ListComponent should be added right after the richtextComponent in order to display a List.',
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'LIST',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  list: 'LIST',
                                },
                              },
                              enum: ['list'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_LIST_COMPONENT_ATTRIBUTES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_LIST_COMPONENT_ATTRIBUTES_HINT',
                                },
                              },
                              properties: {
                                list: {
                                  type: 'array',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_LIST_ATTRIBUTE',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_LIST_ATTRIBUTE_HINT',
                                    },
                                    arrayAddLabel:
                                      'ACTIVITY_LIST_ARRAY_ADD_LABEL',
                                  },
                                  items: {
                                    type: 'string',
                                    format: 'rich-text',
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'BadgeComponentAttributes',
                          'x-stoplight': {
                            id: 'e7ha4csmw03ar',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_BADGE_COMPONENT',
                          },
                          'x-examples': {
                            BadgeComponentAttributes: {
                              componentType: 'badge',
                              componentAttributes: {
                                style: 'pill',
                                text: 'BASICS',
                                altText: 'A badge saying basics in caps lock',
                                iconUrl: '',
                              },
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'BADGE',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  badge: 'BADGE',
                                },
                              },
                              enum: ['badge'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_BADGE_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                style: {
                                  type: 'string',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                text: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_BADGE_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BADGE_TEXT_HINT',
                                    },
                                  },
                                },
                                altText: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BADGE_ALT_TEXT_HINT',
                                    },
                                  },
                                },
                                iconUrl: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_ICON_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BADGE_ICON_URL_HINT',
                                    },
                                  },
                                },
                                styleModifiers: {
                                  title: 'StyleModifiers',
                                  'x-stoplight': {
                                    id: 'wv51qi9cgkqt6',
                                  },
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_BADGE_STYLE_MODIFIERS',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_BADGE_STYLE_MODIFIERS_HINT',
                                    },
                                  },
                                  'x-examples': {},
                                  properties: {
                                    backgroundColor: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'BACKGROUND_COLOR',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_HINT',
                                        },
                                        enumLabels: {
                                          enumControlPlaceholder:
                                            'ACTIVITY_BADGE_STYLE_MODIFIERS_PLACEHOLDER',
                                          'decorative-brand-primary-pastel':
                                            'ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_OPTION_1',
                                          'decorative-brand-secondary-pastel':
                                            'ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_OPTION_2',
                                          'decorative-brand-tertiary-pastel':
                                            'ACTIVITY_BADGE_STYLE_MODIFIERS_BACKGROUND_COLOR_OPTION_3',
                                        },
                                      },
                                      enum: [
                                        'decorative-brand-primary-pastel',
                                        'decorative-brand-secondary-pastel',
                                        'decorative-brand-tertiary-pastel',
                                      ],
                                    },
                                    horizontalAlignment: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'HORIZONTAL_ALIGNMENT',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_BADGE_STYLE_MODIFIERS_HORIZONTAL_ALIGNMENT_HINT',
                                        },
                                        enumLabels: {
                                          enumControlPlaceholder:
                                            'ACTIVITY_BADGE_STYLE_MODIFIERS_PLACEHOLDER',
                                          start: 'START',
                                          center: 'CENTER',
                                          end: 'END',
                                        },
                                      },
                                      enum: ['start', 'center', 'end'],
                                    },
                                    borderRadius: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'BORDER_RADIUS',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_BADGE_STYLE_MODIFIERS_BORDER_RADIUS_HINT',
                                        },
                                        enumLabels: {
                                          enumControlPlaceholder:
                                            'ACTIVITY_BADGE_STYLE_MODIFIERS_PLACEHOLDER',
                                          pill: 'PILL',
                                          medium: 'MEDIUM',
                                        },
                                      },
                                      enum: ['pill', 'medium'],
                                    },
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_MULTI_SELECT_INPUT_COMPONENT',
                          },
                          'x-examples': {
                            MultipleSelectInputExample: {
                              componentType: 'multiple-select-input',
                              componentAttributes: {
                                altText:
                                  'A list where the user must select one option',
                                answers: [],
                                minimumSelectionRequired: 1,
                                maximumSelectionRequired: 1,
                                answerOptions: [
                                  {
                                    id: 'linkId||0',
                                    text: 'Unemployed',
                                    subText: '',
                                    value: 'WORK_SITUATION_UNEMPLOYED',
                                    enableFreeText: false,
                                    iconUrl: '',
                                  },
                                  {
                                    id: 'linkId||1',
                                    text: 'Part-time or temporary work',
                                    subText: '',
                                    value: 'WORK_SITUATION_PART_TIME',
                                    enableFreeText: false,
                                    iconUrl: '',
                                  },
                                  {
                                    id: 'linkId||2',
                                    text: 'Full-time work',
                                    subText: '',
                                    value: 'WORK_SITUATION_FULL_TIME',
                                    enableFreeText: false,
                                    iconUrl: '',
                                  },
                                  {
                                    id: 'linkId||3',
                                    text: 'Otherwise unemployed but not seeking work, ex: student, retired, disabled, unpaid primary care giver',
                                    subText: '',
                                    value: 'WORK_SITUATION_NOT_SEEKING',
                                    enableFreeText: false,
                                    iconUrl: '',
                                  },
                                  {
                                    id: 'linkId||4',
                                    text: 'I choose not to answer',
                                    subText: '',
                                    value: 'WORK_SITUATION_NO_ANSWER',
                                    enableFreeText: false,
                                    iconUrl: '',
                                  },
                                ],
                              },
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_MULTI_SELECT_INPUT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'multiple-select-input':
                                    'ACTIVITY_MULTI_SELECT_INPUT',
                                },
                              },
                              enum: ['multiple-select-input'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_MULTI_SELECT_INPUT_ATTRIBUTES',
                              },
                              properties: {
                                altText: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                },
                                answerOptions: {
                                  type: 'array',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS_HINT',
                                    },
                                    arrayAddLabel:
                                      'ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS_ADD_LABEL',
                                  },
                                  items: {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_MULTI_SELECT_INPUT_ANSWER_OPTIONS_ITEM',
                                    },
                                    properties: {
                                      text: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_TEXT',
                                        },
                                        'x-entity-presentation': {
                                          hint: {
                                            key: 'ACTIVITY_MULTI_SELECT_INPUT_TEXT_HINT',
                                          },
                                        },
                                      },
                                      subText: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_SUB_TEXT',
                                        },
                                        'x-entity-presentation': {
                                          hint: {
                                            key: 'ACTIVITY_MULTI_SELECT_INPUT_SUB_TEXT_HINT',
                                          },
                                        },
                                      },
                                      value: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'VALUE',
                                        },
                                        'x-entity-presentation': {
                                          hint: {
                                            key: 'ACTIVITY_MULTI_SELECT_INPUT_VALUE_HINT',
                                          },
                                          banner: {
                                            status: 'info',
                                            title: {
                                              key: 'INFO',
                                            },
                                            description: {
                                              key: 'ACTIVITY_MULTI_SELECT_INPUT_VALUE_BANNER_DESCRIPTION',
                                            },
                                          },
                                        },
                                      },
                                      iconUrl: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_ICON_URL',
                                        },
                                      },
                                      isSelected: {
                                        type: 'boolean',
                                        'x-entity-presentation': {
                                          hidden: true,
                                        },
                                      },
                                      enableFreeText: {
                                        type: 'boolean',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_MULTI_SELECT_INPUT_ENABLE_FREE_TEXT',
                                        },
                                        'x-entity-presentation': {
                                          hint: {
                                            key: 'ACTIVITY_MULTI_SELECT_INPUT_ENABLE_FREE_TEXT_HINT',
                                          },
                                        },
                                      },
                                      inputType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_MULTI_SELECT_INPUT_TYPE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            enumControlPlaceholder:
                                              'ACTIVITY_MULTI_SELECT_PLACEHOLDER',
                                            string: 'STRING',
                                            integer: 'INTEGER',
                                            decimal: 'DECIMAL',
                                            coding: 'CODING',
                                            date: 'DATE',
                                            time: 'TIME',
                                          },
                                        },
                                        enum: [
                                          'string',
                                          'integer',
                                          'decimal',
                                          'coding',
                                          'date',
                                          'time',
                                        ],
                                      },
                                      freeTextValidation: {
                                        type: 'object',
                                        properties: {
                                          inputType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_MULTI_SELECT_INPUT_TYPE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                enumControlPlaceholder:
                                                  'ACTIVITY_MULTI_SELECT_PLACEHOLDER',
                                                string: 'STRING',
                                                integer: 'INTEGER',
                                              },
                                            },
                                            enum: ['string', 'integer'],
                                          },
                                          placeholder: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_INPUT_PLACEHOLDER',
                                            },
                                            'x-entity-presentation': {
                                              hint: {
                                                key: 'ACTIVITY_INPUT_PLACEHOLDER_HINT',
                                              },
                                            },
                                          },
                                          characterLimit: {
                                            type: 'number',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_MULTI_SELECT_CHARACTER_LIMIT',
                                            },
                                            'x-entity-presentation': {
                                              hint: {
                                                key: 'ACTIVITY_MULTI_SELECT_CHARACTER_LIMIT_HINT',
                                              },
                                            },
                                          },
                                          minimumValue: {
                                            type: 'number',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_MULTI_SELECT_MIN_VALUE',
                                            },
                                            'x-entity-presentation': {
                                              hint: {
                                                key: 'ACTIVITY_MULTI_SELECT_VALUE_HINT',
                                              },
                                            },
                                          },
                                          maximumValue: {
                                            type: 'number',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_MULTI_SELECT_MAX_VALUE',
                                            },
                                            'x-entity-presentation': {
                                              hint: {
                                                key: 'ACTIVITY_MULTI_SELECT_VALUE_HINT',
                                              },
                                            },
                                          },
                                        },
                                      },
                                      id: {
                                        type: 'string',
                                        'x-entity-presentation': {
                                          hidden: true,
                                        },
                                      },
                                      exclusive: {
                                        type: 'boolean',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_MULTI_SELECT_EXCLUSIVE',
                                        },
                                        'x-entity-presentation': {
                                          hint: {
                                            key: 'ACTIVITY_MULTI_SELECT_EXCLUSIVE_HINT',
                                          },
                                        },
                                        description:
                                          'if this field set to true then the FE will unselect all other selected options if this option is selected',
                                      },
                                    },
                                    required: ['id'],
                                  },
                                },
                                validation: {
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_VALIDATION',
                                  },
                                  properties: {
                                    minimumSelectionRequired: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_MULTI_SELECT_MIN_SELECTION_REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_MULTI_SELECT_MIN_SELECTION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    maximumSelectionRequired: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_MULTI_SELECT_MAX_SELECTION_REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_MULTI_SELECT_MAX_SELECTION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    required: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    customWarning: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING',
                                      },
                                    },
                                    editable: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE_HINT',
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            componentStorageSources: {
                              type: 'array',

                              'x-entity-label': {
                                key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT',
                                },
                                hidden: true,
                              },
                              items: {
                                title: 'storageSourcesAttributes',
                                'x-entity-label': {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                                },
                                discriminator: {
                                  propertyName: 'storageSourceType',
                                },
                                oneOf: [
                                  {
                                    title:
                                      'QuestionnaireStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'y3h4dpycril6g',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      questionnaireStorageSourceExample: {
                                        sourceType: 'fhir-questionnaire',
                                        sourceAttributes: {
                                          questionnaireReference:
                                            'questionnaire-123',
                                          linkId: 'linkid-123',
                                          code: 'CODE',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_QUESTIONNAIRE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-questionnaire':
                                              'ACTIVITY_FHIR_QUESTIONNAIRE',
                                          },
                                        },
                                        enum: ['fhir-questionnaire'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          questionnaireReference: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_QUESTIONNAIRE_REFERENCE',
                                            },
                                          },
                                          linkId: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_LINK_ID',
                                            },
                                          },
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ObservationStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'f6w6cllqlrhx8',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_OBSERVATION',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-observation':
                                              'ACTIVITY_FHIR_OBSERVATION',
                                          },
                                        },
                                        enum: ['fhir-observation'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                          metric: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'METRIC',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                    'x-examples': {
                                      'Example 1': {
                                        storageSourceType: 'fhir-observation',
                                        storageSourceAttributes: {
                                          code: 'nl-123',
                                          metric: 'count',
                                        },
                                      },
                                    },
                                  },
                                  {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      CarePlanStorageSourceAttributesExample: {
                                        storageSourceType: 'fhir-care-plan',
                                        storageSourceAttributes: {
                                          carePlanIds: [
                                            'dyn-camp-123',
                                            'dyn-camp-345',
                                          ],
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_CARE_PLAN',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-care-plan':
                                              'ACTIVITY_FHIR_CARE_PLAN',
                                          },
                                        },
                                        enum: ['fhir-care-plan'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          carePlanIds: {
                                            type: 'array',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CARE_PLAN_IDS',
                                            },
                                            'x-entity-presentation': {
                                              arrayAddLabel:
                                                'ACTIVITY_CARE_PLAN_ADD_LABEL',
                                            },
                                            items: {
                                              type: 'string',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ConsentStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'yjjgt81p17ws6',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      'Gina Consent Example': {
                                        storageSourceType: 'consent',
                                        storageSourceAttributes: {
                                          consentType: 'gina_consent',
                                          consentEntity: 'observation',
                                          consentStore: 'lpf',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_CONSENT',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            consent: 'ACTIVITY_CONSENT',
                                          },
                                        },
                                        enum: ['consent'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          consentType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_TYPE',
                                            },
                                          },
                                          consentEntity: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_ENTITY',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                observation: 'OBSERVATION',
                                              },
                                            },
                                            enum: ['observation'],
                                          },
                                          consentStore: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_STORE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                core: 'CORE',
                                                lpf: 'LPF',
                                              },
                                            },
                                            enum: ['core', 'lpf'],
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                ],
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'ProgressTrackingComponentAttributes',
                          'x-stoplight': {
                            id: 'qyv776idr50zj',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_PROGRESS_TRACKING_COMPONENT',
                          },
                          'x-examples': {
                            ProgressTrackingComponent: {
                              componentType: 'progress-tracking',
                              componentAttributes: {
                                unit: 'Steps',
                                metric: 'summary_daily_steps',
                                goal: 15000,
                                currentProgress: 15000,
                                progressPercentage: 10,
                                inputMethod: 'circular-bar',
                                validation: {
                                  allowCompletionBellowGoal: true,
                                  allowUserOverwrite: true,
                                  currentProgressLimit: 125000,
                                  editable: true,
                                  updateWindowInDays: 3,
                                },
                              },
                              componentStorageSources: [
                                {
                                  storageSourceType: 'fhir-questionnaire',
                                  storageSourceAttributes: {
                                    questionnaireReference: 'string',
                                    linkId: 'string',
                                    code: 'string',
                                  },
                                },
                              ],
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_PROGRESS_TRACKING',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'progress-tracking':
                                    'ACTIVITY_PROGRESS_TRACKING',
                                },
                              },
                              enum: ['progress-tracking'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_PROGRESS_TRACKING_COMPONENT_ATTRIBUTES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_PROGRESS_TRACKING_COMPONENT_ATTRIBUTES_HINT',
                                },
                                banner: {
                                  status: 'info',
                                  title: {
                                    key: 'ACTIVITY_PROGRESS_TRACKING_BANNER_TITLE',
                                  },
                                  description: {
                                    key: 'ACTIVITY_PROGRESS_TRACKING_BANNER_DESCRIPTION',
                                  },
                                },
                              },
                              properties: {
                                unit: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_PROGRESS_TRACKING_UNIT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_PROGRESS_TRACKING_UNIT_HINT',
                                    },
                                  },
                                  description: 'User Facing Metric.',
                                },
                                metric: {
                                  type: 'string',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                  description:
                                    'Internal code used for storing the metric.',
                                },
                                goal: {
                                  type: 'integer',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_PROGRESS_TRACKING_GOAL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_PROGRESS_TRACKING_GOAL_HINT',
                                    },
                                  },
                                },
                                currentProgress: {
                                  type: 'number',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                progressPercentage: {
                                  type: 'integer',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                inputMethod: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_PROGRESS_TRACKING_INPUT_METHOD',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_PROGRESS_TRACKING_INPUT_METHOD_HINT',
                                    },
                                    enumLabels: {
                                      'circular-bar':
                                        'ACTIVITY_PROGRESS_TRACKING_CIRCULAR_BAR',
                                    },
                                  },
                                  enum: [
                                    'button',
                                    'horizontal-bar',
                                    'circular-bar',
                                  ],
                                },
                                validation: {
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_VALIDATION',
                                  },
                                  properties: {
                                    allowCompletionBellowGoal: {
                                      type: 'boolean',
                                      'x-entity-presentation': {
                                        hidden: true,
                                      },
                                    },
                                    allowUserOverwrite: {
                                      type: 'boolean',
                                      'x-entity-presentation': {
                                        hidden: true,
                                      },
                                    },
                                    currentProgressLimit: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_PROGRESS_TRACKING_CURRENT_PROGRESS_LIMIT',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_PROGRESS_TRACKING_CURRENT_PROGRESS_LIMIT_HINT',
                                        },
                                      },
                                    },
                                    customWarning: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING_HINT',
                                        },
                                      },
                                    },
                                    editable: {
                                      type: 'boolean',
                                      'x-entity-presentation': {
                                        hidden: true,
                                      },
                                    },
                                    updateWindowInDays: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS_HINT',
                                        },
                                        banner: {
                                          status: 'info',
                                          title: {
                                            key: 'ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS_BANNER_TITLE',
                                          },
                                          description: {
                                            key: 'ACTIVITY_PROGRESS_TRACKING_UPDATE_WINDOW_IN_DAYS_BANNER_DESCRIPTION',
                                          },
                                        },
                                      },
                                      description:
                                        'An offset number of days defined by the content editor. If the Update Window is less than the Activity End Date, the Editable field will be set to true.',
                                    },
                                  },
                                },
                                editButtonLabel: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_PROGRESS_TRACKING_EDIT_BUTTON_LABEL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_PROGRESS_TRACKING_EDIT_BUTTON_LABEL_HINT',
                                    },
                                  },
                                },
                              },
                            },
                            componentStorageSources: {
                              type: 'array',
                              'x-entity-label': {
                                key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT',
                                },
                                hidden: true,
                              },
                              items: {
                                title: 'storageSourcesAttributes',
                                'x-entity-label': {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                                },
                                discriminator: {
                                  propertyName: 'storageSourceType',
                                },
                                oneOf: [
                                  {
                                    title:
                                      'QuestionnaireStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'y3h4dpycril6g',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      questionnaireStorageSourceExample: {
                                        sourceType: 'fhir-questionnaire',
                                        sourceAttributes: {
                                          questionnaireReference:
                                            'questionnaire-123',
                                          linkId: 'linkid-123',
                                          code: 'CODE',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_QUESTIONNAIRE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-questionnaire':
                                              'ACTIVITY_FHIR_QUESTIONNAIRE',
                                          },
                                        },
                                        enum: ['fhir-questionnaire'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          questionnaireReference: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_QUESTIONNAIRE_REFERENCE',
                                            },
                                          },
                                          linkId: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_LINK_ID',
                                            },
                                          },
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ObservationStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'f6w6cllqlrhx8',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_OBSERVATION',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-observation':
                                              'ACTIVITY_FHIR_OBSERVATION',
                                          },
                                        },
                                        enum: ['fhir-observation'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                          metric: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'METRIC',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                    'x-examples': {
                                      'Example 1': {
                                        storageSourceType: 'fhir-observation',
                                        storageSourceAttributes: {
                                          code: 'nl-123',
                                          metric: 'count',
                                        },
                                      },
                                    },
                                  },
                                  {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      CarePlanStorageSourceAttributesExample: {
                                        storageSourceType: 'fhir-care-plan',
                                        storageSourceAttributes: {
                                          carePlanIds: [
                                            'dyn-camp-123',
                                            'dyn-camp-345',
                                          ],
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_CARE_PLAN',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-care-plan':
                                              'ACTIVITY_FHIR_CARE_PLAN',
                                          },
                                        },
                                        enum: ['fhir-care-plan'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          carePlanIds: {
                                            type: 'array',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CARE_PLAN_IDS',
                                            },
                                            'x-entity-presentation': {
                                              arrayAddLabel:
                                                'ACTIVITY_CARE_PLAN_ADD_LABEL',
                                            },
                                            items: {
                                              type: 'string',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ConsentStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'yjjgt81p17ws6',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      'Gina Consent Example': {
                                        storageSourceType: 'consent',
                                        storageSourceAttributes: {
                                          consentType: 'gina_consent',
                                          consentEntity: 'observation',
                                          consentStore: 'lpf',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_CONSENT',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            consent: 'ACTIVITY_CONSENT',
                                          },
                                        },
                                        enum: ['consent'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          consentType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_TYPE',
                                            },
                                          },
                                          consentEntity: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_ENTITY',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                observation: 'OBSERVATION',
                                              },
                                            },
                                            enum: ['observation'],
                                          },
                                          consentStore: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_STORE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                core: 'CORE',
                                                lpf: 'LPF',
                                              },
                                            },
                                            enum: ['core', 'lpf'],
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                ],
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'IntegerInputComponentAttributes',
                          'x-stoplight': {
                            id: 't4cf1h76wtf43',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_INTEGER_INPUT_COMPONENT',
                          },
                          'x-examples': {
                            'Example 1': {
                              id: 'string',
                              componentType: 'integer-input',
                              componentAttributes: {
                                prefix: 'string',
                                suffix: 'string',
                                answer: 0,
                                inputMethod: 'manual',
                                altText: 'string',
                                placeholder: 'string',
                                unit: 'string',
                                validation: {
                                  minimumValue: 0,
                                  maximumValue: 0,
                                  required: true,
                                  editable: true,
                                },
                              },
                              componentStorageSources: [
                                {
                                  storageSourceType: 'fhir-questionnaire',
                                  storageSourceAttributes: {
                                    questionnaireReference: 'string',
                                    linkId: 'string',
                                    code: 'string',
                                  },
                                },
                              ],
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_INTEGER_INPUT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'integer-input': 'ACTIVITY_INTEGER_INPUT',
                                },
                              },
                              enum: ['integer-input'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_INTEGER_INPUT_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                prefix: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PREFIX',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_PREFIX_HINT',
                                    },
                                  },
                                },
                                suffix: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_SUFFIX',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_SUFFIX_HINT',
                                    },
                                  },
                                },
                                answer: {
                                  type: 'integer',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                inputMethod: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_INPUT_METHOD',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INTEGER_INPUT_INPUT_METHOD_HINT',
                                    },
                                    enumLabels: {
                                      manual: 'MANUAL',
                                    },
                                  },
                                  enum: ['manual', 'slider', 'counter'],
                                },
                                altText: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_ALT_TEXT_HINT',
                                    },
                                  },
                                },
                                placeholder: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PLACEHOLDER',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_PLACEHOLDER_HINT',
                                    },
                                  },
                                },
                                unit: {
                                  type: 'string',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                validation: {
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_VALIDATION',
                                  },
                                  properties: {
                                    minimumValue: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_MIN_VALUE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_MIN_VALUE_HINT',
                                        },
                                      },
                                    },
                                    maximumValue: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_MAX_VALUE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_MAX_VALUE_HINT',
                                        },
                                      },
                                    },
                                    required: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    customWarning: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING_HINT',
                                        },
                                      },
                                    },
                                    editable: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE_HINT',
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            componentStorageSources: {
                              type: 'array',
                              'x-entity-label': {
                                key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT',
                                },
                                hidden: true,
                              },
                              items: {
                                title: 'storageSourcesAttributes',
                                'x-entity-label': {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                                },
                                discriminator: {
                                  propertyName: 'storageSourceType',
                                },
                                oneOf: [
                                  {
                                    title:
                                      'QuestionnaireStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'y3h4dpycril6g',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      questionnaireStorageSourceExample: {
                                        sourceType: 'fhir-questionnaire',
                                        sourceAttributes: {
                                          questionnaireReference:
                                            'questionnaire-123',
                                          linkId: 'linkid-123',
                                          code: 'CODE',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_QUESTIONNAIRE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-questionnaire':
                                              'ACTIVITY_FHIR_QUESTIONNAIRE',
                                          },
                                        },
                                        enum: ['fhir-questionnaire'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          questionnaireReference: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_QUESTIONNAIRE_REFERENCE',
                                            },
                                          },
                                          linkId: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_LINK_ID',
                                            },
                                          },
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ObservationStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'f6w6cllqlrhx8',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_OBSERVATION',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-observation':
                                              'ACTIVITY_FHIR_OBSERVATION',
                                          },
                                        },
                                        enum: ['fhir-observation'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                          metric: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'METRIC',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                    'x-examples': {
                                      'Example 1': {
                                        storageSourceType: 'fhir-observation',
                                        storageSourceAttributes: {
                                          code: 'nl-123',
                                          metric: 'count',
                                        },
                                      },
                                    },
                                  },
                                  {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      CarePlanStorageSourceAttributesExample: {
                                        storageSourceType: 'fhir-care-plan',
                                        storageSourceAttributes: {
                                          carePlanIds: [
                                            'dyn-camp-123',
                                            'dyn-camp-345',
                                          ],
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_CARE_PLAN',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-care-plan':
                                              'ACTIVITY_FHIR_CARE_PLAN',
                                          },
                                        },
                                        enum: ['fhir-care-plan'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          carePlanIds: {
                                            type: 'array',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CARE_PLAN_IDS',
                                            },
                                            'x-entity-presentation': {
                                              arrayAddLabel:
                                                'ACTIVITY_CARE_PLAN_ADD_LABEL',
                                            },
                                            items: {
                                              type: 'string',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ConsentStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'yjjgt81p17ws6',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      'Gina Consent Example': {
                                        storageSourceType: 'consent',
                                        storageSourceAttributes: {
                                          consentType: 'gina_consent',
                                          consentEntity: 'observation',
                                          consentStore: 'lpf',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_CONSENT',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            consent: 'ACTIVITY_CONSENT',
                                          },
                                        },
                                        enum: ['consent'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          consentType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_TYPE',
                                            },
                                          },
                                          consentEntity: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_ENTITY',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                observation: 'OBSERVATION',
                                              },
                                            },
                                            enum: ['observation'],
                                          },
                                          consentStore: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_STORE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                core: 'CORE',
                                                lpf: 'LPF',
                                              },
                                            },
                                            enum: ['core', 'lpf'],
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                ],
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_DATE_INPUT_COMPONENT',
                          },
                          'x-examples': {
                            DateInputComponentExample: {
                              componentType: 'date-input',
                              componentAttributes: {
                                altText:
                                  "A question with a date picker, four selectable options to different dates and a don't remember option",
                                answer: '',
                                dateInputMethod: 'month-year',
                                additionalDateOptions: [
                                  {
                                    id: 'linkId||0',
                                    text: '3 months',
                                    value: 3,
                                    unit: 'month',
                                  },
                                  {
                                    id: 'linkId||1',
                                    text: '6 months',
                                    value: 6,
                                    unit: 'month',
                                  },
                                  {
                                    id: 'linkId||2',
                                    text: '1 year',
                                    value: 1,
                                    unit: 'year',
                                  },
                                  {
                                    id: 'linkId||3',
                                    text: '2 years',
                                    value: 2,
                                    unit: 'year',
                                  },
                                  {
                                    id: 'linkId||4',
                                    text: "I don't remember",
                                    value: 0,
                                    unit: 'no-answer',
                                  },
                                ],
                              },
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_DATE_INPUT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'date-input': 'ACTIVITY_DATE_INPUT',
                                },
                              },
                              enum: ['date-input'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_DATE_INPUT_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                altText: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                },
                                answer: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ANSWER',
                                  },
                                  format: 'date-time',
                                },
                                dateInputMethod: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_DATE_INPUT_DATE_INPUT_METHOD',
                                  },
                                  'x-entity-presentation': {
                                    enumLabels: {
                                      enumControlPlaceholder:
                                        'ACTIVITY_DATE_INPUT_METHOD_PLACEHOLDER',
                                      'date-pick':
                                        'ACTIVITY_DATE_INPUT_METHOD_DATE_PICK',
                                      'month-year':
                                        'ACTIVITY_DATE_INPUT_METHOD_MONTH_YEAR',
                                    },
                                  },
                                  enum: ['date-pick', 'month-year'],
                                },
                                placeholder: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PLACEHOLDER',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_PLACEHOLDER_HINT',
                                    },
                                  },
                                },
                                additionalDateOptions: {
                                  type: 'array',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS',
                                  },
                                  'x-entity-presentation': {
                                    arrayAddLabel:
                                      'ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_ADD_LABEL',
                                    hint: {
                                      key: 'ACTIVITY_DATE_INPUT_ADDITIONAL_DATE_OPTIONS_HINT',
                                    },
                                  },
                                  items: {
                                    type: 'object',
                                    properties: {
                                      text: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_TEXT',
                                        },
                                      },
                                      value: {
                                        type: 'integer',
                                        'x-entity-label': {
                                          key: 'VALUE',
                                        },
                                      },
                                      unit: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'UNIT',
                                        },
                                      },
                                      isSelected: {
                                        type: 'boolean',
                                        'x-entity-presentation': {
                                          hidden: true,
                                        },
                                      },
                                      id: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ID',
                                        },
                                      },
                                      code: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'CODE',
                                        },
                                      },
                                    },
                                    required: ['id'],
                                  },
                                },
                                validation: {
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_VALIDATION',
                                  },
                                  properties: {
                                    minimumDate: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_DATE_INPUT_MIN_DATE',
                                      },
                                      format: 'date-time',
                                    },
                                    maximumDate: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_DATE_INPUT_MAX_DATE',
                                      },
                                      format: 'date-time',
                                    },
                                    required: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    customWarning: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING_HINT',
                                        },
                                      },
                                    },
                                    editable: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE_HINT',
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            componentStorageSources: {
                              type: 'array',
                              'x-entity-label': {
                                key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT',
                                },
                                hidden: true,
                              },
                              items: {
                                title: 'storageSourcesAttributes',
                                'x-entity-label': {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                                },
                                discriminator: {
                                  propertyName: 'storageSourceType',
                                },
                                oneOf: [
                                  {
                                    title:
                                      'QuestionnaireStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'y3h4dpycril6g',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      questionnaireStorageSourceExample: {
                                        sourceType: 'fhir-questionnaire',
                                        sourceAttributes: {
                                          questionnaireReference:
                                            'questionnaire-123',
                                          linkId: 'linkid-123',
                                          code: 'CODE',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_QUESTIONNAIRE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-questionnaire':
                                              'ACTIVITY_FHIR_QUESTIONNAIRE',
                                          },
                                        },
                                        enum: ['fhir-questionnaire'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          questionnaireReference: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_QUESTIONNAIRE_REFERENCE',
                                            },
                                          },
                                          linkId: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_LINK_ID',
                                            },
                                          },
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ObservationStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'f6w6cllqlrhx8',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_OBSERVATION',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-observation':
                                              'ACTIVITY_FHIR_OBSERVATION',
                                          },
                                        },
                                        enum: ['fhir-observation'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                          metric: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'METRIC',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                    'x-examples': {
                                      'Example 1': {
                                        storageSourceType: 'fhir-observation',
                                        storageSourceAttributes: {
                                          code: 'nl-123',
                                          metric: 'count',
                                        },
                                      },
                                    },
                                  },
                                  {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      CarePlanStorageSourceAttributesExample: {
                                        storageSourceType: 'fhir-care-plan',
                                        storageSourceAttributes: {
                                          carePlanIds: [
                                            'dyn-camp-123',
                                            'dyn-camp-345',
                                          ],
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_CARE_PLAN',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-care-plan':
                                              'ACTIVITY_FHIR_CARE_PLAN',
                                          },
                                        },
                                        enum: ['fhir-care-plan'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          carePlanIds: {
                                            type: 'array',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CARE_PLAN_IDS',
                                            },
                                            'x-entity-presentation': {
                                              arrayAddLabel:
                                                'ACTIVITY_CARE_PLAN_ADD_LABEL',
                                            },
                                            items: {
                                              type: 'string',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ConsentStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'yjjgt81p17ws6',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      'Gina Consent Example': {
                                        storageSourceType: 'consent',
                                        storageSourceAttributes: {
                                          consentType: 'gina_consent',
                                          consentEntity: 'observation',
                                          consentStore: 'lpf',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_CONSENT',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            consent: 'ACTIVITY_CONSENT',
                                          },
                                        },
                                        enum: ['consent'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          consentType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_TYPE',
                                            },
                                          },
                                          consentEntity: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_ENTITY',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                observation: 'OBSERVATION',
                                              },
                                            },
                                            enum: ['observation'],
                                          },
                                          consentStore: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_STORE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                core: 'CORE',
                                                lpf: 'LPF',
                                              },
                                            },
                                            enum: ['core', 'lpf'],
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                ],
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_STRING_INPUT_COMPONENT',
                          },
                          'x-examples': {
                            StringInputComponentExample: {
                              componentType: 'string-input',
                              componentAttributes: {
                                prefix: 'This week I commit to ',
                                answer: '',
                                inputMethod: 'box',
                                altText:
                                  'A text box where the user types in a personal commitment for the next week',
                              },
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_STRING_INPUT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'string-input': 'ACTIVITY_STRING_INPUT',
                                },
                              },
                              enum: ['string-input'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_STRING_INPUT_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                prefix: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PREFIX',
                                  },
                                },
                                answer: {
                                  type: 'string',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                inputMethod: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_INPUT_METHOD',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_STRING_INPUT_INPUT_METHOD_HINT',
                                    },
                                    enumLabels: {
                                      enumControlPlaceholder:
                                        'ACTIVITY_STRING_PLACEHOLDER',
                                      line: 'LINE',
                                      box: 'BOX',
                                    },
                                  },
                                  enum: ['line', 'box'],
                                },
                                altText: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                },
                                placeholder: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PLACEHOLDER',
                                  },
                                },
                                validation: {
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_VALIDATION',
                                  },
                                  properties: {
                                    characterLimit: {
                                      type: 'integer',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_STRING_CHARACTER_LIMIT',
                                      },
                                    },
                                    required: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    customWarning: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING',
                                      },
                                    },
                                    editable: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE_HINT',
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            componentStorageSources: {
                              type: 'array',
                              'x-entity-label': {
                                key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT',
                                },
                                hidden: true,
                              },
                              items: {
                                title: 'storageSourcesAttributes',
                                'x-entity-label': {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                                },
                                discriminator: {
                                  propertyName: 'storageSourceType',
                                },
                                oneOf: [
                                  {
                                    title:
                                      'QuestionnaireStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'y3h4dpycril6g',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      questionnaireStorageSourceExample: {
                                        sourceType: 'fhir-questionnaire',
                                        sourceAttributes: {
                                          questionnaireReference:
                                            'questionnaire-123',
                                          linkId: 'linkid-123',
                                          code: 'CODE',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_QUESTIONNAIRE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-questionnaire':
                                              'ACTIVITY_FHIR_QUESTIONNAIRE',
                                          },
                                        },
                                        enum: ['fhir-questionnaire'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          questionnaireReference: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_QUESTIONNAIRE_REFERENCE',
                                            },
                                          },
                                          linkId: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_LINK_ID',
                                            },
                                          },
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ObservationStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'f6w6cllqlrhx8',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_OBSERVATION',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-observation':
                                              'ACTIVITY_FHIR_OBSERVATION',
                                          },
                                        },
                                        enum: ['fhir-observation'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                          metric: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'METRIC',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                    'x-examples': {
                                      'Example 1': {
                                        storageSourceType: 'fhir-observation',
                                        storageSourceAttributes: {
                                          code: 'nl-123',
                                          metric: 'count',
                                        },
                                      },
                                    },
                                  },
                                  {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      CarePlanStorageSourceAttributesExample: {
                                        storageSourceType: 'fhir-care-plan',
                                        storageSourceAttributes: {
                                          carePlanIds: [
                                            'dyn-camp-123',
                                            'dyn-camp-345',
                                          ],
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_CARE_PLAN',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-care-plan':
                                              'ACTIVITY_FHIR_CARE_PLAN',
                                          },
                                        },
                                        enum: ['fhir-care-plan'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          carePlanIds: {
                                            type: 'array',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CARE_PLAN_IDS',
                                            },
                                            'x-entity-presentation': {
                                              arrayAddLabel:
                                                'ACTIVITY_CARE_PLAN_ADD_LABEL',
                                            },
                                            items: {
                                              type: 'string',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ConsentStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'yjjgt81p17ws6',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      'Gina Consent Example': {
                                        storageSourceType: 'consent',
                                        storageSourceAttributes: {
                                          consentType: 'gina_consent',
                                          consentEntity: 'observation',
                                          consentStore: 'lpf',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_CONSENT',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            consent: 'ACTIVITY_CONSENT',
                                          },
                                        },
                                        enum: ['consent'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          consentType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_TYPE',
                                            },
                                          },
                                          consentEntity: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_ENTITY',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                observation: 'OBSERVATION',
                                              },
                                            },
                                            enum: ['observation'],
                                          },
                                          consentStore: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_STORE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                core: 'CORE',
                                                lpf: 'LPF',
                                              },
                                            },
                                            enum: ['core', 'lpf'],
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                ],
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'DecimalInputComponentAttributes',
                          'x-stoplight': {
                            id: '0hun30a5sjnjk',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_DECIMAL_INPUT_COMPONENT',
                          },
                          'x-examples': {
                            DecimalInputComponentExample: {
                              componentType: 'decimal-input',
                              componentAttributes: {
                                prefix: 'My height is ',
                                suffix: ' feet',
                                answer: 6.4,
                                altText:
                                  'A decimal input where the users type in how many feet tall they are',
                              },
                            },
                          },
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_DECIMAL_INPUT',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'decimal-input': 'ACTIVITY_DECIMAL_INPUT',
                                },
                              },
                              enum: ['decimal-input'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_DECIMAL_INPUT_COMPONENT_ATTRIBUTES',
                              },
                              properties: {
                                prefix: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PREFIX',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_PREFIX_HINT',
                                    },
                                  },
                                },
                                suffix: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_SUFFIX',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_SUFFIX_HINT',
                                    },
                                  },
                                },
                                answer: {
                                  type: 'number',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                altText: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ALT_TEXT',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_ALT_TEXT_HINT',
                                    },
                                  },
                                },
                                placeholder: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_PLACEHOLDER',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_INPUT_PLACEHOLDER_HINT',
                                    },
                                  },
                                },
                                unit: {
                                  type: 'string',
                                  'x-entity-presentation': {
                                    hidden: true,
                                  },
                                },
                                validation: {
                                  type: 'object',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_INPUT_VALIDATION',
                                  },
                                  properties: {
                                    minimumValue: {
                                      type: 'number',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_MIN_VALUE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_MIN_VALUE_HINT',
                                        },
                                      },
                                    },
                                    maximumValue: {
                                      type: 'number',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_MAX_VALUE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_MAX_VALUE_HINT',
                                        },
                                      },
                                    },
                                    required: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'REQUIRED',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_REQUIRED_HINT',
                                        },
                                      },
                                    },
                                    customWarning: {
                                      type: 'string',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_CUSTOM_WARNING_HINT',
                                        },
                                      },
                                    },
                                    editable: {
                                      type: 'boolean',
                                      'x-entity-label': {
                                        key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE',
                                      },
                                      'x-entity-presentation': {
                                        hint: {
                                          key: 'ACTIVITY_INPUT_VALIDATION_EDITABLE_HINT',
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            componentStorageSources: {
                              type: 'array',
                              'x-entity-label': {
                                key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES_HINT',
                                },
                                hidden: true,
                              },
                              items: {
                                title: 'storageSourcesAttributes',
                                'x-entity-label': {
                                  key: 'ACTIVITY_COMPONENT_STORAGE_SOURCES',
                                },
                                discriminator: {
                                  propertyName: 'storageSourceType',
                                },
                                anyOf: [
                                  {
                                    title:
                                      'QuestionnaireStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'y3h4dpycril6g',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_QUESTIONNAIRE_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      questionnaireStorageSourceExample: {
                                        sourceType: 'fhir-questionnaire',
                                        sourceAttributes: {
                                          questionnaireReference:
                                            'questionnaire-123',
                                          linkId: 'linkid-123',
                                          code: 'CODE',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_QUESTIONNAIRE',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-questionnaire':
                                              'ACTIVITY_FHIR_QUESTIONNAIRE',
                                          },
                                        },
                                        enum: ['fhir-questionnaire'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          questionnaireReference: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_QUESTIONNAIRE_REFERENCE',
                                            },
                                          },
                                          linkId: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_LINK_ID',
                                            },
                                          },
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ObservationStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'f6w6cllqlrhx8',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_OBSERVATION_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_OBSERVATION',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-observation':
                                              'ACTIVITY_FHIR_OBSERVATION',
                                          },
                                        },
                                        enum: ['fhir-observation'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          code: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'CODE',
                                            },
                                          },
                                          metric: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'METRIC',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                    'x-examples': {
                                      'Example 1': {
                                        storageSourceType: 'fhir-observation',
                                        storageSourceAttributes: {
                                          code: 'nl-123',
                                          metric: 'count',
                                        },
                                      },
                                    },
                                  },
                                  {
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CARE_PLAN_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      CarePlanStorageSourceAttributesExample: {
                                        storageSourceType: 'fhir-care-plan',
                                        storageSourceAttributes: {
                                          carePlanIds: [
                                            'dyn-camp-123',
                                            'dyn-camp-345',
                                          ],
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_FHIR_CARE_PLAN',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            'fhir-care-plan':
                                              'ACTIVITY_FHIR_CARE_PLAN',
                                          },
                                        },
                                        enum: ['fhir-care-plan'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          carePlanIds: {
                                            type: 'array',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CARE_PLAN_IDS',
                                            },
                                            'x-entity-presentation': {
                                              arrayAddLabel:
                                                'ACTIVITY_CARE_PLAN_ADD_LABEL',
                                            },
                                            items: {
                                              type: 'string',
                                            },
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                  {
                                    title: 'ConsentStorageSourceAttributes',
                                    'x-stoplight': {
                                      id: 'yjjgt81p17ws6',
                                    },
                                    type: 'object',
                                    'x-entity-label': {
                                      key: 'ACTIVITY_CONSENT_STORAGE_SOURCE_ATTRIBUTES',
                                    },
                                    'x-examples': {
                                      'Gina Consent Example': {
                                        storageSourceType: 'consent',
                                        storageSourceAttributes: {
                                          consentType: 'gina_consent',
                                          consentEntity: 'observation',
                                          consentStore: 'lpf',
                                        },
                                      },
                                    },
                                    properties: {
                                      storageSourceType: {
                                        type: 'string',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_CONSENT',
                                        },
                                        'x-entity-presentation': {
                                          enumLabels: {
                                            consent: 'ACTIVITY_CONSENT',
                                          },
                                        },
                                        enum: ['consent'],
                                      },
                                      storageSourceAttributes: {
                                        type: 'object',
                                        'x-entity-label': {
                                          key: 'ACTIVITY_STORAGE_SOURCE_ATTRIBUTES',
                                        },
                                        properties: {
                                          consentType: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_TYPE',
                                            },
                                          },
                                          consentEntity: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_ENTITY',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                observation: 'OBSERVATION',
                                              },
                                            },
                                            enum: ['observation'],
                                          },
                                          consentStore: {
                                            type: 'string',
                                            'x-entity-label': {
                                              key: 'ACTIVITY_CONSENT_STORE',
                                            },
                                            'x-entity-presentation': {
                                              enumLabels: {
                                                core: 'CORE',
                                                lpf: 'LPF',
                                              },
                                            },
                                            enum: ['core', 'lpf'],
                                          },
                                        },
                                      },
                                    },
                                    required: ['storageSourceType'],
                                  },
                                ],
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                        {
                          title: 'RewardsHeaderComponentAttributes',
                          'x-stoplight': {
                            id: '7qrltfee8wb82',
                          },
                          type: 'object',
                          'x-entity-label': {
                            key: 'ACTIVITY_REWARDS_HEADER_COMPONENT',
                          },
                          'x-examples': {
                            RewardsHeaderComponentAttributesExample: {
                              componentType: 'rewards-header',
                              componentAttributes: {
                                imageUrl: 'http://link.com/image.jpg',
                                campaignName: 'Fueling for Performance',
                                alt: 'alt',
                              },
                            },
                          },
                          description:
                            "The RewardsHeaderComponent is meant to be used as the first component of the first page of a Toolbox Activity Rewards Campaign, and it'll display an image on the top of the first page.",
                          properties: {
                            id: {
                              type: 'string',
                              'x-entity-presentation': {
                                hidden: true,
                              },
                            },
                            componentType: {
                              type: 'string',
                              'x-entity-label': {
                                key: 'ACTIVITY_REWARDS_HEADER',
                              },
                              'x-entity-presentation': {
                                enumLabels: {
                                  'rewards-header': 'ACTIVITY_REWARDS_HEADER',
                                },
                              },
                              enum: ['rewards-header'],
                            },
                            componentAttributes: {
                              type: 'object',
                              'x-entity-label': {
                                key: 'ACTIVITY_REWARDS_HEADER_COMPONENT_ATTRIBUTES',
                              },
                              'x-entity-presentation': {
                                hint: {
                                  key: 'ACTIVITY_REWARDS_HEADER_COMPONENT_ATTRIBUTES_HINT',
                                },
                              },
                              properties: {
                                imageUrl: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_HEADER_IMAGE_URL',
                                  },
                                  'x-entity-presentation': {
                                    hint: {
                                      key: 'ACTIVITY_HEADER_IMAGE_URL_HINT',
                                    },
                                  },
                                },
                                campaignName: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_HEADER_CAMPAIGN_NAME',
                                  },
                                },
                                alt: {
                                  type: 'string',
                                  'x-entity-label': {
                                    key: 'ACTIVITY_HEADER_ALT',
                                  },
                                },
                              },
                            },
                          },
                          required: ['componentType'],
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
        assignmentData: {
          type: 'object',
          'x-entity-label': {
            key: 'ACTIVITY_ASSIGNMENT_DATA',
          },
          'x-stoplight': {
            id: 'vwpu5cx34eyop',
          },
          properties: {
            required: {
              type: 'boolean',
              'x-entity-label': {
                key: 'ACTIVITY_REQUIRED',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_REQUIRED_HINT',
                },
              },
              'x-stoplight': {
                id: 'nsl18rermtsz1',
              },
              default: false,
            },
            removable: {
              type: 'boolean',
              'x-entity-label': {
                key: 'ACTIVITY_REMOVABLE',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_REMOVABLE_HINT',
                },
              },
              'x-stoplight': {
                id: 'qrmut0f6nk9en',
              },
              default: false,
            },
            activityDate: {
              type: 'object',
              'x-entity-label': {
                key: 'ACTIVITY_DURATION',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_DURATION_HINT',
                },
              },
              'x-stoplight': {
                id: 'wjexioxadon81',
              },
              properties: {
                startDate: {
                  type: 'string',
                  'x-entity-label': {
                    key: 'ACTIVITY_START_DATE',
                  },
                  'x-stoplight': {
                    id: '1s6amta0y4n3y',
                  },
                  format: 'date',
                  description: 'Start date of an activity.',
                },
                endDate: {
                  type: 'string',
                  'x-entity-label': {
                    key: 'ACTIVITY_END_DATE',
                  },
                  'x-stoplight': {
                    id: 'yb5jie35tcf12',
                  },
                  format: 'date',
                  description: 'End date of an activity.',
                },
              },
            },
            eligibilityCriteria: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_ELIGIBILITY_CRITERIA',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_ELIGIBILITY_CRITERIA_HINT',
                },
                rulesTemplateType: 'eligibilityCriteria',
              },
              'x-stoplight': {
                id: 'evhxrc4nz2qfa',
              },
              format: 'json-logic',
              description: 'Rules for eligibility for this activity.',
            },
          },
        },
        completionData: {
          type: 'object',
          'x-entity-label': {
            key: 'ACTIVITY_COMPLETION_DATA',
          },
          'x-entity-presentation': {
            hint: {
              key: 'ACTIVITY_COMPLETION_HINT',
            },
            banner: {
              title: {
                key: 'ACTIVITY_COMPLETION_BANNER_TITLE',
              },
              description: {
                key: 'ACTIVITY_COMPLETION_BANNER_DESCRIPTION',
              },
              status: 'warning',
            },
          },
          'x-stoplight': {
            id: 'non0ocoqrwi2t',
          },
          properties: {
            completionCriteria: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_COMPLETION_CRITERIA',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_COMPLETION_CRITERIA_HINT',
                },
              },
              'x-stoplight': {
                id: 'evhxrc4nz2qfa',
              },
              format: 'json-logic',
              description: 'Rules for completing an activity.',
            },
            loopbackStartDate: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_LOOK_BACK_START_DATE',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_LOOK_BACK_START_DATE_HINT',
                },
              },
              'x-stoplight': {
                id: 'khfvio1m4r7a7',
              },
              format: 'date',
              description:
                'Eligibility start date for completing a no-touch activity.',
            },
            loopbackEndDate: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_LOOK_BACK_END_DATE',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_LOOK_BACK_END_DATE_HINT',
                },
              },
              'x-stoplight': {
                id: 'llcpcdpp351hl',
              },
              format: 'date',
              description:
                'Eligibility end date for completing a no-touch activity.',
            },
            visibilityEndDate: {
              type: 'boolean',
              'x-entity-label': {
                key: 'ACTIVITY_VISIBILITY_END_DATE',
              },
              'x-stoplight': {
                id: '6i0k46ehva0db',
              },
              description: 'Expire by Visible End Date.',
            },
            eventId: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_EVENT_ID',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_EVENT_ID_HINT',
                },
              },
              'x-stoplight': {
                id: 'eqsarh3o0velw',
              },
              description:
                'Identifiers for the events that mark this Activity as complete. All must be triggered.',
            },
            campaignTypeTrigger: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_CAMPAIGN_TYPE_TRIGGER',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_CAMPAIGN_TYPE_TRIGGER_HINT',
                },
                enumLabels: {
                  enumControlPlaceholder:
                    'ACTIVITY_CAMPAIGN_TYPE_TRIGGER_PLACEHOLDER',
                  'opt-in': 'ACTIVITY_OPT_IN',
                  challenge: 'CHALLENGE',
                },
              },
              'x-stoplight': {
                id: 'wlzexn47fz5mh',
              },
              enum: ['opt-in', 'challenge'],
              description:
                'This field is used to specify the type of campaign that triggers the no-touch activity.',
            },
            triggerCampaignId: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_TRIGGER_CAMPAIGN_ID',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_TRIGGER_CAMPAIGN_ID_HINT',
                },
              },
              'x-stoplight': {
                id: 'b4c91v6lww0b7',
              },
              description:
                'If there is a specific Campaign that triggers the No-touch completion criteria, link to it here.',
            },
          },
        },
        rewardData: {
          type: 'object',
          'x-entity-label': {
            key: 'ACTIVITY_REWARDS_DATA',
          },
          'x-stoplight': {
            id: 'ptczsxnd2mji6',
          },
          required: ['points', 'unit', 'badge'],
          properties: {
            points: {
              type: 'integer',
              'x-entity-label': {
                key: 'ACTIVITY_POINTS',
              },
              'x-stoplight': {
                id: '7u25ocxns7uba',
              },
              minimum: 0,
              description: 'Awarded Currency on Completion.',
            },
            rewardCriteria: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_REWARD_CRITERIA',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'ACTIVITY_REWARD_CRITERIA_HINT',
                },
              },
              'x-stoplight': {
                id: 'u9x7up4gw2sz3',
              },
              format: 'json-logic',
            },
            unit: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_UNIT',
              },
              'x-stoplight': {
                id: '6e5tmd0vwotmk',
              },
            },
            badge: {
              type: 'string',
              'x-entity-label': {
                key: 'ACTIVITY_BADGE',
              },
              'x-stoplight': {
                id: 'wb9kiud9hkdqu',
              },
              format: 'uri-reference',
            },
          },
        },
      },
      required: [
        'entityMetadata',
        'metaData',
        'cardData',
        'contentData',
        'rewardData',
      ],
    },
  },
  required: ['type'],
  'x-tags': ['Config-Activity'],
};

export const mockCampaignCreateSchema = {
  title: 'ConfigCampaignRequest',
  'x-stoplight': {
    id: 'tn0npq1b63rzj',
  },
  type: 'object',
  description: "Model for creating a campaign and it's related information.",
  'x-tags': [' Config-Campaign'],
  properties: {
    type: {
      type: 'string',
      'x-stoplight': {
        id: 'd5e67dw6qhk8e',
      },
      enum: ['config-campaign'],
    },
    attributes: {
      title: 'ConfigCampaignAttributes',
      'x-stoplight': {
        id: '5yhia1fpx9aqa',
      },
      'x-entity-label': {
        key: 'CAMPAIGN_ENTITY_NAME',
      },
      type: 'object',
      description: 'Model with config campaign attribute information.',
      'x-tags': [' Config-Campaign'],
      properties: {
        entityMetadata: {
          type: 'object',
          'x-entity-presentation': {
            hidden: true,
          },
          'x-stoplight': {
            id: 'kugiiatjwkgcd',
          },
          required: ['status'],
          description:
            'Entity Related Meta Data. Mostly fields that cannot be updated from the Front End.',
          properties: {
            status: {
              type: 'string',
              'x-stoplight': {
                id: 'de10q2crvdcrw',
              },
              enum: ['draft', 'published'],
              description: 'status of campaign in config tools.\n',
            },
            lastPublishedDate: {
              type: 'string',
              'x-stoplight': {
                id: '68kkljrnotgl8',
              },
              format: 'date-time',
              description: 'Date and time campaign was last published.',
            },
            lastUpdatedDate: {
              type: 'string',
              'x-stoplight': {
                id: 'pic5sm0bt23ci',
              },
              format: 'date-time',
              description: 'date and time campaign was last update.',
            },
          },
        },
        metaData: {
          'x-entity-label': {
            key: 'CAMPAIGN_METADATA_LABEL',
          },
          type: 'object',
          'x-stoplight': {
            id: 'aanxhk7qkfykj',
          },
          properties: {
            internalName: {
              type: 'string',
              'x-stoplight': {
                id: 'eo0iog6894tvy',
              },
              description: 'Searchable within content auth, internal name.',
              'x-entity-label': {
                key: 'CAMPAIGN_METADATA_INTERNAL_NAME_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_METADATA_INTERNAL_NAME_HINT',
                },
              },
            },
            type: {
              type: 'string',
              'x-stoplight': {
                id: 'ru2gxk31ib8na',
              },
              enum: ['challenge', 'auto-assigned', 'reward', 'opt-in'],
              description: 'Defines what type of campaign this is.',
              'x-entity-label': {
                key: 'CAMPAIGN_METADATA_TYPE_LABEL',
              },
            },
            externalId: {
              type: 'string',
              'x-stoplight': {
                id: 'vjpz5h9ssnc4o',
              },
              description:
                'Whether the campaign is used as Blank template for Care Advisor to assign activities to a member, default is False. If it is True, this campaign will be displayed at the top of campaigns available to the Care Advisors for assignment purposes.',
              'x-entity-label': {
                key: 'CAMPAIGN_METADATA_EXTERNAL_ID_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_METADATA_EXTERNAL_ID_HINT',
                },
              },
            },
            campaignDate: {
              type: 'object',
              'x-stoplight': {
                id: 'mqxc9zprnnyg2',
              },
              'x-entity-label': {
                key: 'CAMPAIGN_DATE_LABEL',
              },
              properties: {
                startDate: {
                  type: 'string',
                  'x-stoplight': {
                    id: '4w7rh4jcn7574',
                  },
                  format: 'date',
                  description:
                    'If empty, then users are allowed to participate in the campaign anytime this is live.',
                  'x-entity-label': {
                    key: 'CAMPAIGN_DATE_START_DATE_LABEL',
                  },
                },
                endDate: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'hwoygc6be4j4f',
                  },
                  format: 'date',
                  description:
                    'If empty, then users are allowed to participate in the campaign never expires.',
                  'x-entity-label': {
                    key: 'CAMPAIGN_DATE_END_DATE_LABEL',
                  },
                },
                visibilityEndDate: {
                  type: 'string',
                  'x-stoplight': {
                    id: '707w5jhueqrpq',
                  },
                  format: 'date',
                  description:
                    'The date in which the program is no longer visible. Eg. the program can be visible after the end date for a period of time',
                  'x-entity-presentation': {
                    hint: {
                      key: 'CAMPAIGN_DATE_VISIBILITY_END_DATE_HINT',
                    },
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_DATE_VISIBILITY_END_DATE_LABEL',
                  },
                },
              },
            },
          },
          required: ['internalName', 'type'],
        },
        contentData: {
          type: 'object',
          'x-stoplight': {
            id: '9axsd0fr0v0j9',
          },
          'x-entity-label': {
            key: 'CAMPAIGN_CONTENT_LABEL',
          },
          properties: {
            title: {
              type: 'string',
              'x-stoplight': {
                id: 'ocsyd7rfla1u0',
              },
              description: 'This is the title that the user sees.',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_TITLE_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_TITLE_HINT',
                },
              },
            },
            programHeader: {
              type: 'string',
              'x-stoplight': {
                id: 're24c19trl28c',
              },
              description:
                'A badge that appears above the title of the program.',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_PROGRAM_HEADER_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_PROGRAM_HEADER_HINT',
                },
              },
            },
            shortDescription: {
              type: 'string',
              'x-stoplight': {
                id: 'ejz148oargvof',
              },
              description:
                'A short summary of campaign. Ensure characters do not exceed 150 character limit.',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_SHORT_DESCRIPTION_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_SHORT_DESCRIPTION_HINT',
                },
              },
            },
            longDescription: {
              type: 'string',
              'x-stoplight': {
                id: 'i3dghr6s8i683',
              },
              description:
                'Long description of campaign. Ensure characters do not exceed 600 character limit.',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_LONG_DESCRIPTION_LABEL',
              },
            },
            overview: {
              type: 'string',
              'x-stoplight': {
                id: 'klbm4427k5t1i',
              },
              format: 'rich-text',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_OVERVIEW_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_OVERVIEW_HINT',
                },
              },
            },
            campaignImageId: {
              type: 'string',
              'x-stoplight': {
                id: '5jm85oemj5q32',
              },
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_CAMPAIGN_IMAGE_ID_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_CAMPAIGN_IMAGE_ID_HINT',
                },
              },
              'x-entity-asset': {
                type: 'image',
              },
            },
            achievement: {
              type: 'object',
              'x-stoplight': {
                id: '06904nbyx6czz',
              },
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_HINT',
                },
              },
              properties: {
                achievementTitle: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'tgqc1l0792kui',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_TITLE_LABEL',
                  },
                },
                achievementText: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'ebgyozb92z8g1',
                  },
                  format: 'rich-text',
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_TEXT_LABEL',
                  },
                },
                achievementImageId: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'eozdxk18l6xl1',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_IMAGE_LABEL',
                  },
                  'x-entity-asset': {
                    type: 'image',
                  },
                },
              },
            },
            globalProgress: {
              type: 'boolean',
              'x-stoplight': {
                id: 'pidombm7vwltn',
              },
              default: false,
              description:
                'Determines if a progress bar is displayed in the Campaign   Listing.',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_GLOBAL_PROGRESS_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_ACHIEVEMENT_GLOBAL_PROGRESS_HINT',
                },
              },
            },
            disclaimerBanner: {
              type: 'object',
              'x-stoplight': {
                id: 'wdjbzmq42e80s',
              },
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_DISCLAIMER_BANNER',
              },
              properties: {
                title: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'glnc2qcsbj449',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_DISCLAIMER_BANNER_TITLE_LABEL',
                  },
                },
                description: {
                  type: 'string',
                  'x-stoplight': {
                    id: '6vdfk47yjpnmh',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_DISCLAIMER_BANNER_DESCRIPTION_LABEL',
                  },
                },
                iconImageId: {
                  type: 'string',
                  'x-stoplight': {
                    id: '9z7z386cmm5kt',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_DISCLAIMER_BANNER_ICON_IMAGE_ID_LABEL',
                  },
                  'x-entity-asset': {
                    type: 'image',
                  },
                },
              },
            },
            programRulesText: {
              type: 'string',
              'x-stoplight': {
                id: 'u7p7wazcxnyzz',
              },
              format: 'rich-text',
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_PROGRAM_RULES_LABEL',
              },
            },
            contentProvider: {
              type: 'object',
              'x-stoplight': {
                id: 'n16sq4qt685kr',
              },
              'x-entity-label': {
                key: 'CAMPAIGN_CONTENT_CONTENT_PROVIDER_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_CONTENT_CONTENT_PROVIDER_HINT',
                },
              },
              properties: {
                name: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'lyxgzt8bscugw',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_CONTENT_PROVIDER_NAME_LABEL',
                  },
                },
                description: {
                  type: 'string',
                  'x-stoplight': {
                    id: '706facub4cbsm',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_CONTENT_PROVIDER_DESCRIPTION_LABEL',
                  },
                },
                logoMarkImageId: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'uq1s7qgct58gf',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_CONTENT_PROVIDER_LOGOTYPE_IMAGE_ID_LABEL',
                  },
                  'x-entity-asset': {
                    type: 'image',
                  },
                },
                logoTypeImageId: {
                  type: 'string',
                  'x-stoplight': {
                    id: '7dnuqknjz74wf',
                  },
                  'x-entity-label': {
                    key: 'CAMPAIGN_CONTENT_CONTENT_PROVIDER_LOGOMARK_IMAGE_ID_LABEL',
                  },
                  'x-entity-asset': {
                    type: 'image',
                  },
                },
              },
            },
          },
          required: ['title', 'overview', 'campaignImageId'],
        },
        enrolmentData: {
          type: 'object',
          'x-stoplight': {
            id: '9ud9o257mgxsv',
          },
          'x-entity-label': {
            key: 'CAMPAIGN_ENROLMENT_LABEL',
          },
          'x-entity-inject-reference': {
            relationship: 'campaigns',
            labelKey: 'CAMPAIGN_ENROLMENT_CHILD_CAMPAIGNS_LABEL',
            arrayAddLabel: 'CAMPAIGN_ENROLMENT_CHILD_CAMPAIGNS_ADD_LABEL',
            hint: {
              key: 'CAMPAIGN_ENROLMENT_CHILD_CAMPAIGNS_HINT',
            },
          },
          properties: {
            eligibilityCriteria: {
              type: 'string',
              'x-stoplight': {
                id: 'n1bh74r6gq8m3',
              },
              description: 'If empty, everyone is eligible.',
              format: 'json-logic',
              'x-entity-label': {
                key: 'CAMPAIGN_ENROLMENT_ELIGIBILITY_CRITERIA_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_ENROLMENT_ELIGIBILITY_CRITERIA_HINT',
                },
              },
            },
            enrolmentMethod: {
              type: 'string',
              'x-stoplight': {
                id: '5ud891nkni9aq',
              },
              enum: ['opt-in', 'auto-enrolled', 'care-team-member'],
              description:
                'Represents the type of individual who makes the request to enroll the user into the campaign. care_team_member: Care Advisor is assigning/editing this campaign. league_system: Members are auto enrolled into those campaigns - suggested campaigns and reward programs.',
              'x-entity-label': {
                key: 'CAMPAIGN_ENROLMENT_ENROLMENT_METHOD_LABEL',
              },
            },
            enrolment: {
              type: 'object',
              'x-stoplight': {
                id: 'dyytwkmzaf8l4',
              },
              'x-entity-label': {
                key: 'CAMPAIGN_ENROLMENT_ENROLMENT_DURATION_LABEL',
              },
              properties: {
                startDate: {
                  type: 'string',
                  'x-stoplight': {
                    id: '8bdge921g6013',
                  },
                  format: 'date',
                  description:
                    'Date user can start enrolment. If empty, then users are allowed to enroll anytime this is live.',
                  'x-entity-label': {
                    key: 'CAMPAIGN_ENROLMENT_ENROLMENT_DURATION_START_DATE_LABEL',
                  },
                },
                endDate: {
                  type: 'string',
                  'x-stoplight': {
                    id: 'gdjvym7ealsel',
                  },
                  format: 'date',
                  description: 'End date for user enrolment.',
                  'x-entity-label': {
                    key: 'CAMPAIGN_ENROLMENT_ENROLMENT_DURATION_END_DATE_LABEL',
                  },
                },
              },
            },
            frontLineCampaign: {
              type: 'boolean',
              'x-stoplight': {
                id: 'ndwmnzoyjopvj',
              },
              default: false,
              description:
                'Determines if this Campaign will take priority over other recommended campaigns.',
              'x-entity-label': {
                key: 'CAMPAIGN_ENROLMENT_FRONT_LINE_CAMPAIGN_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_ENROLMENT_FRONT_LINE_CAMPAIGN_HINT',
                },
              },
            },
            rootCampaign: {
              type: 'boolean',
              'x-stoplight': {
                id: 'u1kuunji033gm',
              },
              default: false,
              'x-entity-label': {
                key: 'CAMPAIGN_ENROLMENT_ROOT_CAMPAIGN_LABEL',
              },
            },
          },
          required: ['enrolmentMethod'],
        },
        completionData: {
          type: 'object',
          'x-stoplight': {
            id: 'sx0p7yygz20l0',
          },
          'x-entity-label': {
            key: 'CAMPAIGN_COMPLETION_LABEL',
          },
          properties: {
            completionCriteria: {
              type: 'string',
              'x-stoplight': {
                id: 'eyio3an77jkt9',
              },
              description:
                'If empty, users need to complete all items within the campaign.',
              format: 'json-logic',
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_COMPLETION_CRITERIA_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_COMPLETION_CRITERIA_HINT',
                },
              },
            },
            maxRedemptionPoint: {
              type: 'integer',
              'x-stoplight': {
                id: 'cfiflyr689ke7',
              },
              minimum: 0,
              description:
                'Determines the maximum number of points that will be rewarded for all child Campaigns and Activities.',
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_MAX_REDEMPTION_POINTS_HINT',
                },
              },
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_MAX_REDEMPTION_POINTS_LABEL',
              },
            },
            rewardProgramGoal: {
              type: 'integer',
              'x-stoplight': {
                id: 'md1v5tss8kndb',
              },
              minimum: 0,
              description:
                'If this campaign is eligible for rewards programs, specify the number of activities in the campaign that a user must complete in order to earn reward points.',
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_REWARD_PROGRAM_GOAL_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_REWARD_PROGRAM_GOAL_HINT',
                },
              },
            },
            programCompletionTitle: {
              type: 'string',
              'x-stoplight': {
                id: '3fu5b861ew3zn',
              },
              description:
                'Title to display on card when the Campaign is completed.',
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_PROGRAM_COMPLETION_TITLE_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_PROGRAM_COMPLETION_TITLE_HINT',
                },
              },
            },
            completionMessage: {
              type: 'string',
              'x-stoplight': {
                id: '5qgah6rguv0kc',
              },
              format: 'rich-text',
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_COMPLETION_MESSAGE_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_COMPLETION_MESSAGE_HINT',
                },
              },
            },
            programEndedTitle: {
              type: 'string',
              'x-stoplight': {
                id: 'tepv4q8y4k1pj',
              },
              description:
                'Title to display on card when the Campaign has ended.',
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_PROGRAM_ENDED_TITLE_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_PROGRAM_ENDED_TITLE_HINT',
                },
              },
            },
            endedMessage: {
              type: 'string',
              'x-stoplight': {
                id: 'xdfzkg0jrmm04',
              },
              format: 'rich-text',
              'x-entity-label': {
                key: 'CAMPAIGN_COMPLETION_ENDED_MESSAGE_LABEL',
              },
              'x-entity-presentation': {
                hint: {
                  key: 'CAMPAIGN_COMPLETION_ENDED_MESSAGE_HINT',
                },
              },
            },
          },
          required: ['programCompletionTitle', 'programEndedTitle'],
        },
        campaignNodes: {
          type: 'array',
          'x-stoplight': {
            id: 'hlspmia1czpbq',
          },
          'x-entity-label': {
            key: 'CAMPAIGN_CAMPAIGN_NODES_LABEL',
          },
          items: {
            title: 'ConfigCampaignNode',
            'x-stoplight': {
              id: 'xxuiv58xd6wve',
            },
            type: 'object',
            'x-tags': ['Config-Campaign'],
            'x-entity-label': {
              key: 'CAMPAIGN_CAMPAIGN_NODE_LABEL',
              propertyRef: '$.nodeName',
            },
            description:
              'Model with config campaign activity node information.',
            properties: {
              nodeName: {
                type: 'string',
                'x-stoplight': {
                  id: '3stq0fyeib3s8',
                },
                'x-entity-label': {
                  key: 'CAMPAIGN_CAMPAIGN_NODES_NODE_NAME_LABEL',
                },
              },
              offset: {
                type: 'integer',
                'x-stoplight': {
                  id: 'r52qfg3mwsr04',
                },
                minimum: 0,
                'x-entity-label': {
                  key: 'CAMPAIGN_CAMPAIGN_NODES_OFFSET_LABEL',
                },
              },
              activities: {
                type: 'array',
                'x-stoplight': {
                  id: 'wr9k30qaimdw2',
                },
                'x-entity-label': {
                  key: 'CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_LABEL',
                },
                items: {
                  title: 'ConfigCampaignNodeActivity',
                  'x-stoplight': {
                    id: 'aoa347jl5ghte',
                  },
                  type: 'object',
                  'x-entity-label': {
                    key: 'ACTIVITY',
                  },
                  description:
                    'Model with config campaign activity information.',
                  properties: {
                    id: {
                      type: 'string',
                      'x-entity-label': {
                        key: 'CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_ACTIVITY_ID_LABEL',
                      },
                      'x-entity-reference': {
                        relationship: 'activity',
                      },
                    },
                    canExpire: {
                      type: 'boolean',
                      'x-stoplight': {
                        id: '4re5eiqrzstlz',
                      },
                      default: false,
                      'x-entity-label': {
                        key: 'CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_CAN_EXPIRE_LABEL',
                      },
                    },
                    expireIn: {
                      type: 'integer',
                      'x-stoplight': {
                        id: '4dzghkmvi1p63',
                      },
                      minimum: 1,
                      description:
                        'Any integer from 1 to 365 is allowed. 1 means in 1 Day.',
                      'x-entity-label': {
                        key: 'CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_EXPIRE_IN_LABEL',
                      },
                      'x-entity-presentation': {
                        hint: {
                          key: 'CAMPAIGN_CAMPAIGN_NODES_ACTIVITIES_EXPIRE_IN_HINT',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      required: ['entityMetadata'],
    },
    relationships: {
      title: 'ConfigCampaignRelationships',
      'x-stoplight': {
        id: 'ftcdn2je5v0ms',
      },
      type: 'object',
      'x-tags': ['Config-Campaign'],
      properties: {
        activity: {
          type: 'object',
          'x-stoplight': {
            id: '8l96n9w8kd0j6',
          },
          properties: {
            data: {
              type: 'array',
              'x-stoplight': {
                id: 'ec9cmqx1lk967',
              },
              items: {
                'x-stoplight': {
                  id: 'vqazizmby8ni4',
                },
                type: 'object',
                properties: {
                  id: {
                    type: 'string',
                    'x-stoplight': {
                      id: '4jjmk9fy9a32t',
                    },
                  },
                  type: {
                    type: 'string',
                    'x-stoplight': {
                      id: 'f3exkvuh2m2gh',
                    },
                    enum: ['config-activity'],
                  },
                },
              },
            },
          },
        },
        campaigns: {
          type: 'object',
          'x-stoplight': {
            id: '8l96n9w8kd0j6',
          },
          properties: {
            data: {
              type: 'array',
              'x-stoplight': {
                id: 'ec9cmqx1lk967',
              },
              items: {
                'x-stoplight': {
                  id: 'vqazizmby8ni4',
                },
                type: 'object',
                properties: {
                  id: {
                    type: 'string',
                    'x-stoplight': {
                      id: '4jjmk9fy9a32t',
                    },
                  },
                  type: {
                    type: 'string',
                    'x-stoplight': {
                      id: 'f3exkvuh2m2gh',
                    },
                    enum: ['config-campaign'],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  required: ['type'],
};
