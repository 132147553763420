import { keyframes } from '@leagueplatform/genesis-core';

export const TOP_BAR_LINEAR_GRADIENT =
  'linear-gradient(to bottom, #FFFFFFCC, transparent)';

const slideInFromTop = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-100%)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

export const bannerAnimation = `${slideInFromTop} $transitions$defaultTime ease-in`;
