import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { isCombinatorObjectSchema } from '../is-combinator-object-schema/is-combinator-object-schema';
/*
Ensures the schema is an object and has properties.
Verifies that each property's type is present in your primitiveTypes list.
If a property is an array, it checks if its item's type is a valid primitive.
*/

/*
Using Set for the `.has()` method, a very quick way to check if something exists within the set https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/has
*/

const primitiveTypeSet = new Set(['string', 'number', 'boolean', 'integer']);

export const isPrimitiveObjectSchema = (
  schema: AnnotatedJsonSchema,
): boolean => {
  if (
    schema.type !== 'object' ||
    !schema.properties ||
    isCombinatorObjectSchema(schema)
  ) {
    return false;
  }

  return Object.values(schema.properties).every((property) => {
    if (primitiveTypeSet.has(property.type)) {
      return true;
    }
    if (property.type === 'array') {
      if (!property.items) return false;

      return property.items.type && primitiveTypeSet.has(property.items.type);
    }
    return false;
  });
};
