import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  Box,
  Button,
  Icon,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  formatBlockElement,
  getActiveBlockType,
} from '../../../utils/format-helpers.util';
import { toolbarDropdownCss } from './block-element-dropdown-richtext-toolbar.css';
import { UpDownIcon } from '../../../toolbar-icons';
import {
  BlockElementType,
  blockElementToName,
  ToolbarProps,
} from '../../../rich-text-editor.types';

export const BlockElementDropdownRichtextToolbar = ({
  editor,
  readOnly,
}: ToolbarProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [currentBlockElement, setCurrentBlockElement] =
    React.useState<BlockElementType>('paragraph');

  const updateCurrentBlockElement = React.useCallback(() => {
    /*
     * if activeBlockType is undefined (this will happen when multiple types * of block elements are highlighted at once)
     * set block type to be 'paragraph' by default (so that "Normal will appear as text in the selected drop down item")
     */
    const newBlockType = getActiveBlockType(editor) || 'paragraph';
    setCurrentBlockElement(newBlockType);
  }, [editor]);

  React.useEffect(() => {
    editor?.on('selectionUpdate', () => {
      updateCurrentBlockElement();
    });
    return () => {
      editor?.off('selectionUpdate', updateCurrentBlockElement);
    };
  }, [editor, updateCurrentBlockElement]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          size="small"
          quiet
          disabled={readOnly}
          priority="tertiary"
          css={{
            span: {
              display: 'flex',
            },
          }}
        >
          <VisuallyHidden>
            {formatMessage(
              { id: 'STYLES_LIST' },
              {
                selectedBlockElement: formatMessage({
                  id: blockElementToName[currentBlockElement],
                }),
              },
            )}
          </VisuallyHidden>
          <Box as="span" aria-hidden>
            {formatMessage({ id: blockElementToName[currentBlockElement] })}
          </Box>
          <Icon
            css={{
              display: 'inline-flex',
              marginLeft: '$one',
            }}
            size="1rem"
            icon={<UpDownIcon />}
          />
        </Button>
      </DropdownMenu.Trigger>
      <Box
        as={DropdownMenu.Content}
        css={toolbarDropdownCss}
        className="richtext-dropdown-content"
      >
        <DropdownMenu.RadioGroup
          className="richtext-dropdown-radiogroup"
          value={currentBlockElement}
          onValueChange={(value: string) => {
            // the value will always be BlockElementType but Radix expects string in the onValueChange function
            setCurrentBlockElement(value as BlockElementType);
            formatBlockElement({
              newBlockElement: value as BlockElementType,
              editor,
            });
          }}
        >
          {Object.entries(blockElementToName).map(([key, value]) => (
            <DropdownMenu.RadioItem
              value={key}
              key={key}
              className="richtext-dropdown-item"
            >
              {formatMessage({ id: value })}
              <DropdownMenu.ItemIndicator className="richtext-dropdown-item-indicator">
                <Icon
                  icon="interfaceCheckmark"
                  css={{ marginLeft: '$oneAndHalf' }}
                  size="1rem"
                />
              </DropdownMenu.ItemIndicator>
            </DropdownMenu.RadioItem>
          ))}
        </DropdownMenu.RadioGroup>
      </Box>
    </DropdownMenu.Root>
  );
};
