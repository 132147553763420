import * as React from 'react';

/**
 * A formatter function that takes a string in the format "href|label" and renders an <a> tag with the left side | as the href value.
 * A relative or absolute path can be used.
 *
 * This formatter can then be used within the formatMessage function to format tags within the translation strings via [Format.JS Rich Text Formatting](https://formatjs.github.io/docs/core-concepts/icu-syntax/#rich-text-formatting)
 * @example `formatMessage({ id }, values ? { ...values, a: linkValueFormatter } : {})`
 * - would format translation strings that contain text wrapped in a <a> </a>
 * - the `chunk` that is before the `|` should be an [ICU Simple Argument](https://formatjs.github.io/docs/core-concepts/icu-syntax/#simple-argument) `{key}`, which is passed as a value.
 *
 * @param chunk - An array containing a single string in the format "href|label" or any other value.
 * @returns An InternalOrExternalLink component if the input is a valid string, otherwise returns the input chunk.
 *
 * @example
 * const input = "https://www.example.com/|Example Link";
 * const output = linkValueFormatter([input]); // Renders <a href="https://www.example.com/">Example Link</a>
 *
 */
export const linkValueFormatter: (
  chunks: React.ReactNode[],
) => string | undefined = ([chunk]) => {
  if (typeof chunk === 'string') {
    const [href, label] = chunk.split('|');
    return `<a href=${href}>${label}</a>`;
  }
  return undefined;
};
