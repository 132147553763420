import type {
  AnnotatedJsonSchema,
  SchemaTransformerArgs,
} from '@web-config-app/core';
import { isPrimitiveArraySchema } from '../../is-primitive-array-schema/is-primitive-array-schema';
import { isPrimitiveObjectSchema } from '../../is-primitive-object-schema/is-primitive-object-schema';
import { isCombinatorObjectSchema } from '../../is-combinator-object-schema/is-combinator-object-schema';
import { getSchemaWithMutatedProperties } from '../../get-schema-with-mutated-properties/get-schema-with-mutated-properties';
import { isArrayOrObjectSchema } from '../../is-array-or-object-schema/is-array-or-object-schema';

/**
 * Annotates any schema properties that are complex enough to render in a deeper
 * form level with the `x-entity-control` annotation with type `objectCard`
 */

export const addObjectCardAnnotation = ({
  schema: computedSchema,
  options,
}: SchemaTransformerArgs) => {
  const schema = { ...(options?.originalSchema ?? computedSchema) };

  if (schema.type !== 'object') {
    return schema;
  }

  return getSchemaWithMutatedProperties({
    schema,
    mutateProperties: (propertySchema: AnnotatedJsonSchema) => {
      const isObjectCardType =
        isArrayOrObjectSchema(propertySchema) &&
        ((propertySchema.type === 'array' &&
          !isPrimitiveArraySchema(propertySchema)) ||
          (propertySchema.type === 'object' &&
            !isPrimitiveObjectSchema(propertySchema) &&
            !isCombinatorObjectSchema(propertySchema)));

      return isObjectCardType
        ? {
            ...propertySchema,
            'x-entity-control': { type: 'objectCard' },
          }
        : propertySchema;
    },
  }) as AnnotatedJsonSchema;
};
