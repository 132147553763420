import * as React from 'react';
import {
  TextAction,
  Box,
  Icon,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { NavLink } from '@leagueplatform/routing';
import { DomainConfig, EntityConfig } from '@web-config-app/core';

const hoverStyles = {
  backgroundColor: '$inputBackgroundHovered',
  textDecoration: 'none',
};

const currentPageStyles = {
  ...hoverStyles,
  // this after element is used for the right border of the nav item when it is active  as "border-right" styles on their own didn't quite match the designs.
  '&::after': {
    backgroundColor: '$interactiveActionPrimary',
    borderRadius: '0px $medium $medium 0px',
    content: '',
    height: '100%',
    position: 'absolute',
    right: '0',
    width: '3px',
  },
};

const navStyles = {
  borderRadius: '$medium',
  padding: '$half',
  width: '100%',
  '&:focus': {
    zIndex: 1,
  },
  '&:hover': hoverStyles,
  '&[aria-current=page]': currentPageStyles,
};

type EntityNavItemProps = {
  name: string;
  entityPath: string;
};

export interface EntityConfigWithPath extends EntityConfig {
  path: string;
}

export type DomainNavItemProps = {
  domainPath: string;
  isActive: boolean;
} & Omit<DomainConfig, 'id' | 'category' | 'entities'> & {
    entities: EntityConfigWithPath[];
  };

export const EntityNavItem = ({ name, entityPath }: EntityNavItemProps) => (
  <Box as="li">
    <TextAction
      as={NavLink}
      to={entityPath}
      emphasis="subtle"
      size="medium"
      css={{ ...navStyles, paddingLeft: '$three' }}
    >
      {name}
    </TextAction>
  </Box>
);

export const DomainNavItem = ({
  name,
  entities,
  icon,
  domainPath,
  isActive,
}: DomainNavItemProps) => (
  <StackLayout as="li" horizontalAlignment="stretch" spacing="$quarter">
    <TextAction
      as={NavLink}
      to={domainPath}
      emphasis="subtle"
      size="large"
      exact
      css={navStyles}
    >
      <StackLayout orientation="horizontal" spacing="$threeQuarters">
        <Icon icon={icon} />
        {name}
      </StackLayout>
    </TextAction>
    {isActive && (
      <StackLayout as="ul" horizontalAlignment="stretch" spacing="$quarter">
        {entities.map(
          ({ name: entityName, id: entityId, path: entityPath }) => (
            <EntityNavItem
              name={entityName}
              entityPath={entityPath}
              key={entityId}
            />
          ),
        )}
      </StackLayout>
    )}
  </StackLayout>
);
