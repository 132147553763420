import React from 'react';
import { Tooltip } from '@leagueplatform/genesis-core';
import { SelectActionMenuItemContents } from '../select-action-menu-item-contents/select-action-menu-item-contents.component';
import { SelectMenuItemProps } from '../../../types/components.types';

/**
 * Tooltip wrapper for SelectActionMenuItemContents component - renders a single menu item within the SelectActionMenu component with an optional disabledTooltip
 *
 * @param {React.ReactNode} [children] The contents of the menu item
 * @param {boolean} [showDivider=false] - Whether to display a divider below the menu item.
 * @param {boolean} [selected=false] - Whether the menu item is currently selected.
 * @param {string} [color] - GDSColor color to override default of '$onSurfaceTextPrimary'
 * @param {string} [disabledTooltip] - A message to display as a Tooltip when the item is hovered. this also disables the menuItem
 */

export const SelectActionMenuItem = ({
  children,
  showDivider,
  selected,
  disabledTooltip,
  color = '$onSurfaceTextPrimary',
}: SelectMenuItemProps) =>
  disabledTooltip ? (
    <Tooltip
      align="center"
      avoidCollisions
      content={disabledTooltip}
      side="left"
      sideOffset={30}
    >
      <SelectActionMenuItemContents
        selected={selected}
        showDivider={showDivider}
        disabled={Boolean(disabledTooltip)}
        color={color}
      >
        {children}
      </SelectActionMenuItemContents>
    </Tooltip>
  ) : (
    <SelectActionMenuItemContents
      selected={selected}
      showDivider={showDivider}
      disabled={Boolean(disabledTooltip)}
    >
      {children}
    </SelectActionMenuItemContents>
  );
