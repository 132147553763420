import * as React from 'react';
import {
  Box,
  Icon,
  ParagraphText,
  StackLayout,
  StatusBanner,
  TextAction,
} from '@leagueplatform/genesis-core';
import { Link } from '@leagueplatform/routing';
import { bannerAnimation } from '../../constants';

type ErrorLink = {
  detail: string;
  path?: string;
};

type ErrorBannerProps = {
  title: string;
  onDismissClick: (event: React.MouseEvent) => void;
} & (
  | { errorMessage: string; errorList?: never }
  | { errorMessage?: never; errorList: ErrorLink[] }
);

const ErrorItem: React.FC<ErrorLink> = ({ detail, path }) => (
  <StackLayout orientation="horizontal" verticalAlignment="center">
    {path ? (
      <>
        <TextAction
          as={Link}
          to={`?path=${path}`}
          css={{ typography: '$bodyOne', color: '$onSurfaceTextPrimary' }}
        >
          {detail}
        </TextAction>
        <Icon
          icon="interfaceArrowRight"
          size="$threeQuarters"
          tint="$interactiveActionPrimary"
          css={{ marginLeft: '$half' }}
        />
      </>
    ) : (
      detail
    )}
  </StackLayout>
);

export const ErrorBanner: React.FC<ErrorBannerProps> = ({
  title,
  errorMessage,
  errorList,
  onDismissClick,
}) => (
  <StatusBanner
    status="error"
    data-testid="config-error-banner"
    title={title}
    onDismissClick={onDismissClick}
    css={{
      animation: bannerAnimation,
      transformOrigin: 'top',
    }}
  >
    {errorMessage && <ParagraphText>{errorMessage}</ParagraphText>}
    {errorList && errorList.length > 0 ? (
      <Box
        as="ul"
        css={{
          'li::marker': {
            fontSize: '1rem',
          },
          listStyleType: 'revert',
          marginLeft: '$one',
          marginTop: '$quarter',
          typography: '$bodyOne',
          color: '$onSurfaceTextPrimary',
        }}
      >
        {errorList.map((error, index) => {
          const key = `${error.path}-${index}`;
          return (
            <li key={key}>
              <ErrorItem detail={error.detail} path={error.path} />
            </li>
          );
        })}
      </Box>
    ) : null}
  </StatusBanner>
);
