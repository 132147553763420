import React, { FC, PropsWithChildren } from 'react';
import { Icon, StackLayout, TextAction } from '@leagueplatform/genesis-core';
import { Link } from '@leagueplatform/routing';

type AppHeaderProps = {
  logo: string;
  tenant: string;
  environment: string | undefined;
};

export const AppHeader: FC<PropsWithChildren<AppHeaderProps>> = ({
  logo,
  tenant,
  environment,
  children,
}) => (
  <StackLayout
    as="header"
    orientation="horizontal"
    horizontalAlignment="spaceBetween"
    verticalAlignment="center"
    css={{
      backgroundColor: '$surfaceBackgroundSecondary',
      padding: '$half $oneAndHalf',
      height: '100%',
    }}
  >
    <StackLayout
      orientation="horizontal"
      verticalAlignment="center"
      spacing="$threeQuarters"
    >
      <Icon icon={logo} />
      <TextAction as={Link} to="/" emphasis="subtle" size="large">
        {environment ? `${tenant} / ${environment}` : tenant}
      </TextAction>
    </StackLayout>
    <StackLayout orientation="horizontal" spacing="$one" css={{ flexGrow: 0 }}>
      {children}
    </StackLayout>
  </StackLayout>
);
