import { useMemo } from 'react';
import { Generate } from '@jsonforms/core';
import { EntityFormControlProps } from '../../types/controls';
import { createDefaultValueForSchema } from '../../utils/create-default-value-for-schema/create-default-value-for-schema';

export type UseCombinatorSchemasProps = Pick<
  EntityFormControlProps,
  'combinatorProperties' | 'rootSchema' | 'data'
>;

export const useCombinatorSchemas = ({
  combinatorProperties,
  rootSchema,
  data,
}: UseCombinatorSchemasProps) =>
  useMemo(() => {
    if (!combinatorProperties) {
      return {};
    }

    const { combinatorSubSchemaMap, combinatorDiscriminator } =
      combinatorProperties;
    const discriminatorValue = data?.[combinatorDiscriminator];
    const selectedSubSchema = combinatorSubSchemaMap[discriminatorValue];
    const createSelectedSubSchemaValue = (discriminator: string) => {
      const createSubSchema = combinatorSubSchemaMap[discriminator];
      return createDefaultValueForSchema(createSubSchema);
    };

    if (discriminatorValue && !selectedSubSchema) {
      console.error(
        `No subschema associated with option ${discriminatorValue}`,
      );
    }

    return {
      selectedSubSchema,
      createSelectedSubSchemaValue,
      detailUiSchema: Generate.uiSchema(
        selectedSubSchema,
        'Group',
        undefined,
        rootSchema,
      ),
    };
  }, [combinatorProperties, data, rootSchema]);
