import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { isProduction } from '@leagueplatform/core';
import { EntityDetailsHeader } from '@web-config-app/core-react-ui';
import {
  useEntityDetailsProps,
  useEntityWorkflowActions,
  useEntityHeaderSaveStatus,
  EntityMenuItem,
} from '@web-config-app/entity-form';
import {
  getEntityStatusBadgeProps,
  EntityDetailAction,
  EntityStatusValue,
} from '@web-config-app/core';
import type { AuthoringEnvironmentId, Entity } from '@web-config-app/core';
import { useEntityDetailsEnvironmentSelect } from '@web-config-app/core-react';
import type {
  GetEntityDetailsPathForEnvAndId,
  GetEntityCreatePathForEnv,
} from '@web-config-app/core-react';

export const EntityDetailsHeaderContainer = ({
  isLoading = false,
  entity: { nameTranslationKey },
  entityListPath,
  environmentId,
  getEntityDetailsPathForEnvAndId,
  getEntityCreatePathForEnv,
}: {
  isLoading: boolean;
  entity: Entity;
  entityListPath: string;
  environmentId: AuthoringEnvironmentId;
  getEntityDetailsPathForEnvAndId: GetEntityDetailsPathForEnvAndId;
  getEntityCreatePathForEnv: GetEntityCreatePathForEnv;
}) => {
  const { formatMessage } = useIntl();

  const entityConfigName = formatMessage(
    { id: nameTranslationKey },
    { count: 1 },
  );

  const { operations, name, status, instanceId, entityRootData } =
    useEntityDetailsProps();
  const {
    currentEnvironmentId,
    availableEnvironments,
    multiEnvironmentAuthoringEnabled,
  } = useEntityDetailsEnvironmentSelect({
    status,
    getEntityDetailsPathForEnvAndId,
    getEntityCreatePathForEnv,
    environmentId,
    entityInstanceId: instanceId,
  });
  const { isEntitySaved, message: saveStatusMessage } =
    useEntityHeaderSaveStatus({
      operations,
      entityRootData,
      status,
    });
  const { mainWorkflowActions, menuItemWorkflowActions } =
    useEntityWorkflowActions({
      operations,
      status,
      isEntitySaved,
      entityConfigName,
    });
  const statusBadgeProps = getEntityStatusBadgeProps(status);

  const statusBadge = {
    label: formatMessage({ id: statusBadgeProps?.labelKey }),
    status: statusBadgeProps?.genesisStatus,
  };

  const create = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Create,
  );
  const update = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Update,
  );
  const publish = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Publish,
  );
  const republish = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Republish,
  );
  const unarchive = mainWorkflowActions.find(
    (action) => action.type === EntityDetailAction.Unarchive,
  );

  const isArchived = status === EntityStatusValue.Archived;

  const getSecondaryAction = () => {
    if (isArchived) {
      return unarchive;
    }
    if (instanceId) {
      return update;
    }
    return create;
  };

  const secondaryAction = getSecondaryAction();
  const primaryAction =
    status === EntityStatusValue.Draft || !instanceId ? publish : republish;

  const menuItems = menuItemWorkflowActions
    .filter((item) => item?.display)
    .map((item) => ({
      id: item?.type as EntityDetailAction,
      label: (
        <EntityMenuItem
          item={item}
          entityConfigName={entityConfigName}
          entityListPath={entityListPath}
        />
      ),
    }));

  const onMenuItemClicked = React.useCallback(
    (itemId: EntityDetailAction) =>
      menuItemWorkflowActions
        .find((action) => action.type === itemId)
        ?.action?.(),
    [menuItemWorkflowActions],
  );

  const menuItemGroup = { menuItems, onMenuItemClicked };

  const entityInstanceName =
    name || `${formatMessage({ id: 'UNTITLED' })} ${entityConfigName}`;

  return (
    <EntityDetailsHeader
      isLoading={isLoading}
      exitModalPrimaryButtonPath={entityListPath}
      menuItemGroup={menuItemGroup}
      secondaryAction={secondaryAction}
      primaryAction={primaryAction}
      statusBadge={statusBadge}
      entityInstanceName={entityInstanceName}
      isEntitySaved={isEntitySaved}
      saveStatusMessage={saveStatusMessage}
      isArchived={isArchived}
      entityConfigName={entityConfigName}
      instanceId={instanceId}
      currentEnvironmentId={currentEnvironmentId}
      availableEnvironments={availableEnvironments}
      // TODO: Temporary use of isProduction. Will be removed when Content Promotion project launches
      multiEnvironmentAuthoringEnabled={
        multiEnvironmentAuthoringEnabled && !isProduction()
      }
    />
  );
};
