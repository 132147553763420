import {
  tenantSpecificConfigs,
  TenantId,
} from '../configs/tenant-configs/tenant-configs.constants';
import { validateTenantConfig } from './validate-tenant-config/validate-tenant-config';

export const getTenantSpecificConfig = (tenantId: string) => {
  if (tenantId in tenantSpecificConfigs) {
    return validateTenantConfig(tenantSpecificConfigs[tenantId as TenantId]);
  }
  return undefined;
};
